
import {
  IonInput,
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  toastController,
  modalController,
  loadingController,
  IonCard,
  IonIcon,
  IonFooter,
  IonToolbar,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import creditCards from "@/data/providers/credit-cards";
import { useRouter } from "vue-router";
import typesModule from "@/data/modules/typesModule";
import { defineComponent } from "vue";
import { informationCircle } from "ionicons/icons";
import { mask } from "vue-the-mask";
import TheButton from "@/components/visualElements/TheButton.vue";

export default defineComponent({
  name: "AddCardPage",
  directives: { mask },
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonCol,
    IonRow,
    IonCard,
    IonIcon,
    IonFooter,
    IonToolbar,
    IonItem,
    IonLabel,
    TheButton,
  },
  setup() {
    const router = useRouter();

    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    return {
      goBack,
      informationCircle,
    };
  },
  data: () => ({
    numberCard: "",
    validate: "",
    cvv: "",
    name: "",
    cpf: "",
    nickname: "",
  }),
  methods: {
    createToast(message) {
      toastController
        .create({
          message,
          duration: 3000,
        })
        .then((toast) => toast.present());
    },

    openDataNotFoundToast() {
      this.createToast("Todos os campos devem ser preenchidos!");
    },

    openSuccessToast() {
      this.createToast("Cartão cadastrado com sucesso!");
    },

    async saveCardDataBase() {
      if (
        this.numberCard == "" ||
        this.name == "" ||
        this.nickname == "" ||
        this.cpf == "" ||
        this.validate == "" ||
        this.cvv == ""
      ) {
        this.openDataNotFoundToast();
      } else {
        try {
          this.showLoader();

          await this.saveCard({
            name: this.name,
            number: this.numberCard,
            nickname: this.nickname,
            cpf: this.cpf,
            validate: this.formatDate(this.validate),
            cvv: this.cvv,
          });
        } catch (error) {
          loadingController.dismiss();
          this.createToast(
            "Ocorreu um erro inesperado! Por favor, confira os dados e tente novamente!"
          );
        }
      }
    },

    formatDate(date) {
      const dateFormated = new Date(
        20 + date.substr(3, 2),
        parseInt(date.substr(0, 2)) - 1,
        1
      );

      return dateFormated;
    },

    async showLoader() {
      const loader = await loadingController.create({
        message: "Validando cartão...",
      });
      loader.present();
    },

    async saveCard({ number, name, nickname, cpf, validate, cvv }) {
      const response = await creditCards({ name, number, cpf, validate, cvv });
      loadingController.dismiss();
      if (!response.success) {
        this.createToast(response.message);
        return;
      }

      const lastDigits = response.data.last_digits;

      const id = response.data.id;

      const token = response.data.token;

      const type = response.data.type;

      const responseLocal = { lastDigits, nickname, id, token, type };

      this.$store.dispatch(typesModule.actions.SET_CARD, responseLocal);

      this.createToast("Cartão adicionado com sucesso!");

      modalController.dismiss();
    },
  },
});
