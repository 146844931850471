
import { arrowBack, call, ellipse } from "ionicons/icons";
import { modalController } from "@ionic/vue";
import { IonButtons, IonButton, IonIcon } from "@ionic/vue";
export default {
  name: "ChatModalHeader",
  components: {
    IonButtons,
    IonButton,
    IonIcon,
  },
  props: {
    order: {
      type: Object,
      default: undefined,
    },
  },

  setup(props) {
    const phone = `tel:${props.order.establishment.phone}`;
    return {
      phone,
      arrowBack,
      call,
      ellipse,
    };
  },
  data: () => ({
    isOnline: false,
    lastCheck: undefined,
  }),

  methods: {
    closeModal() {
      modalController.dismiss();
    },
  },
};
