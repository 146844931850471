import axios from "@/utils/axios";

export default async function (establishmentId, coupon, value) {
  const response = await axios.post(
    `establishments/${establishmentId}/coupon`,
    {
      coupon,
      price: value,
    }
  );

  const validateCoupon = response.data;

  return validateCoupon;
}
