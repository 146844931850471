
import {
  IonContent,
  modalController,
  toastController,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonFooter,
  IonInput,
  IonItem,
  IonPage,
} from "@ionic/vue";
import { defineComponent, onBeforeUnmount } from "vue";
import { arrowBack, call, send, attach } from "ionicons/icons";
import ChatTextBalloon from "../../components/EstablishmentChat/ChatTextBalloon.vue";
import ChatTextBalloonSkeleton from "../../components/EstablishmentChat/ChatTextBalloonSkeleton.vue";
import ChatModalHeader from "../../components/EstablishmentChat/ChatModalHeader.vue";
import ChatService from "../../services/EstablishmentChat";
import {
  getHistoryMessages,
  getImgLink,
} from "../../services/EstablishmentChat";
import { io } from "socket.io-client";
import CopperImgPage from "./CopperImgPage.vue";
import store from "@/data/modules/index";

export default defineComponent({
  name: "ChatModalPage",
  components: {
    IonContent,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonFooter,
    IonInput,
    ChatTextBalloon,
    ChatTextBalloonSkeleton,
    IonItem,
    ChatModalHeader,
    IonPage,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    order: {
      type: Object,
      default: undefined,
    },
  },
  setup(props) {
    let socket = io(ChatService.chatApiUrl, {
      auth: {
        userId: `app-${(store as any).state?.user.id}`,
        sessionId: props.order.id,
      },
    });

    onBeforeUnmount(() => {
      socket.close();
      socket = null;
      ChatService.chatOpened.splice(0, ChatService.chatOpened.length);
    });
    return {
      arrowBack,
      call,
      send,
      attach,
      socket,
    };
  },
  data: () => ({
    loading: true,
    NewMessageText: "",
    messageList: [],
  }),
  async mounted() {
    this.messageList = await getHistoryMessages(this.order.id);
    this.socket.on(`receiveMessage-${this.order.id}`, (data) =>
      this.renderMessage(data)
    );
    ChatService.chatOpened.push(this.order.id);
    ChatService.postReadMessages(this.order.id);

    this.loading = false;

    setTimeout(() => this.scrollToPoint(), 500);
  },

  methods: {
    fileChangeEvent(event) {
      const file = event.target.files[0];
      if (file?.size > 2000000) {
        return this.createToast(
          "Tente fazer upload de um arquivo menor. (Tamanho máximo 2mb)."
        );
      } else if (file?.type?.indexOf("image/") === -1) {
        this.createToast(
          "Tente fazer upload de um arquivo no formato PNG,JPEG ou GIF."
        );
        return;
      }
      return this.setImgSrc(file);
    },
    async setImgSrc(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.onNewImgChat(event.target.result);
      };
      reader.readAsDataURL(file);
    },
    async onNewImgChat(imgSrc) {
      const modal = await modalController.create({
        component: CopperImgPage,
        componentProps: {
          img: imgSrc,
        },
      });
      modal.present();
      modal.onDidDismiss().then((response) => {
        if (response.data) this.getImgLink(response.data);
      });
    },
    async getImgLink(formData) {
      await getImgLink(formData).then(
        async (res) => {
          this.sendImg(res.data.link);
        },
        async () => {
          this.createToast(
            "Ocorreu um erro de comunicação entre você e o servidor!"
          );
        }
      );
    },
    sendImg(imgLink) {
      this.socket.emit(`sendMessage`, this.getData("img", imgLink));
    },
    createToast(message) {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    },
    getData(property, value) {
      const data = {
        id: this.order.id,
        from: `app-${(store as any).state?.user.id}`,
        to: `emp-${this.order.establishment.id}`,
        hour: new Date().toTimeString().slice(0, 5),
      };
      data[property] = value;
      return data;
    },
    sendMessage() {
      if (!this.NewMessageText) {
        return;
      }
      this.socket.emit(
        `sendMessage`,
        this.getData("text", this.NewMessageText)
      );
      this.NewMessageText = "";
    },
    renderMessage(data) {
      this.messageList.push(data);
      this.scrollToPoint();
      ChatService.postReadMessages(this.order.id);
    },
    scrollToPoint() {
      const element = document.getElementById("content-list");

      const content = this.$refs.content.$el;
      content.scrollByPoint(0, element.offsetHeight - 60, 1000);
    },
    closeModal() {
      this.socket.close();
      modalController.dismiss();
    },
  },
});
