import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ddf10984"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_with_balance = _resolveComponent("header-with-balance")!
  const _component_PromotionProductCard = _resolveComponent("PromotionProductCard")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_EstablishmentCardSkeleton = _resolveComponent("EstablishmentCardSkeleton")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_header_with_balance, {
            class: "balance-header",
            title: "Promoções",
            "has-go-back": true
          }),
          _createElementVNode("div", _hoisted_1, [
            (!$setup.loadingRef)
              ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.promotionsCategoriesRef, (product) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: product.id,
                        class: "no-padding"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PromotionProductCard, { product: product }, null, 8, ["product"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128)),
                    _createVNode(_component_ion_infinite_scroll, {
                      onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => ($setup.getNextPromotions($event)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_infinite_scroll_content, { "loading-ref-spinner": "dots" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
                      return _createVNode(_component_ion_item, {
                        key: i,
                        class: "no-padding"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_EstablishmentCardSkeleton)
                        ]),
                        _: 2
                      }, 1024)
                    }), 64))
                  ]),
                  _: 1
                }))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}