import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a777bde"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-custom-modal" }
const _hoisted_2 = { class: "card-custom-modal" }
const _hoisted_3 = { class: "title-custom-modal" }
const _hoisted_4 = { class: "card__content-custom-modal" }
const _hoisted_5 = { class: "footer-buttons" }
const _hoisted_6 = { class: "quantity-button" }
const _hoisted_7 = { class: "quantity" }
const _hoisted_8 = { class: "buttons-container-custom-modal" }
const _hoisted_9 = { class: "bottom-button-custom-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "custom-modal-transparent" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("header", null, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
            _createVNode(_component_ion_button, {
              fill: "clear",
              onClick: _ctx.closeModal
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ion_input, {
              modelValue: _ctx.note,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.note) = $event)),
              placeholder: _ctx.inputs[0].placeholder,
              class: "content__input-custom-modal",
              autocomplete: "off"
            }, null, 8, ["modelValue", "placeholder"]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_ion_button, {
                  size: "small",
                  color: "primary",
                  class: "circle circle-left",
                  onClick: _ctx.removeQuantity
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.remove }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.quantityProduct), 1),
                _createVNode(_component_ion_button, {
                  size: "small",
                  color: "primary",
                  class: "circle circle-right",
                  onClick: _ctx.addQuantity
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_ion_button, { onClick: _ctx.editProduct }, {
                default: _withCtx(() => [
                  _createTextVNode("Editar produto")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_button, {
                fill: "clear",
                onClick: _ctx.removeProduct
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Remover")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}