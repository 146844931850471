
import { Browser } from "@capacitor/browser";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { IonImg, IonContent, IonPage, IonButton } from "@ionic/vue";
import ImagesCarousel from "@/components/explore/ImagesCarousel.vue";

import { moneyMask } from "@/utils/functions/money";
import getExternalOffer from "@/data/providers/explore/external-offer";
export default {
  name: "ExternalOfferPage",
  components: {
    HeaderWithBalance,
    IonImg,
    IonContent,
    IonPage,
    IonButton,
    ImagesCarousel,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },

  setup() {
    return { moneyMask };
  },
  data() {
    return {
      offer: {},
    };
  },
  async mounted() {
    const offer = await getExternalOffer(this.slug);

    this.offer = offer;
  },
  methods: {
    async openExternalLink(link) {
      await Browser.open({ url: link });
    },
  },
};
