import axios from "@/utils/axios";

export default async function (neighborhoodId, couponId, page?) {
  const response = await axios.get(
    `neighborhood/${neighborhoodId}/coupons/${couponId}${
      page ? "?page=" + page : ""
    }`
  );

  const establishmentsList = response.data;

  return establishmentsList;
}
