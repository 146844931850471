
import {
  IonPage,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import CouponsSearchList from "@/components/explore/CouponsSearchList.vue";
import OffersSearchList from "@/components/explore/OffersSearchList.vue";
import StoresSearchList from "@/components/explore/StoresSearchList.vue";

export default {
  name: "SearchList",
  components: {
    IonContent,
    IonPage,
    BasicHeader,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    CouponsSearchList,
    OffersSearchList,
    StoresSearchList,
  },
  props: {
    term: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      segmentModel: "ofertas",
      renderComponentStore: 0,
      renderComponentOffers: 10,
      renderComponentCoupons: 20,
    };
  },
  methods: {
    segmentChanged(event) {
      this.segmentModel = event.detail.value;
    },
    forceRerender() {
      this.renderComponentStore = this.renderComponentStore == 0 ? 1 : 0;
      this.renderComponentOffers = this.renderComponentOffers == 10 ? 11 : 10;
      this.renderComponentCoupons = this.renderComponentCoupons == 20 ? 21 : 20;
    },
  },
  ionViewWillEnter() {
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Entregamos tudo o que você precisa com dinheiro de volta (cashback). São milhares de opções de delivery: do pãozinho ao sushi e do sabonete a remédios."
      );

    if (Object.keys(this.$route.params).length > 0) {
      this.forceRerender();
    }
  },
};
