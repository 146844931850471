
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { IonImg, IonContent, IonPage, IonButton } from "@ionic/vue";

import getTip from "@/data/providers/explore/tips/tip";

export default {
  name: "TipPage",
  components: {
    HeaderWithBalance,
    IonImg,
    IonContent,
    IonPage,
    IonButton,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tip: {},
    };
  },
  async mounted() {
    const tip = await getTip(this.slug);

    this.tip = tip;
  },
  methods: {
    async goToLink(link) {
      this.$router.push(link);
    },
  },
};
