import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-91ab0b3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "header-end" }
const _hoisted_5 = { class: "subtitle" }
const _hoisted_6 = { class: "tag-container" }
const _hoisted_7 = { class: "tag-master" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_store_logo = _resolveComponent("store-logo")!
  const _component_rating_badge = _resolveComponent("rating-badge")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_delivery_fee = _resolveComponent("delivery-fee")!
  const _component_delivery_time = _resolveComponent("delivery-time")!
  const _component_delivery_payment = _resolveComponent("delivery-payment")!
  const _component_cashback_badge = _resolveComponent("cashback-badge")!
  const _component_coupon_badge = _resolveComponent("coupon-badge")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_store_logo, {
            logo: $props.establishment.logo,
            status: $props.establishment.status
          }, null, 8, ["logo", "status"]),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h3", _hoisted_3, _toDisplayString($props.establishment.name), 1),
              _createElementVNode("div", _hoisted_4, [
                (!$props.establishment.new && $props.establishment.rating)
                  ? (_openBlock(), _createBlock(_component_rating_badge, {
                      key: 0,
                      rating: $props.establishment.rating
                    }, null, 8, ["rating"]))
                  : _createCommentVNode("", true),
                ($props.establishment.new)
                  ? (_openBlock(), _createBlock(_component_ion_badge, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode("Novo")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("p", _hoisted_5, _toDisplayString($props.establishment.category), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_delivery_fee, {
                "delivery-fee": $props.establishment.deliveryFee
              }, null, 8, ["delivery-fee"]),
              _createVNode(_component_delivery_time, {
                "average-time": $props.establishment.averageTime
              }, null, 8, ["average-time"]),
              _createVNode(_component_delivery_payment, {
                "credit-card": $props.establishment.creditCard,
                "online-payment": $props.establishment.onlinePayment,
                pix: $props.establishment.pix
              }, null, 8, ["credit-card", "online-payment", "pix"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_cashback_badge, {
                "cashback-value": $props.establishment.cashbackValue
              }, null, 8, ["cashback-value"]),
              _createVNode(_component_coupon_badge, {
                coupon: $props.establishment.coupon
              }, null, 8, ["coupon"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}