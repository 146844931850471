
import { IonCard, IonImg, modalController } from "@ionic/vue";
import Products from "../views/Establishments/Products.vue";
import { moneyMask } from "@/utils/functions/money";

export default {
  name: "ProductCard",
  components: { IonCard, IonImg },
  props: {
    product: {
      type: Object,
      default: undefined,
    },
  },
  setup(props: any) {
    const getProduct = async () => {
      const modal = await modalController.create({
        component: Products,
        componentProps: { productId: props.product.id },
      });
      return modal.present();
    };

    return {
      getProduct,
      moneyMask,
    };
  },
};
