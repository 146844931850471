import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2998ee24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "transactions" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = { class: "col-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, (i) => {
        return _createVNode(_component_ion_item, {
          key: i,
          class: "no-padding"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_card, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ion_skeleton_text, {
                      animated: "",
                      class: "logo"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_ion_skeleton_text, {
                      animated: "",
                      class: "parag"
                    }),
                    _createVNode(_component_ion_skeleton_text, {
                      animated: "",
                      class: "value"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_ion_skeleton_text, {
                        animated: "",
                        class: "badge"
                      })
                    ]),
                    _createVNode(_component_ion_skeleton_text, {
                      animated: "",
                      class: "date"
                    })
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 2
        }, 1024)
      }), 64))
    ]),
    _: 1
  }))
}