import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d15f9b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "store" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "badges" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_store_external_logo = _resolveComponent("store-external-logo")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.goToLink(`/lojas-online/${$props.item.slug}`)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_store_external_logo, {
          logo: $props.item.logo,
          class: "logo"
        }, null, 8, ["logo"]),
        _createElementVNode("h3", null, _toDisplayString($props.item.name), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          ($props.item.cashback)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 0,
                color: "secondary",
                mode: "ios"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.item.cashback) + "% cashback", 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($props.item.number_coupons)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 1,
                color: "warning",
                mode: "ios"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.item.number_coupons) + " Cupons ", 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($props.item.number_offers)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 2,
                color: "medium",
                mode: "ios"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.item.number_offers) + " Ofertas ", 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_ion_icon, { icon: $setup.caretForward }, null, 8, ["icon"])
      ])
    ]),
    _: 1
  }))
}