
import { IonIcon, IonButton, IonButtons, modalController } from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { useRouter } from "vue-router";

export default {
  name: "BasicHeader",
  components: { IonButton, IonButtons, IonIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  setup() {
    const router = useRouter();

    const goBack = async () => {
      try {
        await modalController.dismiss();
      } catch {
        if (history?.state?.back) {
          router.back();
        } else {
          router.push("/tabs/inicio");
        }
      }
    };

    return {
      arrowBack,
      goBack,
    };
  },
};
