<template>
  <ion-content>
    <basic-header title="Regras do Sorteio" />
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="rules" class="circular-border" v-html="rules"></div>
  </ion-content>
</template>

<script>
import { defineComponent } from "vue";
import { IonContent } from "@ionic/vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";
export default defineComponent({
  name: "RulesModal",
  components: { IonContent, BasicHeader },
  props: {
    rules: {
      type: String,
      default: undefined,
    },
  },
});
</script>

<style scoped>
.circular-border {
  font-size: 12px;
}
</style>