import {
  cash,
  card,
  cardOutline,
  walkOutline,
  pricetagOutline,
  logoUsd,
} from "ionicons/icons";

export default [
  {
    id: 0,
    name: "Aceita Pix",
    value: "aceita_pix",
    isChecked: false,
    icon: cardOutline,
    imageSrc: "assets/simbolo_pix_mono_positivo.svg",
    imageSelectedSrc: "assets/simbolo_pix_mono_negativo.svg",
  },
  {
    id: 1,
    name: "Frete grátis",
    value: "bairros_frete_gratis",
    isChecked: false,
    icon: cash,
  },
  {
    id: 2,
    name: "Aceita cartão",
    value: "aceita_cartao",
    isChecked: false,
    icon: cardOutline,
  },
  {
    id: 3,
    name: "Pagamento Online",
    value: "aceita_pagamento_online",
    isChecked: false,
    icon: card,
  },
  {
    id: 4,
    name: "Aceita retirada",
    value: "retirada",
    isChecked: false,
    icon: walkOutline,
  },
  {
    id: 5,
    name: "Aceita cupom de desconto",
    value: "permite_cupons_desconto",
    isChecked: false,
    icon: pricetagOutline,
  },
  {
    id: 6,
    name: "Oferece cashback",
    value: "campanha_cashback_ativa",
    isChecked: false,
    icon: logoUsd,
  },
];
