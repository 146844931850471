
import {
  IonButton,
  IonInput,
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  toastController,
  loadingController,
  modalController,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import {} from "ionicons/icons";
import SimpleHeader from "@/components/headers/SimpleHeader.vue";
import loginWithPassword from "@/services/loginWithPassword";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import typesModule from "@/data/modules/typesModule";
import { mask } from "vue-the-mask";

export default {
  name: "LoginWithPassword",
  directives: { mask },
  components: {
    SimpleHeader,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonItem,
    IonLabel,
  },
  props: {
    isModal: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const createToast = (message) => {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    };

    const goLogin = async (phone, password) => {
      try {
        const response = await loginWithPassword(
          phone.replace(/\D/g, ""),
          password
        );

        loadingController.dismiss();
        if (response.token) {
          createToast("Login feito com sucesso");
          store.dispatch(typesModule.actions.SET_USER, response);

          if (!response.user.email) {
            createToast(
              "Sua conta não possui um email vinculado. Adicione um email válido!"
            );

            await router.push("/edit-profile");

            return;
          }

          modalController.dismiss({ login: true });
        } else {
          createToast("Ocorreu um erro ao fazer login");
        }
      } catch (error) {
        loadingController.dismiss();
        if (error.response?.data) {
          createToast(error.response.data.message);
        }
      }
    };
    return {
      goLogin,
      createToast,
    };
  },
  data: () => ({
    phone: "",
    password: "",
  }),
  methods: {
    async goConfirmLogin() {
      if (this.phone == "" || this.password == "") {
        this.createToast("Todos os campos devem ser preenchidos!");
        return;
      }
      await this.showLoader();
      this.goLogin(this.phone, this.password);
    },
    async showLoader() {
      const loader = await loadingController.create({
        message: "Acessando sua conta...",
      });
      loader.present();
    },
  },
};
