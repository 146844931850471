import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6324b089"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_header = _resolveComponent("basic-header")!
  const _component_coupon_card_skeleton = _resolveComponent("coupon-card-skeleton")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_coupon_card = _resolveComponent("coupon-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_basic_header, { title: "Cupons" }),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.loadingRef)
              ? (_openBlock(), _createBlock(_component_ion_grid, {
                  key: 0,
                  class: "skeleton"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (i) => {
                          return _createVNode(_component_ion_col, {
                            key: i,
                            "size-xs": "12",
                            "size-sm": "12",
                            "size-md": "6",
                            "size-lg": "4",
                            "size-xl": "4",
                            class: "no-padding"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_coupon_card_skeleton)
                            ]),
                            _: 2
                          }, 1024)
                        }), 64))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.loadingRef && _ctx.couponsAvailableRef.length == 0)
              ? (_openBlock(), _createBlock(_component_ion_card, {
                  key: 1,
                  class: "without-coupon-available"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_content, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Nenhum cupom disponível")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.couponsAvailableRef, (coupon) => {
                      return (_openBlock(), _createBlock(_component_ion_col, {
                        key: coupon.id,
                        class: "no-padding",
                        "size-xs": "12",
                        "size-sm": "12",
                        "size-md": "6",
                        "size-lg": "4",
                        "size-xl": "4",
                        onClick: ($event: any) => (_ctx.selectCoupon(coupon))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_coupon_card, { coupon: coupon }, null, 8, ["coupon"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}