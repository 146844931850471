
import { IonChip, IonLabel } from "@ionic/vue";

export default {
  name: "CategoriesList",
  components: {
    IonChip,
    IonLabel,
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["selectCategory"],
  data() {
    return {
      selected: undefined,
    };
  },
  methods: {
    selectCategory(category?) {
      if (!category || category?.id == this.selected) {
        this.selected = undefined;
        this.$emit("selectCategory", {});
        return;
      }

      this.selected = category?.id;

      this.$emit("selectCategory", category);
    },
  },
};
