
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import Categories from "@/components/explore/Categories.vue";
import StoreCard from "@/components/explore/StoreCard.vue";
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from "@ionic/vue";

import getAnyRoute from "@/data/providers/any-route";
import { logEvent, eventKeys } from "@/services/analitics";

import { caretForward } from "ionicons/icons";

export default {
  name: "AllStoresPage",
  components: {
    HeaderWithBalance,
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    StoreCard,
    Categories,
  },
  setup() {
    return {
      caretForward,
    };
  },
  data() {
    return {
      stores: [],
      categories: [],
    };
  },
  async mounted() {
    this.getStores();
    this.getCategories();
    logEvent(eventKeys.OPEN_EXTERNAL_STORES);
  },
  methods: {
    async getStores(categoryId) {
      const queryParams = new URLSearchParams();

      categoryId && queryParams.set("categoryId", categoryId);

      const query = queryParams.toString();

      const stores = await getAnyRoute(
        "/explore/stores" + (query && `?${query}`)
      );

      this.stores = stores;
    },
    async updateCategory(item) {
      this.getStores(item.id);
    },
    async goToLink(link) {
      this.$router.push(link);
    },
    async getCategories() {
      const queryParams = new URLSearchParams();

      queryParams.set("filter", "stores");

      const query = queryParams.toString();

      const categories = await getAnyRoute(
        "/explore/categories" + (query && `?${query}`)
      );

      this.categories = categories;
    },
  },
};
