
import { IonGrid, IonRow, IonCol, modalController } from "@ionic/vue";
import OfferCard from "@/components/explore/OfferCard.vue";

import { moneyMask } from "@/utils/functions/money";

import TransitionToExternalStorePage from "@/views/Explore/TransitionToExternalStorePage.vue";

export default {
  name: "OffersCards",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    OfferCard,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      moneyMask,
    };
  },
  methods: {
    async goToLink(link) {
      this.$router.push(link);
    },
    async openExternal(item) {
      if (item.details_page) {
        this.$router.push(`/lojas-online/ofertas/${item.slug}`);
        return;
      }
      const modal = await modalController.create({
        component: TransitionToExternalStorePage,
        componentProps: {
          store: {
            name: item.store_name,
            logo: item.logo,
            link: item.link,
          },
          message: item.description,
          buttonText: item.button_text,
          offerId: item.id,
        },
      });
      modal.present();
    },
  },
};
