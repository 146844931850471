<template>
  <ion-button shape="round" :color="color" :expand="expand" :fill="fill">
    {{ name }}
  </ion-button>
</template>

<script>
import { IonButton } from "@ionic/vue";
export default {
  name: "TheButton",
  components: { IonButton },
  props: {
    name: {
      type: String,
      default: "",
    },
    expand: {
      type: String,
      default: "full",
    },
    color: {
      type: String,
      default: "primary",
    },
    fill: {
      type: String,
      default: "solid",
    },
  },
};
</script>

<style scoped></style>