import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-576f1a83"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-content" }
const _hoisted_2 = { class: "name-date" }
const _hoisted_3 = { class: "star-coment" }
const _hoisted_4 = {
  key: 0,
  class: "response"
}
const _hoisted_5 = { class: "response-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RatingStars = _resolveComponent("RatingStars")!
  const _component_store_logo = _resolveComponent("store-logo")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "item" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString($setup.firstNameAndNextLetter($props.rating.user)), 1),
              _createElementVNode("span", null, _toDisplayString($setup.dateToBr($props.rating.rating_date)), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_RatingStars, {
                rating: $props.rating.rating,
                class: "star"
              }, null, 8, ["rating"]),
              _createElementVNode("span", null, _toDisplayString($props.rating.comment), 1)
            ])
          ]),
          ($props.rating.response)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString($props.rating.response), 1)
                ]),
                _createVNode(_component_store_logo, {
                  logo: $props.logo,
                  "w-h": "42px"
                }, null, 8, ["logo"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}