
import { IonContent, IonButton, IonInput, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import { mask } from "vue-the-mask";

export default defineComponent({
  name: "DataInvoiceModal",
  directives: { mask },
  components: { IonContent, IonButton, IonInput },
  data: () => ({
    dataInvoice: "",
  }),
  methods: {
    closeModal() {
      modalController.dismiss();
    },
    confirmData() {
      modalController.dismiss(this.dataInvoice);
    },
  },
});
