
import { IonSkeletonText } from "@ionic/vue";

export default {
  name: "ChatTextBalloonSkeleton",
  components: { IonSkeletonText },
  setup() {
    return {};
  },
};
