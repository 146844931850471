import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38e7227c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stars" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (n) => {
      return _createVNode(_component_ion_icon, {
        key: n,
        icon: $setup.star,
        style: _normalizeStyle({ color: n <= $data.limit ? 'orange' : '#e0e0e0' }),
        onClick: ($event: any) => ($options.changeRatings(n))
      }, null, 8, ["icon", "style", "onClick"])
    }), 64))
  ]))
}