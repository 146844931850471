import { isPlatform } from "@ionic/vue";

export function getNumberPlatform() {
  if (isPlatform("capacitor")) {
    if (isPlatform("android")) {
      return 1;
    } else {
      return 2;
    }
  } else {
    return 4;
  }
}
