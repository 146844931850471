export function capitalize(text) {
  return text.replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  );
}

export function transformSlug(text: string) {
  const textTranslateSpace = text.replace(/-/g, " ");
  return capitalize(textTranslateSpace);
}

export function slugify(name) {
  name = name.replace(/^\s+|\s+$/g, "");
  name = name.toLowerCase();

  const invalidChars = [
    "à",
    "á",
    "ä",
    "â",
    "è",
    "é",
    "ë",
    "ê",
    "ì",
    "í",
    "ï",
    "î",
    "ò",
    "ó",
    "ö",
    "ô",
    "ù",
    "ú",
    "ü",
    "û",
    "ñ",
    "ç",
    "·",
    "/",
    "_",
    ",",
    ":",
    ";",
  ];
  const validChars = [
    "a",
    "a",
    "a",
    "a",
    "e",
    "e",
    "e",
    "e",
    "i",
    "i",
    "i",
    "i",
    "o",
    "o",
    "o",
    "o",
    "u",
    "u",
    "u",
    "u",
    "n",
    "c",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
  ];
  invalidChars.forEach((char, index) => {
    name = name.replace(
      new RegExp(invalidChars[index], "g"),
      validChars[index]
    );
  });

  name = name
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return name;
}

export function removeArticles(texto) {
  const regex = /(?: (a|e|i|o|u|de|da|do) +)/g;
  return texto.replace(regex, " ");
}
