import ValidateCoupon from "@/data/providers/validateCoupon";

const updatePrice = (state) => {
  if (
    (state.freeShippingBy && state.price >= state.freeShippingBy) ||
    state?.coupon?.freeDelivery
  ) {
    state.deliveryFee = 0;
  } else {
    state.deliveryFee = state.originalDeliveryFee;
  }

  const discount = state?.coupon?.discount || 0;

  state.totalPrice = state.deliveryFee + state.price - discount;
};

const cardsModule = {
  state: {
    establishmentId: undefined,
    products: [],
    price: 0,
    totalPrice: 0,
    coupon: undefined,
    deliveryFee: undefined,
    originalDeliveryFee: undefined,
    freeShippingBy: undefined,
    isWithdraw: undefined,
    isOnlinePayment: undefined,
    isPix: undefined,
    isCashbackCampaign: undefined,
    change: 0,
    paymentMethod: undefined,
    address: undefined,
    couponsAvailable: 0,
    deliveryTime: undefined,
    allowCouponOrCashback: undefined,
    minimumValue: 0,
  },
  mutations: {
    resetCart(state: any) {
      state.establishmentId = undefined;
      state.products = [];
      state.price = 0;
      state.totalPrice = 0;
      state.deliveryFee = undefined;
      state.originalDeliveryFee = undefined;
      state.freeShippingBy = undefined;
      state.isWithdraw = undefined;
      state.isOnlinePayment = undefined;
      state.isPix = undefined;
      state.isCashbackCampaign = undefined;
      state.change = 0;
      state.coupon = undefined;
      state.couponsAvailable = 0;
      state.deliveryTime = undefined;
      state.allowCouponOrCashback = undefined;
      state.minimumValue = 0;

      if (!state.paymentMethod?.token) {
        state.paymentMethod = undefined;
      }
    },

    updateEstablishmentInfo(state: any, value: any) {
      state.originalDeliveryFee = value.deliveryFee;
      state.isWithdraw = value.isWithdraw;
      state.isOnlinePayment = value.isOnlinePayment;
      state.isPix = value.isPix;
      state.isCashbackCampaign = value.isCashbackCampaign;
      state.freeShippingBy = value.freeShippingBy;
      state.couponsAvailable = value.couponsAvailable || 0;
      state.deliveryTime = value.deliveryTime;
      state.allowCouponOrCashback = value.allowCouponOrCashback;
      state.minimumValue = value.minimumValue;
      updatePrice(state);
    },

    initCart(state: any, value: any) {
      state.establishmentId = value.establishmentId;
      state.deliveryFee = value.deliveryFee;
      state.originalDeliveryFee = value.deliveryFee;
      state.isWithdraw = value.isWithdraw;
      state.isOnlinePayment = value.isOnlinePayment;
      state.isPix = value.isPix;
      state.isCashbackCampaign = value.isCashbackCampaign;
      state.products = [];
      state.price = 0;
      state.totalPrice = 0;
      state.freeShippingBy = value.freeShippingBy;
      state.couponsAvailable = value.couponsAvailable || 0;
      state.deliveryTime = value.deliveryTime;
      state.allowCouponOrCashback = value.allowCouponOrCashback;
      state.minimumValue = value.minimumValue;

      if (!(state.paymentMethod?.token && state.isOnlinePayment)) {
        state.paymentMethod = undefined;
      }
    },

    setDeliveryFee(state: any, value: any) {
      state.originalDeliveryFee = value;
      updatePrice(state);
    },

    selectedPaymentMethod(state: any, value: any) {
      state.paymentMethod = value;
    },

    selectedAddress(state: any, value: any) {
      state.address = value;
    },

    removeQuantityProduct(state: any, value: any) {
      state.products[value].quantity--;
      state.products[value].totalPrice =
        state.products[value].quantity * state.products[value].price;

      state.price -= state.products[value].price;
      updatePrice(state);
    },

    addQuantityProduct(state: any, value: any) {
      state.products[value].quantity++;
      state.products[value].totalPrice =
        state.products[value].quantity * state.products[value].price;

      state.price += state.products[value].price;
      updatePrice(state);
    },

    editProduct(state: any, value: any) {
      state.price -= state.products[value.index].totalPrice;
      state.totalPrice = state.deliveryFee + state.price;
      state.products.splice(value.index, 1);

      state.products.push(value.product);
      state.price += value.product.totalPrice;
      updatePrice(state);
    },

    addProduct(state: any, value: any) {
      state.products.push(value);
      state.price += value.totalPrice;

      updatePrice(state);
    },
    removeProduct(state: any, value: any) {
      state.price -= state.products[value].totalPrice;
      state.products.splice(value, 1);
      updatePrice(state);
    },
    updateNoteProduct(state: any, value: any) {
      state.products[value.productIndex].note = value.note;
    },
    setCoupon(state: any, value: any) {
      state.coupon = value;
      updatePrice(state);
    },
    removeCoupon(state) {
      state.coupon = undefined;
      updatePrice(state);
    },
  },
  actions: {
    resetCart({ commit }: any) {
      commit("resetCart");
    },

    initCart({ commit, dispatch }: any, establishmentInfo: string) {
      commit("initCart", establishmentInfo);
      dispatch("revalidateCoupon");
    },

    updateInfo({ commit, dispatch }: any, establishmentInfo: string) {
      commit("updateEstablishmentInfo", establishmentInfo);
      dispatch("revalidateCoupon");
    },

    setDeliveryFee({ commit }: any, deliveryFee) {
      commit("setDeliveryFee", deliveryFee);
    },

    selectedPaymentMethod({ commit }: any, paymentMethod) {
      commit("selectedPaymentMethod", paymentMethod);
    },
    selectedAddress({ commit }: any, address) {
      commit("selectedAddress", address);
    },

    editProduct({ commit, dispatch }, product: any) {
      commit("editProduct", product);
      dispatch("revalidateCoupon");
    },

    removeQuantityProduct({ commit, dispatch }, product: any) {
      commit("removeQuantityProduct", product);
      dispatch("revalidateCoupon");
    },

    addQuantityProduct({ commit, dispatch }, product: any) {
      commit("addQuantityProduct", product);
      dispatch("revalidateCoupon");
    },
    addProduct({ commit, dispatch }, product: any) {
      commit("addProduct", product);
      dispatch("revalidateCoupon");
    },
    removeProduct({ commit, dispatch }, productIndex: number) {
      commit("removeProduct", productIndex);
      dispatch("revalidateCoupon");
    },
    updateNoteProduct({ commit }, { note, productIndex }) {
      commit("updateNoteProduct", { note, productIndex });
    },

    async revalidateCoupon({ commit, state }) {
      if (!state?.coupon?.name) {
        return;
      }

      const response = await ValidateCoupon(
        state.establishmentId,
        state?.coupon?.name,
        state.price
      );

      if (!response?.isValid) {
        commit("removeCoupon");
        return (
          response.message ||
          "Ocorreu um erro ao validar seu cupom, por favor, tente novamente!"
        );
      }

      commit("setCoupon", {
        name: state?.coupon?.name,
        discount: response.discount,
        freeDelivery: response.freeDelivery,
      });

      return response.message;
    },
    async setCouponName({ commit }, coupon: string) {
      commit("setCoupon", {
        name: coupon,
      });
    },
    async setCoupon({ commit, state }, coupon: string) {
      if (!coupon) {
        commit("removeCoupon");
        return "Cupom removido com sucesso!";
      }

      const response = await ValidateCoupon(
        state.establishmentId,
        coupon,
        state.price
      );

      if (!response?.isValid) {
        commit("removeCoupon");
        return (
          response.message ||
          "Ocorreu um erro ao validar seu cupom, por favor, tente novamente!"
        );
      }

      commit("setCoupon", {
        name: coupon,
        discount: response.discount,
        freeDelivery: response.freeDelivery,
      });

      return response.message;
    },
  },
};

export default cardsModule;
