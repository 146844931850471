
import { IonButton, IonIcon, toastController } from "@ionic/vue";
import { add, remove } from "ionicons/icons";

export default {
  name: "ProductCounterComponent",
  components: { IonButton, IonIcon },
  props: {
    defaultQuantity: { type: Number, default: 0 },
    max: { type: Number, default: 0 },
  },
  emits: ["selected"],
  setup() {
    return { add, remove };
  },
  data: function () {
    return {
      quantity: this.defaultQuantity,
      addItem: false,
      removeItem: false,
    };
  },
  methods: {
    removeProduct() {
      if (this.quantity == 0) {
        return;
      } else {
        this.quantity--;
        this.$emit("selected", this.quantity);
      }
    },
    async addProduct() {
      if (this.max && this.quantity >= this.max) {
        const alert = await toastController.create({
          message: `O máximo que pode ser selecionado é ${this.max}.`,
          duration: 3000,
        });

        alert.present();

        return;
      }
      this.quantity++;
      this.$emit("selected", this.quantity);
    },
  },
};
