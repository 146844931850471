import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!

  return (_openBlock(), _createBlock(_component_ion_checkbox, {
    checked: _ctx.active,
    mode: "md",
    value: $props.suboption.name,
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.activate($event)))
  }, null, 8, ["checked", "value"]))
}