export function moneyMask(value) {
  if (isNaN(value)) return;
  return `R$ ${value.toFixed(2).replace(".", ",")}`;
}

export function curencyToNumber(value) {
  return Number(value.replace(",", ".").substring(3));
}

export function currencyRound(value) {
  return Math.round(value * 100) / 100;
}
