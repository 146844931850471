
import {
  IonInput,
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonSelect,
  IonSelectOption,
  toastController,
  modalController,
  IonFooter,
  IonToolbar,
  IonItem,
  IonLabel,
  IonSkeletonText,
} from "@ionic/vue";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { useRouter } from "vue-router";
import { defineComponent } from "vue";
import addAddresData from "@/data/providers/address/add-address";
import getOnlineNeighborhoods from "@/data/providers/neighborhoods";
import editAddress from "@/data/providers/address/edit-address";
import TheButton from "@/components/visualElements/TheButton.vue";
import { capitalize } from "@/utils/functions/string";

export default defineComponent({
  name: "ManageAddress",
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonCol,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonFooter,
    IonToolbar,
    IonItem,
    IonLabel,
    IonSkeletonText,
    TheButton,
  },
  props: {
    address: {
      type: Object,
      default: undefined,
    },
  },
  setup() {
    const router = useRouter();

    const addAddress = async (
      neighborhood,
      street,
      number,
      reference,
      complement
    ) => {
      await addAddresData(neighborhood, street, number, reference, complement);
    };

    const customNeighborhoodOptions: any = {
      header: "Escolha o bairro",
      cssClass: "select-neighborhoods",
    };

    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    return {
      addAddress,
      goBack,
      customNeighborhoodOptions,
      capitalize,
    };
  },
  data: () => ({
    neighborhood: "",
    street: "",
    number: "",
    reference: "",
    complement: "",
    neighborhoods: undefined,
    loading: true,
  }),
  async mounted() {
    await this.getNeighborhoods();

    if (this.address) {
      this.neighborhood = this.address.neighborhood_id;
      this.street = this.address.street_name;
      this.number = this.address.address_number;
      this.reference = this.address.reference;
      this.complement = this.address.complement;
    }

    this.loading = false;
  },
  methods: {
    createToast(message) {
      toastController
        .create({
          message,
          duration: 3000,
        })
        .then((toast) => toast.present());
    },

    openDataNotFoundToast() {
      this.createToast("Todos os campos devem ser preenchidos!");
    },

    openSuccessToast(message) {
      this.createToast(message);
    },

    addAddressConfirm() {
      if (
        this.neighborhood === "" ||
        this.street === "" ||
        this.number === "" ||
        this.reference === ""
      ) {
        this.openDataNotFoundToast();
      } else {
        if (this.address) {
          editAddress(
            this.address.id,
            this.neighborhood,
            this.capitalize(this.street),
            this.number,
            this.capitalize(this.reference),
            this.capitalize(this.complement)
          );

          this.openSuccessToast("Endereço atualizado com sucesso!");
        } else {
          this.addAddress(
            this.neighborhood,
            this.capitalize(this.street),
            this.number,
            this.capitalize(this.reference),
            this.capitalize(this.complement)
          );

          this.openSuccessToast("Endereço cadastrado com sucesso!");
        }

        modalController.dismiss({ success: true });
      }
    },
    async getNeighborhoods() {
      const neighborhoodState = this.$store.state.neighborhood;

      const neighborhoodsResponse = await getOnlineNeighborhoods(
        neighborhoodState.cityId
      );

      this.neighborhoods = neighborhoodsResponse;
    },
  },
});
