
export default {
  name: "ChatTextBalloon",
  components: {},
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  setup() {
    return {};
  },
};
