
import {
  IonContent,
  IonButton,
  modalController,
  toastController,
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { moneyMask, curencyToNumber } from "@/utils/functions/money";

export default defineComponent({
  name: "ModalConfirmOrder",
  components: { IonContent, IonButton, IonCard, IonCardContent },
  props: {
    payment: { type: Object, default: null },
    address: { type: Object, default: null },
    data: { type: Object, default: null },
    deliveryFee: { type: String, default: null },
    total: { type: String, default: null },
  },
  setup() {
    return { moneyMask, curencyToNumber };
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
    infoPaymentMethod(payment) {
      if (payment.paymentMethod) {
        return payment.paymentMethod.name;
      } else if (payment.pix) {
        return payment.nickname;
      }
      return "Cartão: " + payment.nickname;
    },
    async confirmOrder() {
      modalController.dismiss({ confirmed: true });
    },

    async openToast(message) {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    },
  },
});
