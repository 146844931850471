
import { IonIcon, IonButton, modalController } from "@ionic/vue";
import { arrowBack } from "ionicons/icons";
import { useRouter } from "vue-router";
import CitiesModal from "@/views/Cities.vue";

export default {
  name: "SimpleHeader",
  components: { IonButton, IonIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
    headerImg: {
      type: String,
      default: "",
    },
    hasGoBack: {
      type: Boolean,
      default: true,
    },
    hasGoBackModal: {
      type: Boolean,
      default: false,
    },
    hasChangeCity: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter();

    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    const goBackModal = () => {
      modalController.dismiss();
    };

    const changeCity = async () => {
      modalController.dismiss();

      const modal = await modalController.create({
        component: CitiesModal,
        cssClass: "my-custom-class",
      });
      modal.present();
    };

    return {
      arrowBack,
      goBack,
      goBackModal,
      changeCity,
    };
  },
};
