import axios from "@/utils/axios";

export default async function searchExternalOffers(params) {
  const queryParams = new URLSearchParams(params);
  const response = await axios.get(
    `/explore/search/offers${"?" + queryParams.toString()}`
  );

  const coupons = response.data;

  return coupons;
}
