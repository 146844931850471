
import { IonCheckbox, alertController } from "@ionic/vue";

export default {
  name: "ProductSelectMultipleComponent",
  components: { IonCheckbox },
  props: {
    option: {
      type: String,
      default: undefined,
    },
    suboption: {
      type: Object,
      default: undefined,
    },
    checkedProps: {
      type: Number,
      default: undefined,
    },
    maxSelected: {
      type: Number,
      default: undefined,
    },
    alreadySelected: {
      type: Array,
      default: undefined,
    },
  },
  emits: ["isCheckboxActive"],
  data: function () {
    return {
      active: false,
    };
  },
  mounted() {
    if (this.$props.alreadySelected) {
      this.active = true;
    }
  },
  methods: {
    activate(event) {
      event.stopImmediatePropagation();
      event.preventDefault();
      if (!this.active) {
        if (this.checkedProps >= this.maxSelected && this.maxSelected != 0) {
          this.openAlertMaxOption(
            this.maxSelected,
            this.$props.option,
            this.$props.suboption
          );
          return;
        }
        this.active = true;
        this.$emit("isCheckboxActive", this.active);
      } else {
        this.active = false;
        this.$emit("isCheckboxActive", this.active);
      }
    },

    async openAlertMaxOption(maxSelected, option, suboption) {
      const alert = await alertController.create({
        header: "Quantidade máxima",
        message: `Você já escolheu ${maxSelected} opções de ${option}, retire um item para adicionar ${suboption.name} :)`,
        buttons: ["OK"],
      });

      return alert.present();
    },
  },
};
