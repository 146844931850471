import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-582f25ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info" }
const _hoisted_2 = { class: "texts" }
const _hoisted_3 = { class: "store-name" }
const _hoisted_4 = { class: "complements" }
const _hoisted_5 = { class: "badges" }
const _hoisted_6 = { class: "initial-price-div" }
const _hoisted_7 = { class: "initial-price" }
const _hoisted_8 = { class: "prices" }
const _hoisted_9 = { class: "price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_store_logo = _resolveComponent("store-logo")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_delivery_fee = _resolveComponent("delivery-fee")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { onClick: $setup.getProduct }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_store_logo, {
            logo: $props.product.establishment?.logo,
            "w-h": "50px",
            status: $props.product.establishment?.status
          }, null, 8, ["logo", "status"]),
          _createElementVNode("div", null, [
            _createElementVNode("h3", null, _toDisplayString($props.product.description), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString($props.product.establishment?.name), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ion_badge, { class: "badge-discount" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.product.discountPercentage) + " OFF", 1)
              ]),
              _: 1
            }),
            ($props.product.establishment.cashbackCampaign)
              ? (_openBlock(), _createBlock(_component_ion_badge, {
                  key: 0,
                  class: "badge-cashback"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($props.product.establishment.cashbackCampaign.discount_percentage
                ? $props.product.establishment.cashbackCampaign.discount_percentage +
                  "% CASHBACK"
                : "R$ " + $props.product.establishment.cashbackCampaign.discount_value), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(`R$ ${$props.product.initial_price?.toFixed(2).replace(".", ",")}`), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_delivery_fee, {
              "delivery-fee": $props.product.establishment?.deliveryFee?.delivery_fee
            }, null, 8, ["delivery-fee"]),
            _createElementVNode("span", _hoisted_9, _toDisplayString(`R$ ${$props.product.promotional_price?.toFixed(2).replace(".", ",")}`), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClick"]))
}