
import { IonIcon } from "@ionic/vue";
import { star } from "ionicons/icons";

export default {
  name: "RatingStars",
  components: { IonIcon },
  props: {
    rating: {
      type: Number,
      default: null,
    },
  },
  setup() {
    return {
      star,
    };
  },

  data() {
    return {
      limit: Math.round(this.$props.rating),
    };
  },

  computed: {},

  methods: {
    changeRatings(n) {
      this.limit = n;
      this.$emit("saveValueLimit", this.limit);
    },
  },
};
