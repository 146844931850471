
import VLazyImage from "v-lazy-image";
export default {
  name: "StoreLogo",
  components: { VLazyImage },
  props: {
    logo: {
      type: String,
      default: undefined,
    },
    status: {
      type: [Boolean, Number],
      default: true,
    },
    wH: {
      type: String,
      default: "56px",
    },
  },
  computed: {
    cssProps() {
      return {
        "--wH": this.wH,
      };
    },
  },
};
