
import { IonIcon } from "@ionic/vue";
import { bicycle } from "ionicons/icons";
export default {
  name: "DeliveryFee",
  components: { IonIcon },
  props: {
    deliveryFee: {
      type: Number,
      default: undefined,
    },
  },
  setup() {
    return {
      bicycle,
    };
  },
  methods: {
    formatCurrency(currency) {
      const format = "R$ " + currency.toFixed(2).replace(".", ",");
      return format;
    },
  },
};
