import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f257975"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "searchbar" }
const _hoisted_3 = { class: "select" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_header = _resolveComponent("simple-header")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_simple_header, {
                title: "Escolha a sua localização",
                "header-img": "assets/headerInitial.webp",
                "has-go-back": false,
                "has-go-back-modal": _ctx.hasCityAndNeigh()
              }, null, 8, ["has-go-back-modal"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_searchbar, {
                placeholder: "Procure sua cidade",
                debounce: "500",
                onIonInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterList($event)))
              })
            ]),
            (_ctx.loadingRef)
              ? (_openBlock(), _createBlock(_component_ion_list, {
                  key: 0,
                  class: "skeleton"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
                      return _createVNode(_component_ion_item, {
                        key: i,
                        class: "no-padding"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_skeleton_text, { animated: "" })
                        ]),
                        _: 2
                      }, 1024)
                    }), 64))
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_radio_group, { "allow-empty-selection": "" }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.citiesRef, (city) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: city.id,
                            class: "no-padding no-ripple-effect",
                            onClick: ($event: any) => (_ctx.markCity(city))
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("p", null, _toDisplayString(city.name), 1),
                              _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_ion_radio, {
                                  slot: "end",
                                  mode: "ios",
                                  value: city.id
                                }, null, 8, ["value"])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_the_button, {
                name: "Prosseguir",
                onClick: _ctx.selectCity
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}