
import {
  IonPage,
  IonContent,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonList,
  IonItem,
  modalController,
} from "@ionic/vue";

import EstablishmentCard from "@/components/cards/EstablishmentCard.vue";
import EstablishmentCardSkeleton from "@/components/skeletonComponents/EstablishmentCardSkeleton.vue";
import SelectLocation from "@/components/SelectLocation.vue";

import EstablishmentsListByCoupon from "@/data/providers/establishments/establishments-list-by-coupon";

import { onMounted, watch, defineComponent, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import typesModule from "@/data/modules/typesModule";
import { slugify } from "@/utils/functions/string";

export default defineComponent({
  name: "EstablishmentByCoupon",
  components: {
    EstablishmentCard,
    EstablishmentCardSkeleton,
    IonContent,
    IonPage,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonItem,
    BasicHeader,
    SelectLocation,
  },
  props: {
    coupon: {
      type: Object,
      default: undefined,
    },
  },
  setup(props: any) {
    const router = useRouter();
    const store = useStore();

    const establishmentsRef: Ref = ref([]);
    const loadingRef = ref(true);
    const cursorRef = ref(1);

    const neighborhoodState = store.state.neighborhood;

    const user = store.state.user;

    const getEstablishments = async () => {
      loadingRef.value = true;

      if (neighborhoodState?.id) {
        const listEstablishments = await EstablishmentsListByCoupon(
          neighborhoodState.id,
          props.coupon.id
        );

        establishmentsRef.value = listEstablishments;
        cursorRef.value = 2;
      }

      loadingRef.value = false;
    };

    const getNextEstablishments = async (event) => {
      const newEstablishments = await EstablishmentsListByCoupon(
        neighborhoodState.id,
        props.coupon.id,
        cursorRef.value
      );

      establishmentsRef.value.push(...newEstablishments);

      cursorRef.value += 1;
      event.target.complete();
    };

    const goToEstablishment = (slug, categorySlug) => {
      store.dispatch(typesModule.actions.SET_COUPON_NAME, props.coupon.name);

      const citySlug = store.state.neighborhood.citySlug;

      router.push(`/loja/${citySlug || "categoria"}/${categorySlug}/${slug}`);

      modalController.dismiss();
    };

    const doRefresh = async (event) => {
      await getEstablishments();
      event.target.complete();
    };

    onMounted(getEstablishments);

    watch(neighborhoodState, () => getEstablishments());

    return {
      loadingRef,
      establishmentsRef,
      cursorRef,
      user,
      doRefresh,
      neighborhoodState,
      goToEstablishment,
      getNextEstablishments,
      slugify,
    };
  },
  methods: {},
});
