import axios from "@/utils/axios";

export default async function (
  name: string,
  phone: string,
  email: string,
  birth: string,
  gender: string,
  ref?: string
) {
  const response = await axios.post(`/user/register`, {
    name,
    phone,
    email,
    birthDate: birth,
    gender,
    ref,
  });

  const data = response.data;

  return data;
}
