import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bd6ad47"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "circular-border"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_header = _resolveComponent("basic-header")!
  const _component_select_location = _resolveComponent("select-location")!
  const _component_establishment_card = _resolveComponent("establishment-card")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_establishment_card_skeleton = _resolveComponent("establishment-card-skeleton")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_basic_header, {
            title: _ctx.coupon.name
          }, null, 8, ["title"]),
          (!_ctx.neighborhoodState?.id)
            ? (_openBlock(), _createBlock(_component_select_location, {
                key: 0,
                class: "circular-border"
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (!_ctx.loadingRef)
                  ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.establishmentsRef, (establishment) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: establishment.id,
                            class: "no-padding",
                            onClick: ($event: any) => (
              _ctx.goToEstablishment(
                establishment.slug,
                _ctx.slugify(establishment.category)
              )
            )
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_establishment_card, { establishment: establishment }, null, 8, ["establishment"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128)),
                        _createVNode(_component_ion_infinite_scroll, {
                          onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getNextEstablishments($event)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_infinite_scroll_content, { "loading-ref-spinner": "dots" })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
                          return _createVNode(_component_ion_item, { key: i }, {
                            default: _withCtx(() => [
                              _createVNode(_component_establishment_card_skeleton)
                            ]),
                            _: 2
                          }, 1024)
                        }), 64))
                      ]),
                      _: 1
                    }))
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}