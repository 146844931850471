
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import Categories from "@/components/explore/Categories.vue";
import {
  IonContent,
  IonPage,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
} from "@ionic/vue";
import CouponsCards from "@/components/explore/CouponsCards.vue";

import getAnyRoute from "@/data/providers/any-route";
import { logEvent, eventKeys } from "@/services/analitics";

export default {
  name: "AllCouponsPage",
  components: {
    HeaderWithBalance,
    IonContent,
    IonPage,
    IonInfiniteScrollContent,
    IonInfiniteScroll,
    CouponsCards,
    Categories,
  },
  props: {
    slug: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      coupons: [],
      categories: [],
      page: 1,
    };
  },
  async mounted() {
    this.getCoupons();
    this.getCategories();
    logEvent(eventKeys.OPEN_EXTERNAL_COUPONS);
  },
  methods: {
    async updateCategory(item) {
      this.getCoupons(1, item.id);
      this.page = 1;
    },
    async getNextCoupons(event) {
      await this.getCoupons(++this.page);
      event.target.complete();
    },
    async getCoupons(page = 1, categoryId = undefined) {
      const queryParams = new URLSearchParams();

      this.slug && queryParams.set("storeSlug", this.slug);
      categoryId && queryParams.set("categoryId", categoryId);
      queryParams.set("page", page.toString());

      const query = queryParams.toString();

      const coupons = await getAnyRoute(
        "/explore/coupons" + (query && `?${query}`)
      );

      if (page == 1) {
        this.coupons = coupons;
        return;
      }

      this.coupons.push(...coupons);
    },
    async getCategories() {
      const queryParams = new URLSearchParams();

      queryParams.set("filter", "coupons");

      const query = queryParams.toString();

      const categories = await getAnyRoute(
        "/explore/categories" + (query && `?${query}`)
      );

      this.categories = categories;
    },
  },
};
