import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-795ba203"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }
const _hoisted_2 = {
  key: 2,
  class: "message"
}
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { class: "coupon-code" }
const _hoisted_5 = {
  key: 4,
  class: "offer-prices"
}
const _hoisted_6 = { class: "old-price" }
const _hoisted_7 = { class: "new-price" }
const _hoisted_8 = {
  key: 5,
  class: "about"
}
const _hoisted_9 = { key: 6 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_header = _resolveComponent("basic-header")!
  const _component_images_carousel = _resolveComponent("images-carousel")!
  const _component_store_external_logo = _resolveComponent("store-external-logo")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_basic_header, {
            title: _ctx.store.name,
            "has-go-back-modal": true,
            "has-go-back": false
          }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.images.length)
              ? (_openBlock(), _createBlock(_component_images_carousel, {
                  key: 0,
                  images: _ctx.images.map((image) => image.image)
                }, null, 8, ["images"]))
              : (_openBlock(), _createBlock(_component_store_external_logo, {
                  key: 1,
                  logo: _ctx.store.logo,
                  "w-h": "200px"
                }, null, 8, ["logo"])),
            (_ctx.message)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.coupon)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("p", null, [
                    _createTextVNode(" Para continuar, anote o cupom "),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.coupon), 1),
                    _createTextVNode(" para ser inserido antes de efetuar a compra. Boas compras! ")
                  ]),
                  _createVNode(_component_ion_button, {
                    shape: "round",
                    expand: "full",
                    fill: "clear",
                    size: "small",
                    color: "medium",
                    onClick: _withModifiers(_ctx.copyCoupon, ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.copy }, null, 8, ["icon"]),
                      _createTextVNode(" Copiar cupom")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.initialPrice || _ctx.promotionalPrice)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.initialPrice ? _ctx.moneyMask(_ctx.initialPrice) : ""), 1),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.promotionalPrice ? _ctx.moneyMask(_ctx.promotionalPrice) : ""), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_button, {
              shape: "round",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToStore()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
              ]),
              _: 1
            }),
            (_ctx.about)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("ul", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.about.content, (content, i) => {
                      return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(content), 1))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.warning)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.warning.title), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warning.content, (content, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: i,
                      class: "external-store-info"
                    }, [
                      _createElementVNode("p", null, _toDisplayString(content), 1)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}