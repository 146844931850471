
import { IonIcon } from "@ionic/vue";
import { timeOutline } from "ionicons/icons";
export default {
  name: "DeliveryTime",
  components: { IonIcon },
  props: {
    averageTime: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    return {
      timeOutline,
    };
  },
};
