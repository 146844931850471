import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a824722"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "categories" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "category",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.selectCategory()))
    }, [
      _createVNode(_component_ion_chip, {
        class: _normalizeClass({ selected: !$data.selected })
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, null, {
            default: _withCtx(() => [
              _createTextVNode("Todas as categorias")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.categories, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        lines: "none",
        class: "category",
        onClick: ($event: any) => ($options.selectCategory(item))
      }, [
        _createVNode(_component_ion_chip, {
          class: _normalizeClass({ selected: $data.selected == item.id })
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.name), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["class"])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}