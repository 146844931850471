
import {
  IonContent,
  IonButton,
  IonInput,
  modalController,
  IonIcon,
} from "@ionic/vue";
import { close } from "ionicons/icons";
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import typesModule from "@/data/modules/typesModule";
import { remove, add } from "ionicons/icons";
import Products from "./Establishments/Products.vue";
export default defineComponent({
  name: "ModalChangeProduct",
  components: { IonContent, IonButton, IonInput, IonIcon },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    inputs: {
      type: Object,
      default: undefined,
    },
    product: {
      type: Number,
      default: undefined,
    },
    products: {
      type: Object,
      default: undefined,
    },
  },
  setup(props: any) {
    const store = useStore();

    const selectedProduct = store.state.cart.products[props.product];

    const quantityProduct = computed(() => {
      return selectedProduct.quantity;
    });

    const productId = selectedProduct.id;

    return { remove, add, quantityProduct, selectedProduct, close, productId };
  },
  data: function () {
    return {
      note: this.products.note,
    };
  },
  methods: {
    async editProduct() {
      modalController.dismiss();
      const modal = await modalController.create({
        component: Products,
        componentProps: {
          productId: this.productId,
          editIndex: this.$props.product,
        },
      });

      modal.present();
    },

    removeQuantity() {
      if (this.quantityProduct === 1) {
        return;
      }

      this.$store.dispatch(
        typesModule.actions.REMOVE_QUANTITY_PRODUCT,
        this.$props.product
      );
    },

    addQuantity() {
      this.$store.dispatch(
        typesModule.actions.ADD_QUANTITY_PRODUCT,
        this.$props.product
      );
    },

    removeProduct() {
      this.$store.dispatch(
        typesModule.actions.REMOVE_PRODUCT,
        this.$props.product
      );
      modalController.dismiss();
    },
    closeModal() {
      this.$store.dispatch(typesModule.actions.UPDATE_NOTE_PRODUCT, {
        note: this.note,
        productIndex: this.$props.product,
      });
      modalController.dismiss();
    },
  },
});
