
export default {
  name: "RatingTo10",
  props: {
    rating: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      limit: this.$props.rating ? Math.round(this.$props.rating) : undefined,
    };
  },

  computed: {},

  methods: {
    changeRatings(n) {
      this.limit = n;
      this.$emit("saveValueLimit", this.limit);
    },
  },
};
