
import { defineComponent } from "vue";
import {
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import Search from "@/data/providers/explore/search-external-stores";
import StoreCard from "@/components/explore/StoreCard.vue";
import EstablishmentCardSkeleton from "@/components/skeletonComponents/EstablishmentCardSkeleton.vue";
import NetworkError from "@/components/NetworkError.vue";
import { slugify } from "@/utils/functions/string";

export default defineComponent({
  name: "StoresSearchList",
  components: {
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonGrid,
    IonRow,
    IonCol,
    StoreCard,
    EstablishmentCardSkeleton,
    NetworkError,
  },
  props: {
    listStore: {
      type: Boolean,
      default: false,
    },
    term: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      slugify,
    };
  },
  data: function () {
    return {
      items: [],
      cursor: 2,
      occurredError: false,
      loading: true,
    };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    async getItems() {
      try {
        this.loading = true;
        const items = await Search({ query: this.term });

        this.items = items;
        this.occurredError = false;
        this.cursor = 2;
      } catch (_error) {
        this.occurredError = true;
      } finally {
        this.loading = false;
      }
    },
    async getNextItems(event) {
      try {
        this.loading = true;
        const items = await Search({ query: this.term, page: this.cursor });

        this.items.push(...items);
        this.cursor++;
      } finally {
        event.target.complete();
      }
    },
  },
});
