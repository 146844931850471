import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d0a478e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "subtitle" }
const _hoisted_6 = { class: "tag-container" }
const _hoisted_7 = { class: "tag-master" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_header = _resolveComponent("basic-header")!
  const _component_store_logo = _resolveComponent("store-logo")!
  const _component_delivery_time = _resolveComponent("delivery-time")!
  const _component_delivery_payment = _resolveComponent("delivery-payment")!
  const _component_cashback_badge = _resolveComponent("cashback-badge")!
  const _component_coupon_badge = _resolveComponent("coupon-badge")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_EstablishmentCardSkeleton = _resolveComponent("EstablishmentCardSkeleton")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_basic_header, { title: "Favoritos" }),
          _createElementVNode("div", _hoisted_1, [
            (!_ctx.loading)
              ? (_openBlock(), _createBlock(_component_ion_grid, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.establishments, (establishment) => {
                          return (_openBlock(), _createBlock(_component_ion_col, {
                            key: establishment.id,
                            class: "no-padding",
                            "size-xs": "12",
                            "size-sm": "12",
                            "size-md": "6",
                            "size-lg": "6",
                            "size-xl": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_content, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_store_logo, {
                                        logo: establishment.logo,
                                        status: establishment.status,
                                        onClick: ($event: any) => (
                      _ctx.goToStore(
                        establishment.slug,
                        _ctx.slugify(establishment.category)
                      )
                    )
                                      }, null, 8, ["logo", "status", "onClick"]),
                                      _createElementVNode("div", {
                                        class: "info",
                                        onClick: ($event: any) => (_ctx.goToStore(establishment.id))
                                      }, [
                                        _createElementVNode("div", _hoisted_3, [
                                          _createElementVNode("h3", _hoisted_4, _toDisplayString(establishment.name), 1)
                                        ]),
                                        _createElementVNode("p", _hoisted_5, _toDisplayString(establishment.category), 1),
                                        _createElementVNode("div", _hoisted_6, [
                                          _createVNode(_component_delivery_time, {
                                            "average-time": establishment.averageTime
                                          }, null, 8, ["average-time"]),
                                          _createVNode(_component_delivery_payment, {
                                            "credit-card": establishment.creditCard,
                                            "online-payment": establishment.onlinePayment,
                                            pix: establishment.pix
                                          }, null, 8, ["credit-card", "online-payment", "pix"])
                                        ]),
                                        _createElementVNode("div", _hoisted_7, [
                                          _createVNode(_component_cashback_badge, {
                                            "cashback-value": establishment.cashbackValue
                                          }, null, 8, ["cashback-value"]),
                                          _createVNode(_component_coupon_badge, {
                                            coupon: establishment.coupon
                                          }, null, 8, ["coupon"])
                                        ])
                                      ], 8, _hoisted_2),
                                      _createElementVNode("div", {
                                        class: "heart",
                                        onClick: ($event: any) => (_ctx.likeStore(establishment.id))
                                      }, [
                                        (establishment.like)
                                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                                              key: 0,
                                              icon: _ctx.heart,
                                              color: "primary"
                                            }, null, 8, ["icon"]))
                                          : _createCommentVNode("", true),
                                        (!establishment.like)
                                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                                              key: 1,
                                              icon: _ctx.heartOutline,
                                              color: "primary"
                                            }, null, 8, ["icon"]))
                                          : _createCommentVNode("", true)
                                      ], 8, _hoisted_8)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_grid, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
                          return _createVNode(_component_ion_col, {
                            key: i,
                            "size-xs": "12",
                            "size-sm": "12",
                            "size-md": "6",
                            "size-lg": "6",
                            "size-xl": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_EstablishmentCardSkeleton)
                            ]),
                            _: 2
                          }, 1024)
                        }), 64))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}