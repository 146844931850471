
import { IonCard, IonImg, IonBadge } from "@ionic/vue";
import { moneyMask } from "@/utils/functions/money";
import { dateToBr } from "@/utils/functions/date";

export default {
  name: "TransactionCashbackCard",
  components: { IonCard, IonImg, IonBadge },
  props: {
    transaction: {
      type: Object,
      default: undefined,
    },
  },
  setup() {
    return { moneyMask, dateToBr };
  },
  data() {
    return {
      debit: "debit",
      credit: "credit",
    };
  },
};
