
import {
  IonPage,
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import ItemsSearchBy from "@/components/search/ItemsSearchBy.vue";

export default {
  name: "SearchList",
  components: {
    IonContent,
    IonPage,
    BasicHeader,
    IonSegment,
    IonSegmentButton,
    ItemsSearchBy,
    IonLabel,
  },
  setup() {
    return {};
  },
  data: function () {
    return {
      segmentModel: "lojas",
      renderComponentStore: 1,
      renderComponentItens: -1,
    };
  },
  methods: {
    segmentChanged(event) {
      this.segmentModel = event.detail.value;
    },
    forceRerender() {
      this.renderComponentStore += 1;
      this.renderComponentItens -= 1;
    },
  },
  ionViewWillEnter() {
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Entregamos tudo o que você precisa com dinheiro de volta (cashback). São milhares de opções de delivery: do pãozinho ao sushi e do sabonete a remédios."
      );

    if (Object.keys(this.$route.params).length > 0) {
      this.forceRerender();
    }
  },
};
