import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30d0c029"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-line" }
const _hoisted_2 = { class: "offer-info" }
const _hoisted_3 = { class: "offer-prices" }
const _hoisted_4 = { class: "old-price" }
const _hoisted_5 = { class: "new-price" }
const _hoisted_6 = { class: "card-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_lazy_image = _resolveComponent("v-lazy-image")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openExternal($props.item)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_lazy_image, {
          src: $props.item.logo,
          "src-placeholder": "/assets/thelivery_logo.jpg",
          alt: "Imagem do produto",
          class: "avatar"
        }, null, 8, ["src"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString($props.item.description), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString($props.item.initial_price ? $setup.moneyMask($props.item.initial_price) : ""), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString($props.item.promotional_price ? $setup.moneyMask($props.item.promotional_price) : ""), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", null, [
          _createElementVNode("h4", null, _toDisplayString($props.item.cashback ? $props.item.cashback + "% de Cashback" : ""), 1),
          _createElementVNode("p", null, _toDisplayString($props.item.store_name), 1)
        ])
      ])
    ]),
    _: 1
  }))
}