const preferencesModule = {
  state: {
    favoritesCard: true,
    lastStores: true,
  },
  mutations: {
    toggleFavoritesCardView(state: any, value: boolean) {
      state.favoritesCard = value;
    },
    toggleLastStoresView(state: any, value: boolean) {
      state.lastStores = value;
    },
  },
  actions: {
    toggleFavoritesCardView({ commit }: any, option: boolean) {
      commit("toggleFavoritesCardView", option);
    },
    toggleLastStoresView({ commit }: any, option: boolean) {
      commit("toggleLastStoresView", option);
    },
  },
};

export default preferencesModule;
