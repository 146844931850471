
export default {
  name: "CouponBadge",
  props: {
    coupon: {
      type: String,
      default: undefined,
    },
  },
};
