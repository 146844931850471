<template>
  <div class="content">
    <input
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="fileChangeEvent"
    />

    <div>
      <vue-cropper
        ref="cropper"
        class="img-cropper"
        :src="imgSrc"
        preview=".preview"
      />
      <section class="preview-area">
        <div class="preview" />
      </section>
    </div>

    <div class="buttons-area">
      <p @click.prevent="goBack(false)">Cancelar</p>
      <p @click.prevent="goBack(true)">Enviar</p>
    </div>
  </div>
</template>

<script>
import { toastController, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import VueCropper from "vue-cropperjs";
import { cameraReverseOutline, arrowBack } from "ionicons/icons";
import "cropperjs/dist/cropper.css";
export default defineComponent({
  name: "CopperImgPage",
  components: {
    VueCropper,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    img: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    return { cameraReverseOutline, arrowBack };
  },
  data() {
    return {
      imgSrc: this.img,
      cropImg: "",
      data: null,
    };
  },
  methods: {
    fileChangeEvent(event) {
      const file = event.target.files[0];
      if (file?.size > 2000000) {
        return this.createToast(
          "Tente fazer upload de um arquivo menor. (Tamanho máximo 2mb)."
        );
      } else if (file.type.indexOf("image/") === -1) {
        this.createToast(
          "Tente fazer upload de um arquivo no formato PNG,JPEG ou GIF."
        );
        return;
      }
      return this.setImgSrc(file);
    },
    async setImgSrc(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.imgSrc = event.target.result;
        this.$refs.cropper.replace(event.target.result);
      };
      reader.readAsDataURL(file);
    },
    dataURLtoFile(dataurl, filename) {
      // eslint-disable-next-line prefer-const
      let arr = dataurl.split(","),
        // eslint-disable-next-line prefer-const
        mime = arr[0].match(/:(.*?);/)[1],
        // eslint-disable-next-line prefer-const
        bstr = atob(arr[1]),
        n = bstr.length,
        // eslint-disable-next-line prefer-const
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const file = new File([u8arr], filename, { type: mime });
      const formData = new FormData();
      formData.append("file", file);
      return formData;
    },
    createToast(message) {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    goBack(data) {
      modalController.dismiss(
        data
          ? this.dataURLtoFile(
              this.$refs.cropper.getCroppedCanvas().toDataURL(),
              "img.png"
            )
          : false
      );
    },
  },
});
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.img-cropper {
  max-height: 350px;
}
.preview-area {
  position: fixed;
  width: 20%;
  bottom: 55px;
  left: 15px;
}
.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.buttons-area {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  bottom: 45px;
  text-decoration: none;
  color: var(--ion-color-primary);
  cursor: pointer;
}
</style>
