import OneSignalService from "@/services/OneSignal";
import ChatService from "@/services/chat";
import logoffSession from "@/services/logoff";

const userModule = {
  state: {
    notificationToken: undefined,
    token: undefined,
    id: undefined,
    name: undefined,
    email: undefined,
    slug: undefined,
    cashbackValue: undefined,
    phoneVerified: undefined,
    phone: undefined,
    ordersQuantity: undefined,
  },
  mutations: {
    setUserName(state: any, value: any) {
      state.name = value.name;
      state.email = value.email;
    },

    setUserPhoneVerified(state: any, value: any) {
      state.phoneVerified = value.phoneVerified;
    },

    updateUserInfo(state: any, value: any) {
      state.name = value.name;
      state.email = value.email;
      state.phoneVerified = value.phone_verified;
      state.phone = value.phone;
      state.slug = value.slug;
      state.ordersQuantity = value.orders_quantity || 0;
      state.cashbackValue = value.cashbackValue || value.cashback_value;
    },
    setLoginInfo(state: any, value: any) {
      state.id = value.user.id;
      state.name = value.user.name;
      state.email = value.user.email;
      state.phoneVerified = value.user.phone_verified;
      state.phone = value.user.phone;
      state.slug = value.user.slug;
      state.token = value.token;
      state.cashbackValue = value.user.cashbackValue;
    },
    logoff(state) {
      state.id = undefined;
      state.name = undefined;
      state.email = undefined;
      state.phoneVerified = undefined;
      state.phone = undefined;
      state.slug = undefined;
      state.token = undefined;
      state.cashbackValue = undefined;
    },
    updateCashback(state: any, value: number) {
      state.cashbackValue = value;
    },
    setUserNotificationToken(state: any, token) {
      state.notificationToken = token;
    },
  },
  actions: {
    setUserNotificationToken({ commit, state }: any, token) {
      if (state.notificationToken !== token) {
        commit("setUserNotificationToken", token);
        ChatService.updateUserNotificationToken();
      }
    },
    setUserName({ commit }: any, { name, email }) {
      commit("setUserName", { name, email });
    },
    setUserPhoneVerified({ commit }: any, phoneVerified: any) {
      commit("setUserPhoneVerified", { phoneVerified });
    },
    updateUserInfo({ commit, state }: any, user) {
      const shouldUpdateChat = state.name != user.name;

      commit("updateUserInfo", user);

      if (shouldUpdateChat) {
        ChatService.updateUser();
      }
    },
    setLoginInfo({ commit }: any, { user, token }) {
      commit("setLoginInfo", { user, token });

      OneSignalService.sendNotificationToken();
      ChatService.updateUser();
    },
    async logoff({ commit }) {
      await logoffSession();
      commit("resetCart", null, { root: true });
      commit("logoff");
      commit("deleteAllCards", null, { root: true });

      ChatService.logout();
      ChatService.initChat();
    },
    updateCashback({ commit }, value) {
      commit("updateCashback", value);
    },
  },
};

export default userModule;
