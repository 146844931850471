export function formatPhone(phone) {
  if (phone) {
    const phoneAdapter =
      phone.length < 11 ? "9" + phone.substr(2, 4) : phone.substr(2, 5);

    return (
      "(" + phone.substr(0, 2) + ") " + phoneAdapter + "-" + phone.substr(-4, 4)
    );
  }
  return;
}
