const addressModule = {
  state: {
    address: {},
  },
  mutations: {
    addAddress(state: any, value: any) {
      state.address = value;
    },
  },
  actions: {
    addAddress({ commit }: any, address: string) {
      commit("addAddress", address);
    },
  },
};

export default addressModule;
