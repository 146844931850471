import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60672f4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "square-buttons" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        ($props.hasGoBack)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 0,
              fill: "clear",
              onClick: $setup.goBack
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: $setup.arrowBack,
                  color: "light"
                }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("h3", null, _toDisplayString($props.title), 1),
      _createElementVNode("h1", null, _toDisplayString($setup.moneyMask($props.cashBackValue)), 1),
      _createElementVNode("div", _hoisted_3, [
        ($props.history)
          ? (_openBlock(), _createBlock(_component_ion_button, {
              key: 0,
              fill: "outline",
              onClick: $setup.goTransactions
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Ver histórico ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}