
import { IonList, IonItem, IonRadio, IonRadioGroup } from "@ionic/vue";
import { moneyMask } from "@/utils/functions/money";

export default {
  name: "SelectUniqueSuboption",
  components: { IonList, IonItem, IonRadio, IonRadioGroup },
  props: {
    suboptions: {
      type: Object,
      default: undefined,
    },
    alreadySelected: {
      type: Array,
      default: undefined,
    },
    mandatory: {
      type: Number,
      default: undefined,
    },
  },
  emits: ["selectSuboption", "preselectSuboption"],
  setup() {
    return { moneyMask };
  },
  data: function () {
    return {
      defaultValue: "",
    };
  },
  mounted() {
    const selectedSuboption = this.alreadySelected?.[0];

    if (selectedSuboption) {
      const selected = this.suboptions.find(
        (suboption) => suboption.id == selectedSuboption.id
      );

      this.defaultValue = selected?.name || "";
      this.$emit("preselectSuboption", [selected]);
    } else if (this.suboptions.length == 1 && this.mandatory) {
      this.defaultValue = this.suboptions[0]?.name || "";
      this.$emit("preselectSuboption", [this.suboptions[0]]);
    }
  },
  methods: {
    clickSuboption(event) {
      let value = event.detail.value;
      if (!value) {
        value = "";
      }
      const selected = this.$props.suboptions.find(
        (suboption) => suboption.id == value
      );
      this.$emit("selectSuboption", [selected]);
    },
  },
};
