import axios from "@/utils/axios";

export default async function (phone: string, password: string) {
  const response = await axios.post(`/auth/password`, {
    phone,
    password,
    info: navigator.userAgent.toString(),
  });

  const data = response.data;

  return data;
}
