import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23a2448a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }
const _hoisted_2 = { class: "titles" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_with_balance = _resolveComponent("header-with-balance")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_header_with_balance, {
            title: "Dica",
            "has-go-back-modal": false,
            "has-go-back": true
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_img, {
              src: $data.tip.image
            }, null, 8, ["src"]),
            _createElementVNode("h3", _hoisted_2, _toDisplayString($data.tip.title), 1),
            _createElementVNode("div", {
              innerHTML: $data.tip.content
            }, null, 8, _hoisted_3),
            _createVNode(_component_ion_button, {
              shape: "round",
              color: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.goToLink($data.tip.button_link)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($data.tip.button_text), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}