
import { filterOutline } from "ionicons/icons";
import {
  IonPage,
  IonContent,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonFooter,
  IonToolbar,
} from "@ionic/vue";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";

import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import Categories from "@/data/providers/categories";
import PaymentMethods from "@/data/providers/payment-methods";
import SortByGet from "@/data/providers/sort-by";
import FiltersGet from "@/data/providers/filters";

import typesModule from "@/data/modules/typesModule";
import TheButton from "@/components/visualElements/TheButton.vue";
export default {
  name: "ModalFilters",
  components: {
    HeaderWithBalance,
    IonContent,
    IonPage,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonSpinner,
    IonFooter,
    IonToolbar,
    TheButton,
  },
  setup() {
    const paymentMethodsRef = ref([]);
    const categoriesRef = ref([]);

    const sortBy = [...SortByGet];
    const filters = [...FiltersGet];

    const loadingRef = ref(true);
    const valueFilterRef = ref("default");

    const store = useStore();
    const router = useRouter();

    const getCategories = async () => {
      loadingRef.value = true;
      const dataCategories = await Categories();

      categoriesRef.value = dataCategories;

      loadingRef.value = false;
    };

    const getPaymentMethods = async () => {
      loadingRef.value = true;
      const dataPaymentMethods = await PaymentMethods();

      paymentMethodsRef.value = dataPaymentMethods;

      loadingRef.value = false;
    };

    const getSearchSortBy = async (sortBy, filters, payments, categories) => {
      store.dispatch(typesModule.actions.SET_UPDATE_PARAMS, {
        sortBy,
        filters,
        payments,
        categories,
        searchName: "",
      });
      router.push({
        name: "simple-search",
        params: { category: "search" },
      });
    };

    onMounted(getPaymentMethods);
    onMounted(getCategories);
    return {
      store,
      categoriesRef,
      loadingRef,
      sortBy,
      filters,
      filterOutline,
      paymentMethodsRef,
      valueFilterRef,
      getSearchSortBy,
    };
  },

  data: function () {
    return {
      orderName: "default",
      sortByData: { name: "Padrão", value: "default" },
      filtersData: [],
      payments: [],
      categoriesData: [],
    };
  },

  methods: {
    sortByName(sort) {
      this.sortByData = sort;
      this.orderName = sort.value;
    },

    filtersByPushPayments(value) {
      if (this.payments.indexOf(value) === -1) {
        this.payments.push(value);
      } else {
        const indexArray = this.filtersByRemovePayments(value);
        this.payments.splice(indexArray, 1);
      }
    },

    filtersByRemovePayments(value) {
      return this.payments.findIndex((category) => {
        return category.name === value.name;
      });
    },

    filtersByPush(filter) {
      if (this.filtersData.indexOf(filter) === -1) {
        this.filtersData.push(filter);
      } else {
        const indexArray = this.filtersByRemove(filter);
        this.filtersData.splice(indexArray, 1);
      }
    },

    filtersByRemove(value) {
      return this.filtersData.findIndex((category) => {
        return category.value === value.value;
      });
    },

    categoriesByPush(value) {
      if (this.categoriesData.indexOf(value) === -1) {
        this.categoriesData.push(value);
      } else {
        const indexArray = this.categoriesByRemove(value);
        this.categoriesData.splice(indexArray, 1);
      }
    },

    categoriesByRemove(value) {
      return this.categoriesData.findIndex((category) => {
        return category.name === value.name;
      });
    },

    isFilterActive(filter) {
      return this.filtersData.indexOf(filter) != -1;
    },
    isCategoryActive(category) {
      return this.categoriesData.indexOf(category) != -1;
    },
    isFilterPaymentsActive(payment) {
      return this.payments.indexOf(payment) != -1;
    },

    apply() {
      this.getSearchSortBy(
        this.sortByData,
        this.filtersData,
        this.payments,
        this.categoriesData
      );
    },
  },
};
