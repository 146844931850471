<template>
  <div class="error">
    <ion-icon :icon="sadOutline"></ion-icon>
    <h4>Ocorreu um problema inesperado!</h4>
    <p>
      Por favor, verifique sua Internet e tente novamente. Caso o problema
      persista, aguarde alguns minutos ou entre em contato conosco.
    </p>
    <ion-button shape="round" @click="refresh">Tentar novamente</ion-button>
  </div>
</template>

<script>
import { IonIcon, IonButton } from "@ionic/vue";

import { sadOutline } from "ionicons/icons";

export default {
  name: "NetworkError",
  components: {
    IonIcon,
    IonButton,
  },
  props: {
    refresh: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return { sadOutline };
  },
};
</script>

<style scoped>
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error ion-icon {
  font-size: 50px;
}

.error {
  text-align: center;
}

.error h4 {
  margin-bottom: 0px;
}

ion-button {
  margin-top: 22px;
  --box-shadow: none;
}
</style>