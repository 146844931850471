import axios from "@/utils/axios";

export default async function (
  neighborhoods,
  street,
  number,
  reference,
  complement
) {
  const response = await axios.post(`/address`, {
    neighborhoodId: neighborhoods,
    streetName: street,
    addressNumber: number,
    reference,
    complement,
  });

  const address = response.data;

  return address;
}
