import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6283d072"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { id: "text-item" }
const _hoisted_3 = { id: "hour-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["content-message", {
      'float-left': $props.item.from.split('-')[0] === 'emp',
      'float-right': $props.item.from.split('-')[0] === 'app',
    }])
  }, [
    _createElementVNode("span", null, [
      ($props.item.img)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: $props.item.img
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_2, _toDisplayString($props.item.text), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString($props.item.hour), 1)
    ])
  ], 2))
}