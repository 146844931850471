
import {
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonButton,
} from "@ionic/vue";
import { ticketOutline } from "ionicons/icons";
import { dateToBr } from "@/utils/functions/date";

export default {
  name: "CouponCard",
  components: {
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonButton,
  },
  props: {
    coupon: {
      type: Object,
      default: undefined,
    },
  },
  setup() {
    return {
      ticketOutline,
      dateToBr,
    };
  },
};
