import axios from "@/utils/axios";

export default async function (establishmentId: number) {
  const response = await axios.get(
    `/establishments/${establishmentId}/initial-ratings`
  );

  const initialRatingsData = response.data;

  return initialRatingsData;
}
