
import { IonPage, IonContent, IonSkeletonText } from "@ionic/vue";
import { pencilOutline } from "ionicons/icons";
import { onMounted, ref } from "vue";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import ChatButton from "@/components/ChatButton.vue";
import { useRouter } from "vue-router";

import getPageContent from "@/data/providers/page-contents";

export default {
  name: "ProfileInfo",
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    ChatButton,
    IonSkeletonText,
  },
  setup() {
    const router = useRouter();
    const loadingRef = ref(true);
    const faqRef = ref([]);

    const getFaqData = async () => {
      loadingRef.value = true;

      const pageContent = await getPageContent("faq");

      if (pageContent?.content?.questions) {
        faqRef.value = pageContent.content.questions;
      }

      loadingRef.value = false;
    };

    const openFaq = async () => {
      router.push("/faq");
    };

    onMounted(() => {
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Entregamos tudo o que você precisa com dinheiro de volta (cashback). São milhares de opções de delivery: do pãozinho ao sushi e do sabonete a remédios."
        );

      getFaqData();
    });

    return {
      loadingRef,
      faqRef,
      pencilOutline,
      openFaq,
    };
  },
};
