import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-489dd138"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container swiper-zoom-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { onClick: _ctx.closeModal }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", { src: _ctx.image }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["onClick"]))
}