import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a0ff034"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }
const _hoisted_2 = { class: "titles" }
const _hoisted_3 = { class: "prices" }
const _hoisted_4 = { class: "old-price" }
const _hoisted_5 = { class: "new-price" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_with_balance = _resolveComponent("header-with-balance")!
  const _component_images_carousel = _resolveComponent("images-carousel")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_header_with_balance, {
            title: $data.offer.store_name || 'Oferta',
            "has-go-back-modal": false,
            "has-go-back": true
          }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_1, [
            ($data.offer?.images?.length)
              ? (_openBlock(), _createBlock(_component_images_carousel, {
                  key: 0,
                  images: $data.offer.images.map((image) => image.image)
                }, null, 8, ["images"]))
              : (_openBlock(), _createBlock(_component_ion_img, {
                  key: 1,
                  src: $data.offer.logo
                }, null, 8, ["src"])),
            _createElementVNode("h1", _hoisted_2, _toDisplayString($data.offer.description), 1),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, _toDisplayString($data.offer.initial_price ? $setup.moneyMask($data.offer.initial_price) : ""), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString($data.offer.promotional_price ? $setup.moneyMask($data.offer.promotional_price) : ""), 1)
            ]),
            _createElementVNode("div", {
              innerHTML: $data.offer.details
            }, null, 8, _hoisted_6),
            _createVNode(_component_ion_button, {
              shape: "round",
              color: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openExternalLink($data.offer.link)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($data.offer.button_text || "Ir para a loja"), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}