
import { IonBadge, IonCard, IonCardContent } from "@ionic/vue";
import DeliveryFee from "../visualElements/DeliveryFee.vue";
import DeliveryTime from "../visualElements/DeliveryTime.vue";
import DeliveryPayment from "../visualElements/DeliveryPayment.vue";
import CashbackBadge from "../visualElements/CashbackBadge.vue";
import CouponBadge from "../visualElements/CouponBadge.vue";
import RatingBadge from "../visualElements/RatingBadge.vue";
import StoreLogo from "../visualElements/StoreLogo.vue";

export default {
  name: "EstablishmentCard",
  components: {
    IonBadge,
    IonCard,
    IonCardContent,
    DeliveryFee,
    DeliveryTime,
    DeliveryPayment,
    CashbackBadge,
    CouponBadge,
    RatingBadge,
    StoreLogo,
  },
  props: {
    establishment: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    formatCurrency(currency) {
      const format = "R$ " + currency.toFixed(2).replace(".", ",");
      return format;
    },
  },
};
