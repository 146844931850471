import axios from "@/utils/axios";

export default async function (establishmentId: string) {
  const response = await axios.get(
    `establishments/${establishmentId}/payment-methods`
  );

  const establishmentPaymentMethods = response.data;

  return establishmentPaymentMethods;
}
