import axios from "@/utils/axios";

export default async function (email: string, code: string) {
  const response = await axios.post(`/auth/email`, {
    email,
    code,
    info: navigator.userAgent.toString(),
  });

  const data = response.data;

  return data;
}
