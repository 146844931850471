import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c8530fa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_with_balance = _resolveComponent("header-with-balance")!
  const _component_categories = _resolveComponent("categories")!
  const _component_store_card = _resolveComponent("store-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_header_with_balance, {
            title: "Lojas on-line",
            "has-go-back-modal": false,
            "has-go-back": true
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_categories, {
              categories: $data.categories,
              onSelectCategory: $options.updateCategory
            }, null, 8, ["categories", "onSelectCategory"]),
            _createVNode(_component_ion_grid, { class: "grid-cards" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.stores, (item) => {
                      return (_openBlock(), _createBlock(_component_ion_col, {
                        key: item.id,
                        "size-xs": "12",
                        "size-sm": "12",
                        "size-md": "6",
                        "size-lg": "4",
                        "size-xl": "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_store_card, { item: item }, null, 8, ["item"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}