
import { arrowBack } from "ionicons/icons";
import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
} from "@ionic/vue";
import ModalGoStore from "@/components/modals/ModalGoStore.vue";
import getCategories from "@/data/providers/explore/lomadee/coupons-categories";
import { Ref, ref, onMounted } from "vue";
import { eventKeys, logEvent } from "@/services/analitics";

export default {
  name: "CouponsCategoriesPage",
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonIcon,
    IonGrid,
    IonRow,
    IonCol,
  },
  setup() {
    const loadingRef = ref(true);
    const categoriesRef: Ref = ref([]);

    const getStoresList = async () => {
      loadingRef.value = true;

      const categories = await getCategories();

      categoriesRef.value = categories;

      loadingRef.value = false;
    };

    onMounted(getStoresList);

    return {
      categoriesRef,
      loadingRef,
      arrowBack,
    };
  },
  methods: {
    ionViewDidEnter() {
      logEvent(eventKeys.OPEN_LOMADEE);
    },
    goBack() {
      if (history?.state?.back) {
        this.$router.back();
      } else {
        this.$router.push("/tabs/inicio");
      }
    },
    goToCategory(category) {
      this.$router.push(
        `/explore/cupons-online/categorias/${category.id}/lojas`
      );
    },
    async openModal(store) {
      const modal = await modalController.create({
        component: ModalGoStore,
        cssClass: "modal-confirm-order",
        componentProps: {
          store: store,
        },
      });

      await modal.present();
    },
    formatName(name: string) {
      return name.replace(/\//, " / ");
    },
  },
};
