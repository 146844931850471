
export default {
  name: "CashbackBadge",
  props: {
    cashbackValue: {
      type: String,
      default: undefined,
    },
  },
};
