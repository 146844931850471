/* eslint-disable no-console */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { App as CapacitorApp, URLOpenListenerEvent } from "@capacitor/app";

import { alertController, IonicVue, isPlatform } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import store from "./data/modules/index";

import OneSignalService from "@/services/OneSignal";
import ChatService from "@/services/chat";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { GoogleAnalytics } from "@awesome-cordova-plugins/google-analytics";

import * as Sentry from "@sentry/capacitor";
import * as SentryVue from "@sentry/vue";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import { AppUpdate } from "@capawesome/capacitor-app-update";

import "./registerServiceWorker";

Sentry.init(
  {
    dsn: "http://bc3d33b539da406289134c61a0ff0d6b@95.217.165.193:9000/7",
    tracesSampleRate: 1,
    enableOutOfMemoryTracking: false,
  },
  SentryVue.init
);

const app = createApp(App).use(IonicVue).use(router).use(store);

router.isReady().then(async () => {
  app.mount("#app");

  ChatService.initChat();
  OneSignalService.initOneSignal();

  if (isPlatform("capacitor")) {
    if (isPlatform("ios")) {
      await StatusBar.setStyle({ style: Style.Dark });
    }

    GoogleAnalytics.startTrackerWithId("UA-20856000-12")
      .then(() => {
        GoogleAnalytics.trackView("entrou_no_app");
      })
      .catch((e) => e);

    setTimeout(() => {
      SplashScreen.hide({ fadeOutDuration: 500 }).then(async () => {
        const lastUpdateTryString = localStorage.getItem("thelivery-update");
        const lastUpdateTryDate = lastUpdateTryString
          ? new Date(lastUpdateTryString)
          : new Date();

        const aWeekAgo = new Date();

        aWeekAgo.setDate(new Date().getDate() - 7);

        const canTryToUpdateAgain = lastUpdateTryDate > aWeekAgo;

        if (!lastUpdateTryString || canTryToUpdateAgain) {
          const result = await AppUpdate.getAppUpdateInfo();
          if (result.updateAvailability !== 2) {
            return;
          }

          if (result.immediateUpdateAllowed) {
            const updateResponse = await AppUpdate.performImmediateUpdate();

            const isUpdateCanceled = updateResponse.code == 1;

            if (isUpdateCanceled) {
              localStorage.setItem(
                "thelivery-update",
                new Date().toISOString()
              );
            }
          } else if (isPlatform("ios")) {
            const alert = await alertController.create({
              header: "Me atualize",
              message:
                "Temos uma nova versão disponível para download. Deseja fazer agora?",
              buttons: [
                {
                  text: "Agora não",
                  role: "cancel",
                  cssClass: "secondary",
                  handler: () => {
                    localStorage.setItem(
                      "thelivery-update",
                      new Date().toISOString()
                    );
                    alert.dismiss();
                  },
                },
                {
                  text: "Sim",
                  handler: async () => {
                    window.open(
                      "https://apps.apple.com/br/app/thelivery-delivery-de-tudo/id1052415123",
                      "_target"
                    );
                  },
                },
              ],
            });
            return alert.present();
          }
        }
      });
    }, 2000);
  } else {
    GoogleAuth.initialize();
  }
});

CapacitorApp.addListener("appUrlOpen", function (event: URLOpenListenerEvent) {
  const path = event.url.split(".br").pop();

  if (path) {
    router.push({ path });
  }
});
