<template>
  <ion-page>
    <ion-content>
      <basic-header title="Sorteios" />
      <div class="circular-border">
        <raffles-list-skeleton v-if="loading"></raffles-list-skeleton>
        <ion-grid v-else class="raffles">
          <ion-row>
            <ion-col
              v-for="raffle in raffles"
              :key="raffle.id"
              size-xs="12"
              size-sm="12"
              size-md="6"
              size-lg="6"
              size-xl="6"
            >
              <ion-thumbnail :class="{ inactive: !raffle.active }">
                <ion-img
                  :src="`https://cdn.thelivery.com.br/img/banners/${raffle.img}`"
                  @click="goTo(raffle.slug)"
                ></ion-img>
              </ion-thumbnail>
            </ion-col>
          </ion-row>
          <ion-infinite-scroll @ionInfinite="getNextRaffles($event)">
            <ion-infinite-scroll-content loading-ref-spinner="dots" />
          </ion-infinite-scroll>
        </ion-grid>
        <ion-card
          v-if="!loading && raffles.length == 0"
          class="without-raffles"
        >
          <ion-card-content> Ixi... hoje não temos sorteios! </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonThumbnail,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import Raffles from "@/data/providers/raffles/raffles";
import RafflesListSkeleton from "@/components/skeletonComponents/RafflesListSkeleton.vue";
export default {
  name: "RafflesListPage",
  components: {
    IonPage,
    IonContent,
    BasicHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonThumbnail,
    IonImg,
    RafflesListSkeleton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonCard,
    IonCardContent,
  },
  data: () => ({
    raffles: [],
    loading: true,
    cursor: 2,
  }),
  async mounted() {
    this.loading = true;
    this.raffles = await Raffles();

    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Ganhe números da sorte e participe dos sorteios. Oportunidade de ganhar muitos prêmios."
      );

    this.loading = false;
  },
  methods: {
    goTo(raffle) {
      this.$router.push("/sorteios/" + raffle);
    },
    async getNextRaffles(event) {
      try {
        const newRaffles = await Raffles(this.cursor);

        this.raffles.push(...newRaffles);

        this.cursor = this.cursor + 1;
      } finally {
        event.target.complete();
      }
    },
  },
};
</script>

<style scoped>
.raffles {
  margin: 0;
}
.raffles ion-thumbnail {
  width: 100%;
  margin: 0;
  height: auto;
}

.raffles ion-img {
  border-radius: 15px;
}
.inactive {
  opacity: 0.6;
}

.without-raffles {
  text-align: center;
  width: 100%;
  margin: 0;
  border: 1px solid #ebebeb;
  border-radius: 15px;
  box-shadow: none;
}
@media only screen and (min-width: 768px) {
  .without-raffles {
    max-width: 720px;
    margin: 0 auto;
  }
}
</style>
