
import { defineComponent } from "vue";
import { IonContent } from "@ionic/vue";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";

import getPageContent from "@/data/providers/page-contents";

export default defineComponent({
  name: "DocumentPage",
  components: {
    IonContent,
    HeaderWithBalance,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    document: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      title: "",
      content: "",
    };
  },
  async mounted() {
    const pageContent = await getPageContent(this.document);

    if (pageContent?.content?.content) {
      this.title = pageContent.content.title;
      this.content = pageContent.content.content;
    }
  },
});
