import sendNotificationToken from "@/data/providers/user/send-notification-token";
import OneSignal from "onesignal-cordova-plugin";
import store from "@/data/modules/index";
import typesModule from "@/data/modules/typesModule";
import router from "@/router";
import { isPlatform } from "@ionic/vue";

class OneSignalService {
  private PushEvents = {
    UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
    CRISP_MESSAGE: "CRISP_MESSAGE",
    RATING_REPLY: "RATING_REPLY",
    PUSH: "PUSH",
    NEW_PROMOTION: "NEW_PROMOTION",
    NEW_ESTABLISHMENT: "NEW_ESTABLISHMENT",
    OUTDATED_USER_INFO: "OUTDATED_USER_INFO",
    ABANDONED_CART: "ABANDONED_CART",
    LOTTERY_WINNER: "LOTTERY_WINNER",
    NEW_MEDAL_ACHIEVED: "NEW_MEDAL_ACHIEVED",
  };

  private initOneSignalNative() {
    OneSignal.setAppId("5eb64142-0a23-41e0-95b5-89fb73ea15e2");

    OneSignal.setNotificationOpenedHandler((res) => {
      this.handleNotificationOpened(res.notification);
    });

    this.sendNotificationToken();
  }

  private async importOneSignalScript() {
    return new Promise<void>((resolve) => {
      delete window["OneSignal"];
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://cdn.onesignal.com/sdks/OneSignalSDK.js";
      script.async = true;
      script.onload = function () {
        resolve();
      };
      document.querySelector("head").appendChild(script);
    });
  }

  private async initOneSignalWeb() {
    await this.importOneSignalScript();

    const OneSignalWeb = (window as any).OneSignal || [];

    OneSignalWeb.push([
      "init",
      {
        appId: "5eb64142-0a23-41e0-95b5-89fb73ea15e2",
        autoRegister: false,
        allowLocalhostAsSecureOrigin: true,
        notifyButton: {
          enable: false,
        },
      },
    ]);

    OneSignalWeb.push(() => {
      OneSignalWeb.push(["registerForPushNotifications"]);

      OneSignalWeb.showSlidedownPrompt();
    });
  }

  public initOneSignal() {
    if (isPlatform("capacitor")) {
      this.initOneSignalNative();
    } else {
      this.initOneSignalWeb();
    }
  }

  handleGCMToken(token: string) {
    sendNotificationToken(token);
  }

  sendNotificationToken() {
    if (isPlatform("capacitor")) {
      OneSignal.getDeviceState((data) => {
        const userId = data.userId;
        (store as any).dispatch(
          typesModule.actions.SET_USER_NOTIFICATION_TOKEN,
          userId
        );
        this.handleGCMToken(userId);
      });
    } else {
      const OneSignalWeb = (window as any).OneSignal || [];

      OneSignalWeb.getUserId().then((userId) => {
        this.handleGCMToken(userId);
      });
    }
  }

  handleNotificationOpened(notification: any) {
    this.mapPushEventToHandler(notification, {
      [this.PushEvents.ABANDONED_CART]: this.openCartWithProducts,
      [this.PushEvents.UPDATE_ORDER_STATUS]: this.openOrderInfo,
      [this.PushEvents.NEW_MEDAL_ACHIEVED]: this.openModalNewMedal,
    });
  }

  openCartWithProducts() {
    //
  }
  openOrderInfo(data: any) {
    const orderId = data?.additionalData?.id_pedido;
    if (orderId) {
      router.push(`/order-details/${orderId}`);
    }
    //
  }
  openModalNewMedal() {
    //
  }

  mapPushEventToHandler(notificationData: any, reducer: any) {
    const type = notificationData.additionalData.type;

    if (reducer[type]) {
      reducer[type].bind(this)(notificationData);
    }
  }
}

export default new OneSignalService();
