import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-010fa433"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "items" }
const _hoisted_2 = { class: "suboption" }
const _hoisted_3 = { class: "check-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_radio_group, {
        "allow-empty-selection": "",
        value: _ctx.defaultValue,
        onIonChange: _cache[0] || (_cache[0] = ($event: any) => ($options.clickSuboption($event)))
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.suboptions, (suboption) => {
            return (_openBlock(), _createBlock(_component_ion_item, {
              key: suboption.id,
              class: "no-padding no-ripple-effect"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("p", null, _toDisplayString(suboption.name), 1),
                    _createElementVNode("span", null, _toDisplayString(suboption.description), 1)
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_ion_radio, {
                      mode: "md",
                      value: suboption.id
                    }, null, 8, ["value"]),
                    _createElementVNode("span", null, _toDisplayString(suboption.price > 0 ? $setup.moneyMask(suboption.price) : ""), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _: 1
  }))
}