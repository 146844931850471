const searchModules = {
  state: {
    searchSortBy: { name: "Padrão", value: "default" },
    storesSortBy: { name: "Padrão", value: "default" },
    itemsSortBy: { name: "Padrão", value: "default" },
    searchFilters: undefined,
    categories: [],
    payments: undefined,
    searchName: "",
  },

  mutations: {
    updateParams(state, value) {
      state.searchSortBy = value.sortBy;
      state.storesSortBy = value.sortBy;
      state.itemsSortBy = value.sortBy;
      state.searchFilters = value.filters;
      state.categories = value.categories;
      state.payments = value.payments;
      state.searchName = value.searchName;
    },
    cleanParams(state) {
      state.searchSortBy = { name: "Padrão", value: "default" };
      state.storesSortBy = { name: "Padrão", value: "default" };
      state.itemsSortBy = { name: "Padrão", value: "default" };
      state.categories = [];
      state.searchFilters = undefined;
      state.payments = undefined;
      state.searchName = "";
    },
    setCategory(state, value) {
      state.searchSortBy = { name: "Padrão", value: "default" };
      state.storesSortBy = { name: "Padrão", value: "default" };
      state.itemsSortBy = { name: "Padrão", value: "default" };
      state.categories = [value];
      state.searchFilters = undefined;
      state.payments = undefined;
      state.searchName = "";
    },

    setSearchName(state, value) {
      state.searchSortBy = { name: "Padrão", value: "default" };
      state.storesSortBy = { name: "Padrão", value: "default" };
      state.itemsSortBy = { name: "Padrão", value: "default" };
      state.searchName = value;
      state.searchFilters = undefined;
      state.categories = [];
      state.payments = undefined;
    },

    setSortBy(state, value) {
      state.searchSortBy = value;
    },
    storesSortBy(state, value) {
      state.storesSortBy = value;
    },
    itemsSortBy(state, value) {
      state.itemsSortBy = value;
    },

    removeParamsPayments(state, value) {
      const index = state.payments.indexOf(value);

      state.payments.splice(index, 1);
    },

    removeParamsFilters(state, value) {
      const index = state.searchFilters.indexOf(value);

      state.searchFilters.splice(index, 1);
    },
    removeParamsCategories(state, value) {
      const index = state.categories.indexOf(value);

      state.categories.splice(index, 1);
    },
  },

  actions: {
    setSearchParams({ commit }, params) {
      commit("updateParams", params);
    },
    cleanSearchParams({ commit }) {
      commit("cleanParams");
    },
    setCategory({ commit }, searchCategory) {
      commit("setCategory", searchCategory);
    },
    setSearchName({ commit }, searchName) {
      commit("setSearchName", searchName);
    },
    setSortBy({ commit }, sortBy) {
      commit("setSortBy", sortBy);
    },
    storesSortBy({ commit }, sortBy) {
      commit("storesSortBy", sortBy);
    },
    itemsSortBy({ commit }, sortBy) {
      commit("itemsSortBy", sortBy);
    },

    deleteParamsPayments({ commit }, payments) {
      commit("removeParamsPayments", payments);
    },
    deleteParamsFilters({ commit }, filters) {
      commit("removeParamsFilters", filters);
    },
    deleteParamsCategories({ commit }, categories) {
      commit("removeParamsCategories", categories);
    },
  },
};

export default searchModules;
