import axios from "@/utils/axios";

export default async function (token: string) {
  const response = await axios.post(`/user/register-device`, {
    token,
  });

  const info = response.data;

  return info;
}
