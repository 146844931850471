import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61070ce9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info" }
const _hoisted_2 = { class: "nickname" }
const _hoisted_3 = { class: "digits" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          ($props.card.type)
            ? (_openBlock(), _createBlock(_component_ion_img, {
                key: 0,
                src: `https://cdn.thelivery.com.br/static/assets/layout/card-flags/${$props.card.type.toLowerCase()}.svg`
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString($props.card.nickname), 1),
            _createElementVNode("p", _hoisted_3, "xxxx " + _toDisplayString($props.card.lastDigits), 1)
          ]),
          ($props.hasDelete)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: "delete",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.deleteCard && $options.deleteCard(...args)))
              }, [
                _createVNode(_component_ion_icon, { icon: $setup.close }, null, 8, ["icon"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}