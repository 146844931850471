import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b894a87"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tag" }
const _hoisted_2 = {
  key: 1,
  class: "highlight"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_img = _resolveComponent("ion-img")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.onlinePayment || $props.creditCard)
      ? (_openBlock(), _createBlock(_component_ion_icon, {
          key: 0,
          icon: $setup.card
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    ($props.onlinePayment)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Online"))
      : _createCommentVNode("", true),
    ($props.pix)
      ? (_openBlock(), _createBlock(_component_ion_img, {
          key: 2,
          class: "logoPix",
          src: "assets/logoPix.webp"
        }))
      : _createCommentVNode("", true)
  ]))
}