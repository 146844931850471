
import { arrowBack } from "ionicons/icons";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonImg,
} from "@ionic/vue";
import getStores from "@/data/providers/explore/lomadee/coupons-stores-by-category";
import { Ref, ref, onMounted } from "vue";
import { eventKeys, logEvent } from "@/services/analitics";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";

export default {
  name: "CouponsStoresPage",
  components: {
    IonPage,
    IonContent,
    HeaderWithBalance,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonImg,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const loadingRef = ref(true);
    const storesRef: Ref = ref([]);

    const getStoresList = async () => {
      loadingRef.value = true;

      const listStores = await getStores(props.id);

      storesRef.value = listStores;

      loadingRef.value = false;
    };

    onMounted(getStoresList);

    return {
      storesRef,
      loadingRef,
      arrowBack,
    };
  },
  methods: {
    ionViewDidEnter() {
      logEvent(eventKeys.OPEN_LOMADEE);
    },
    goBack() {
      if (history?.state?.back) {
        this.$router.back();
      } else {
        this.$router.push("/tabs/inicio");
      }
    },
    async goToStore(store) {
      this.$router.push({
        name: "coupons-online-store",
        params: { id: store.id, store: store.name, image: store.image },
      });
    },
  },
};
