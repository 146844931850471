import axios from "@/utils/axios";

export default async function (establishmentId: any) {
  const response = await axios.get(
    `/establishments/${establishmentId}/neighborhood`
  );

  const responseData = response.data;

  const neighborhoods = responseData.map((establishmentNeighborhood) => {
    return {
      id: establishmentNeighborhood.neighborhood.id,
      name: establishmentNeighborhood.neighborhood.name,
      deliveryFee: establishmentNeighborhood.delivery_fee,
    };
  });

  return neighborhoods;
}
