
import {
  IonContent,
  modalController,
  IonImg,
  IonCard,
  IonCardContent,
  IonButton,
} from "@ionic/vue";
import { defineComponent } from "vue";
import storeClicked from "@/data/providers/explore/lomadee/store-clicked";

export default defineComponent({
  name: "ModalGoStore",
  components: { IonContent, IonImg, IonCard, IonCardContent, IonButton },
  props: {
    store: { type: Object, default: null },
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
    async goStore(id, link) {
      try {
        storeClicked(id);
      } catch (err) {
        modalController.dismiss();
      }
      window.open(link, "_system");
      modalController.dismiss();
    },
  },
});
