import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b7cf350"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "closed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_lazy_image = _resolveComponent("v-lazy-image")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle($options.cssProps),
    class: "avatar-container"
  }, [
    _createVNode(_component_v_lazy_image, {
      class: "avatar",
      src: $props.logo,
      "src-placeholder": "/assets/thelivery_logo.jpg",
      alt: "Logotipo do estabelecimento"
    }, null, 8, ["src"]),
    (!$props.status)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Fechado"))
      : _createCommentVNode("", true)
  ], 4))
}