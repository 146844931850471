import { createStore } from "vuex";
import neighborhoodsModule from "./neighborhoodModule";
import userModule from "./userModule";
import cardsModule from "./cardModule";
import cartModule from "./cartModule";
import addressModule from "./lastAddressModule";
import createPersistedState from "vuex-persistedstate";
import searchModules from "./searchModules";
import paymentModule from "./lastPaymentMethodModule";
import preferencesModule from "./preferencesModule";
import othersModule from "./othersModule";

const store = createStore({
  modules: {
    neighborhood: neighborhoodsModule,
    user: userModule,
    card: cardsModule,
    search: searchModules,
    cart: cartModule,
    lastAddress: addressModule,
    lastPayment: paymentModule,
    preferences: preferencesModule,
    others: othersModule,
  },
  plugins: [createPersistedState({ key: "thelivery-vuex-state" })],
});

export default store;
