import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_skeleton_text, {
      class: "title",
      animated: ""
    }),
    _createVNode(_component_ion_skeleton_text, {
      animated: "",
      class: "status"
    }),
    _createVNode(_component_ion_skeleton_text, {
      animated: "",
      class: "separate"
    }),
    _createVNode(_component_ion_skeleton_text, {
      animated: "",
      class: "status"
    }),
    _createVNode(_component_ion_card, { class: "items" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" })
      ]),
      _: 1
    })
  ], 64))
}