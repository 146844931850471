import axios from "@/utils/axios";

export default async function (neighborhoodId?) {
  const route = neighborhoodId
    ? `neighborhood/${neighborhoodId}/coupons`
    : "user/coupons";

  const response = await axios.get(route);

  const coupons = response.data;

  return coupons;
}
