
import { IonCard, IonButton, modalController } from "@ionic/vue";
import StoreExternalLogo from "@/components/visualElements/StoreExternalLogo.vue";
import { dateToBr } from "@/utils/functions/date";

import TransitionToExternalStorePage from "@/views/Explore/TransitionToExternalStorePage.vue";

export default {
  name: "CouponsCards",
  components: {
    IonCard,
    //IonButton,
    StoreExternalLogo,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return { dateToBr };
  },
  methods: {
    async openExternal() {
      const modal = await modalController.create({
        component: TransitionToExternalStorePage,
        componentProps: {
          store: {
            name: this.item.store_name,
            logo: this.item.image || this.item.logo,
            link: this.item.link,
          },
          message: this.item.description,
          coupon: this.item.code,
          buttonText: this.item.button_text,
          couponId: this.item.id,
        },
      });
      modal.present();
    },
  },
};
