import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31a5bd09"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "currentSessions" }
const _hoisted_3 = { class: "active-sessions" }
const _hoisted_4 = { class: "sessions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_skeleton_text, {
        animated: "",
        class: "h4"
      }),
      _createVNode(_component_ion_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_skeleton_text, {
                animated: "",
                class: "parag"
              }),
              _createVNode(_component_ion_skeleton_text, {
                animated: "",
                class: "span"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("header", null, [
        _createVNode(_component_ion_skeleton_text, {
          animated: "",
          class: "h4"
        })
      ]),
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
        return _createVNode(_component_ion_card, { key: i }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_card_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_skeleton_text, {
                  animated: "",
                  class: "checkbox"
                }),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_ion_skeleton_text, {
                    animated: "",
                    class: "parag"
                  }),
                  _createVNode(_component_ion_skeleton_text, {
                    animated: "",
                    class: "span"
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1024)
      }), 64))
    ])
  ]))
}