import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "circular-border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_with_balance = _resolveComponent("header-with-balance")!
  const _component_categories = _resolveComponent("categories")!
  const _component_offers_cards = _resolveComponent("offers-cards")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_header_with_balance, {
            title: "Ofertas",
            "has-go-back-modal": false,
            "has-go-back": true
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_categories, {
              categories: $data.categories,
              onSelectCategory: $options.updateCategory
            }, null, 8, ["categories", "onSelectCategory"]),
            _createVNode(_component_offers_cards, { items: $data.offers }, null, 8, ["items"]),
            ($data.offers.length)
              ? (_openBlock(), _createBlock(_component_ion_infinite_scroll, {
                  key: 0,
                  onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => ($options.getNextOffers($event)))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_infinite_scroll_content, { "loading-ref-spinner": "dots" })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}