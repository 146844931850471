
import {
  IonPage,
  IonIcon,
  IonContent,
  IonButton,
  IonSkeletonText,
  toastController,
  modalController,
} from "@ionic/vue";
import {
  informationCircleOutline,
  star,
  arrowBack,
  heart,
  share,
  cart,
  flame,
} from "ionicons/icons";
import { useRouter } from "vue-router";

import getStore from "@/data/providers/explore/external-store";
import likeExternalStore from "@/data/providers/explore/like-external-store";
import getPageContent from "@/data/providers/page-contents";
import { logEvent, eventKeys } from "@/services/analitics";

import { Share } from "@capacitor/share";
import { Browser } from "@capacitor/browser";

import { Capacitor } from "@capacitor/core";
import Badge from "@/components/visualElements/Badge.vue";
import CouponsCards from "@/components/explore/CouponsCards.vue";
import OffersCards from "@/components/explore/OffersCards.vue";
import StoreExternalLogo from "@/components/visualElements/StoreExternalLogo.vue";
import Login from "@/views/Login/Login.vue";
import TransitionToExternalStorePage from "@/views/Explore/TransitionToExternalStorePage.vue";

export default {
  name: "ExternalStore",
  components: {
    IonPage,
    IonIcon,
    IonContent,
    IonButton,
    IonSkeletonText,
    Badge,
    StoreExternalLogo,
    CouponsCards,
    OffersCards,
  },
  props: {
    storeId: {
      type: String,
      default: null,
    },
    storeSlug: {
      type: String,
      default: null,
    },
  },
  setup() {
    const router = useRouter();

    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    return {
      informationCircleOutline,
      star,
      arrowBack,
      share,
      heart,
      goBack,
      cart,
      flame,
    };
  },
  data() {
    return {
      loading: true,
      store: {},
      coupons: [],
      offers: [],
      warning: null,
      about: null,
    };
  },
  methods: {
    async ionViewWillEnter() {
      await this.getEstablishmentData();
      logEvent(eventKeys.OPEN_EXTERNAL_STORE);
    },
    async getEstablishmentData() {
      this.loading = true;

      const store = await getStore(this.$route.params.storeSlug);
      this.store = store;

      this.coupons = store.coupons.map((item) => ({
        ...item,
        logo: store.logo,
        //eslint-disable-next-line
        store_name: store.name,
      }));

      this.offers = store.offers;

      try {
        const warning = await getPageContent("external-store-warning");
        this.warning = warning.content;
        const about = await getPageContent("external-store-about");
        this.about = about.content;
      } finally {
        this.loading = false;
      }
    },
    async openLoginModal() {
      const modal = await modalController.create({
        component: Login as any,
        componentProps: { isModal: true },
        cssClass: "custom-modal-full-transparent",
      });

      return modal.present();
    },

    async likeStore() {
      if (!this.$store.state.user.token) {
        await this.openLoginModal();
        return;
      }

      await likeExternalStore(this.store.id);

      this.store.liked = !this.store.liked;
    },

    goToRelative(url) {
      this.$router.push(this.$route.path + url);
    },

    async goToStore() {
      const modal = await modalController.create({
        component: TransitionToExternalStorePage,
        componentProps: {
          store: this.store,
          message:
            this.store.cashback &&
            `Você receberá ${this.store.cashback}% do seu dinheiro de volta`,
          buttonText: this.store.button_text,
          storeId: this.store.id,
        },
      });
      modal.present();
    },

    async openExternal(link) {
      await Browser.open({ url: link });
    },

    async shareEstablishment() {
      const url = `${
        this.$store.state.others.appOptions?.base_url ||
        "https://thelivery.com.br"
      }/lojas-online/${this.store.slug}`;
      if (Capacitor.isNativePlatform()) {
        await Share.share({
          text: `Hey! Você já conhece o ${this.store.name}? É simplesmente incrível :) Use o link abaixo e peça no TheLivery <3`,
          url,
        });
      } else {
        navigator.clipboard.writeText(url),
          toastController
            .create({
              message: "Endereço copiado",
              duration: 2000,
            })
            .then((toast) => toast.present());
      }
    },
  },
};
