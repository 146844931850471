<template>
  <ion-page>
    <ion-content class="page">
      <div class="first-section">
        <div class="align-container">
          <div class="header">
            <img
              src="assets/webpage/logo.webp"
              alt="Logo marca do TheLivery"
              title="Logo marca do TheLivery"
              class="header__logo"
            />
          </div>

          <div class="grid-2x2">
            <div class="presentation-card">
              <h1 class="presentation-card__title">
                Pense em uma <span class="text-accent">comida</span>...
                <br />
                Do <span class="text-accent"> pãozinho</span> ao
                <span class="text-accent"> sushi</span>, <br />
                você encontra de tudo aqui!
              </h1>

              <p class="presentation-card__description">
                Peça comida online nos melhores
                <span class="text-accent">Restaurantes</span> e
                <span class="text-accent">Lanchonetes</span> da sua cidade.
              </p>

              <div class="presentation-card__options-use">
                <div class="options-use">
                  <button
                    class="clickable-component download-app"
                    @click="openCurrentNativeStore()"
                  >
                    Baixar App
                  </button>
                  <button
                    class="clickable-component use-site"
                    @click="openSite()"
                  >
                    Pedir Pelo Site
                  </button>
                </div>

                <div class="native-stores text-2size-big">
                  <ion-icon
                    :icon="logoAndroid"
                    @click="openAndroidStore()"
                  ></ion-icon>
                  <ion-icon
                    :icon="logoApple"
                    @click="openAppleStore()"
                  ></ion-icon>
                </div>
              </div>
            </div>
            <div>
              <img
                class="smartphone-image"
                src="assets/webpage/smartphone.webp"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="second-section">
        <div class="second-section-container align-container">
          <div class="second-section-presentation">
            <h4 class="presentation__title text-2size-big">
              A cada pedido mais um cliente feliz!
            </h4>
            <p class="presentation__description text-size-default">
              Encontre as melhores opções nos restaurantes da sua cidade.
              Utilize nossos filtros, confira as avaliações dos restaurantes,
              busque seu prato favorito por categoria e saiba quais restaurantes
              mais próximos de você estão abertos hoje.
            </p>
          </div>

          <div class="second-section__cards">
            <div class="card full-size card--first">
              <div
                class="card-transparent-bg-location bg-location-right-top background-sushi"
              >
                <div class="card__content transparent-background">
                  <h4 class="text-2size-big card__title">
                    As comidas orientais mais saborosas!
                  </h4>
                  <p class="card__description text-size-default">
                    Sushi, sashimi, temaki, salmão grelhado, entre outros, são
                    diversos restaurantes japoneses na palma da sua mão!
                  </p>
                </div>
              </div>
            </div>
            <div class="second-section__smalll-cards grid-2x2">
              <div class="card card--second">
                <div
                  class="card-transparent-bg-location bg-location-left-center background-hamburger"
                >
                  <div class="card__content transparent-background">
                    <h4 class="text-size-big card__title">
                      Lanches para todos os gostos e tamanhos.
                    </h4>
                    <p class="card__description text-size-default">
                      Os melhores lanches da cidade.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card card--last">
                <div
                  class="card-transparent-bg-location bg-location-right-center background-pizza"
                >
                  <div class="card__content transparent-background">
                    <h4 class="text-size-big card__title">
                      Uma variedade incrível de pizzas para você!
                    </h4>
                    <p class="card__description text-size-default">
                      Seja qual for seu gosto, você encontra a pizza ideal para
                      satisfazê-lo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="benefits grid-2x2">
        <div class="benefits__cards">
          <ion-card
            v-for="(benefit, index) in benefits"
            :key="index"
            class="benefit_card"
          >
            <img :src="benefit.icon" :alt="benefit.alt" />
            <div class="benefit_card__content">
              <ion-card-header>
                <ion-card-title>{{ benefit.title }}</ion-card-title>
              </ion-card-header>
              <ion-card-content>
                {{ benefit.description }}
              </ion-card-content>
            </div>
          </ion-card>
          <button
            class="clickable-component download-app"
            @click="openCurrentNativeStore()"
          >
            Baixar App
          </button>
        </div>

        <div class="pizza-container">
          <img
            alt="App de Delivery, TheLivery"
            class="img-fluid grow pizza rotate aos-init aos-animate"
            data-aos="fade-up-left"
            title="Pedir comida online, pelo app TheLivery"
            src="/assets/webpage/pizza.webp"
          />
        </div>
      </div>

      <div class="collaborators">
        <div class="collaborators__cards grid-2x2">
          <div class="collaborators__card-information">
            <div class="card__header">
              <ion-icon :icon="logoUsd" color="primary"></ion-icon>
              <h4 class="card__title text-size-big">
                Tem um restaurante?<br />
                Seja nosso parceiro!
              </h4>
            </div>

            <p class="card__description">
              Junte-se a mais de 660 restaurantes que confiam em nosso trabalho
              e garanta o crescimento do seu negócio, com agilidade e segurança.
              <br />
              <br />
              Oferecemos a melhor plataforma online de controle de pedidos para
              o seu negócio com dados atualizados em tempo real.
              <br />
              <br />
              E o melhor de tudo, você paga somente pelo resultado.
              <br />
              <br />
              Junte-se a nós nessa jornada rumo ao sucesso!
            </p>
          </div>
          <div class="collaborators__cards-image">
            <img
              src="assets/webpage/smartphone.webp"
              alt="Celular com app instalado"
            />
          </div>
        </div>
      </div>
      <div class="footer-container">
        <div class="content">
          <div class="navigation">
            <div
              v-for="(cardMenu, index) in cards"
              :key="index"
              class="footer-card"
            >
              <h4 class="card__title">{{ cardMenu.title }}</h4>
              <div class="card__links">
                <a
                  v-for="(link, linksIndex) in cardMenu.links"
                  :key="linksIndex"
                  :href="link.url"
                  >{{ link.title }}</a
                >
              </div>
            </div>
          </div>
          <div class="social-media">
            <a
              v-for="(social, index) in socialMedias"
              :key="index"
              class="social-media__link"
              alt="Peça comida Online de forma prática em Delivery de Comida em Restaurantes e Lanchonetes, Seja: Sushi, Pizza, Lanches, Açaí qualquer entrega de comida"
              :href="social.url"
              target="_blank"
              title="Peça comida Online de forma prática em Delivery de Comida em Restaurantes e Lanchonetes, Seja: Sushi, Pizza, Lanches, Açaí qualquer entrega de comida"
            >
              <ion-icon :icon="social.icon"></ion-icon>
            </a>
          </div>
          <p class="title-light">
            TheLivery 2022 &copy; A melhor forma de pedir comida online.
          </p>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonIcon,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  isPlatform,
  toastController,
} from "@ionic/vue";
import {
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoLinkedin,
  logoAndroid,
  logoApple,
  logoUsd,
} from "ionicons/icons";

import typesModule from "@/data/modules/typesModule";

export default {
  name: "HomePage",
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
  },
  setup() {
    return {
      logoAndroid,
      logoApple,
      logoUsd,
    };
  },
  data() {
    return {
      cards: [
        {
          title: "Cidades",
          links: [
            {
              title: "Vitória da Conquista",
              url: "#",
            },
            {
              title: "Jequié",
              url: "#",
            },
          ],
        },
        {
          title: "Sobre",
          links: [
            {
              title: "Equipe",
              url: "#",
            },
            {
              title: "Ajuda",
              url: "faq",
            },
          ],
        },
        {
          title: "Navegue",
          links: [
            {
              title: "Corporativo ",
              url: "https://corporativo.thelivery.com.br",
            },
            {
              title: "Cadastre seu estabelecimento",
              url: "/aumente-suas-vendas",
            },
          ],
        },
      ],
      socialMedias: [
        {
          url: "https://www.instagram.com/theliverybr/?hl=pt-br",
          icon: logoInstagram,
        },
        {
          url: "https://www.facebook.com/theliverybr/",
          icon: logoFacebook,
        },
        {
          url: "https://twitter.com/theliverybr",
          icon: logoTwitter,
        },
        {
          url: "https://www.linkedin.com/company/thelivery",
          icon: logoLinkedin,
        },
      ],
      benefits: [
        {
          title: "Os melhores cardápios à sua mão",
          description:
            "Encontre os melhores cardápios de restaurantes da sua cidade. Utilize nossos filtros, confira as avaliações dos restaurantes, busque seu prato favorito por categoria e saiba quais restaurantes mais próximos de você estão abertos hoje.",
          icon: "assets/webpage/benefit-1.webp",
          alt: "Os Melhores Restaurantes",
        },
        {
          title: "Mais de 660 restaurantes disponíveis",
          description:
            "Do sushi ao acarajé, do sopa ao açaí, você encontra de tudo aqui.",
          icon: "assets/webpage/benefit-2.webp",
          alt: "Restaurantes próximos de mim, Delivery Online, Sushis, Pizzas, Lanches",
        },
        {
          title: "Economize com mais de 840 promoções!",
          description:
            "Essa é pra você que gosta de economizar, temos promoções e cupons de desconto de todos os tipos para você fazer seu pedido online no seu delivery de comida favorito.",
          icon: "assets/webpage/benefit-3.webp",
          alt: "Promoções, Sushis, Pizzas, Sorteios",
        },
      ],
    };
  },
  mounted() {
    this.toastCookies();
  },
  methods: {
    async toastCookies() {
      if (!this.$store.state.others.acceptedCookies) {
        const toast = await toastController.create({
          message:
            "TheLivery usa cookies para personalizar a comunicação e melhorar a sua experiência no site. Ao continuar navegando, você concorda com a nossa Política de privacidade.",
          cssClass: "toast-cookies",
          position: "bottom",
          buttons: [
            {
              text: "Continuar e Fechar",
              role: "cancel",
              handler: () => {
                this.$store.dispatch(typesModule.actions.ACCEPT_COOKIES);
              },
            },
          ],
        });
        await toast.present();
      }
    },
    openSite() {
      this.$router.push("/tabs/inicio");
    },
    openAndroidStore() {
      window.location.assign(
        "https://play.google.com/store/apps/details?id=br.com.thelivery"
      );
    },
    openAppleStore() {
      window.location.assign(
        "https://apps.apple.com/br/app/thelivery-delivery-de-comida/id1052415123"
      );
    },
    openCurrentNativeStore() {
      if (isPlatform("ios")) {
        this.openAppleStore();
      } else {
        this.openAndroidStore();
      }
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  text-align: center;
  height: 150px;
  padding: 15px;
}
.header__logo {
  width: 200px;
}

.nav-menu {
  display: flex;
  justify-items: center;
  justify-content: flex-end;
  width: 100%;
}

.nav-menu__item {
  font-size: 18px;
  color: var(--ion-color-primary);
  background: none;
}

@media only screen and (max-width: 768px) {
  .nav-menu {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.page {
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.first-section {
  background-image: url("/public/assets/webpage/background-icon-header.webp"),
    url("/public/assets/webpage/background-icon-header.webp"),
    url("/public/assets/webpage/background-icon-header.webp");
  background-repeat: no-repeat;
  background-size: 120px 120px, 80px 80px, 50px 50px;
  margin: 0 auto;
  background-position: right 2%, 98% 90%, 3% 80%;
}

@media only screen and (min-width: 576px) {
  .first-section {
    max-width: 540px;
  }
}
@media only screen and (min-width: 768px) {
  .first-section {
    max-width: 720px;
  }
}
@media only screen and (min-width: 992px) {
  .first-section {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .first-section {
    max-width: 1100px;
    background-position: right 5%, right 90%, left 77%;
  }
}

.grid-2x2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.align-container {
  margin-right: auto;
  margin-left: auto;
  align-items: center;
}

.presentation-card {
  text-align: center;
}
.presentation-card__title {
  font-weight: 500;
  font-size: 28px;
  line-height: 28px;
}
.presentation-card__description {
  color: #212529;
  font-weight: 200;
}

.smartphone-image {
  max-width: 90%;
}

.native-stores ion-icon {
  margin: 16px;
}

.second-section {
  background-image: url("/public/assets/webpage/diagonal-background-full.webp");
  background-repeat: no-repeat;
  background-position: -15px 0;
  background-size: 105% 100%;

  padding: 140px 80px 180px 80px;

  color: #fff;
  margin-top: -140px;
  z-index: -2;

  display: block;
  overflow: hidden;
  max-width: 100%;
}
.second-section__cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.card__content {
  position: relative;
  text-align: left;
  display: flex;
  height: 100%;
  justify-content: center;
  color: white;

  justify-items: center;
  flex-direction: column;
}
.card__img-background {
  position: absolute;
  z-index: -1;
}
.card--first {
  background-color: #f39c12;
}
.card--first .card__content {
  justify-content: flex-start;
}
.card--first .card__description {
  margin: 0;
}
.card--second {
  background-color: #1abc9c;
}
.card--second .card__title,
.card--second .card__description {
  text-align: right;
}

.card--last {
  background-color: #f1c40f;
}

.second-section__smalll-cards {
  font-size: 24px;
  line-height: 27px;
  margin-top: 1.5rem;
  gap: 30px;
}

.benefits {
  z-index: -9;
  background-image: url("/public/assets/webpage/diagonal-background-white-up.webp");
  background-size: 100% auto;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  padding: 5%;
  padding-bottom: 80px;
  background-color: #f0efe9;
}
.benefits__cards {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.benefits__col {
  min-width: 250px;
}
.collaborators {
  padding: 5%;
}

.collaborators__card-information {
  padding: 10%;
}
.collaborators__card-information .card__header {
  display: flex;
  width: 100%;
  align-items: center;
}
.collaborators__card-information .card__header ion-icon {
  font-size: 64px;
  padding: 20px;
}

.collaborators__card-information .card__description {
  color: #212529;
  font-weight: 200;
}

.text-accent {
  color: var(--ion-color-primary);
}
.text-size-big {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
}
.text-2size-big {
  font-size: 38px;
  line-height: 38px;
  font-weight: 500;
}

.text-size-default {
  font-size: 10pt;
  line-height: 12pt;
}
.transparent-background {
  height: 100%;
  background-repeat: no-repeat;
  min-height: 154px;
  padding: 20px;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.2);
}
.card-transparent-bg-location {
  display: block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: auto 90%;
}
.bg-location-right-top {
  background-position: 100% 0;
  text-align: left;
}
.bg-location-left-center {
  background-position: 0;
  text-align: right;
}
.bg-location-right-center {
  background-position: 100%;
  text-align: left;
}

.background-sushi {
  background-image: url("/public/assets/webpage/sushi.webp");
}
.background-hamburger {
  background-image: url("/public/assets/webpage/hamburger.webp");
}
.background-pizza {
  background-image: url("/public/assets/webpage/pizza.webp");
}

@media (min-width: 900px) {
  .pizza-container img {
    z-index: 99;
    margin-top: -30px;
    margin-left: 200px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .align-container {
    max-width: 540px;
  }
  .full-padding {
    max-height: auto;
  }
  .second-section {
    padding: 140px 30px 80px 30px;
  }
  .second-section__cards .card {
    text-align: center;
    background-size: auto 60%;
    background-position: center;
  }

  .second-section__smalll-cards {
    flex-direction: column;
  }
  .text-size-big {
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    margin: 0;
  }
  .text-2size-big {
    font-size: 36px;
    line-height: 36px;
    font-weight: 500;
    margin: 0;
  }
}

.footer-container {
  width: 100%;
  background-color: #f0efe9;
  padding: auto;
  padding: 3rem;
}
.navigation {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  display: flex;
  justify-content: space-around;
}
.card {
  text-align: center;
  line-height: 1.5;
  color: #212529;
}

.card__links a {
  color: #212529;
  text-decoration: none;
  margin-bottom: 5px;
}
.card__links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media only screen and (max-width: 768px) {
  .navigation {
    display: inherit;
  }
}
.title-light {
  font-size: 10pt;
  line-height: 12pt;
  color: #212529;
  font-weight: 200;
  text-align: center;
}

.download-app {
  margin: 16px;
  padding: 13px;
  border: 2px solid #262626;
  color: #262626;
  background: 0 0;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  background: var(--ion-color-primary);
  color: white;
  border: 2px var(--ion-color-primary) solid;
  font-size: 20px;
}
.download-app:hover {
  padding: 16px;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.14), 0 6px 6px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
.use-site {
  margin: 16px;
  padding: 13px;
  border: 2px solid #262626;
  color: #262626;
  background: 0 0;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 20px;
}
.use-site:hover {
  padding: 16px;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.14), 0 6px 6px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.benefit_card {
  display: grid;
  align-items: center;
  grid-template-columns: minmax(40%, 1fr) minmax(60%, 1fr);
}
.benefit_card__content {
  width: 100%;
  padding: 5%;
}

@media only screen and (max-width: 768px) {
  .benefit_card {
    display: flex;
    flex-direction: column;
  }
}

.footer-card {
  width: 100%;
  height: 100%;

  min-height: 154px;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  overflow: hidden;
  outline: none;
  border: none;
  background-repeat: no-repeat;
  background-size: auto 90%;
  margin-top: 40px;
  text-align: left;
}

.social-media {
  text-align: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding-top: 3rem;
}

.social-media__link {
  font-size: 32px;
  width: bold;
  margin: 5px;
}
</style>