
import { IonFab, IonFabButton, IonIcon } from "@ionic/vue";
import { chatbubblesOutline } from "ionicons/icons";

import { toggleChat } from "@/services/chat";

export default {
  name: "ChatButton",
  components: { IonFab, IonFabButton, IonIcon },
  setup() {
    return {
      chatbubblesOutline,
      toggleChat,
    };
  },
};
