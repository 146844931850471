
import {
  IonContent,
  IonImg,
  IonIcon,
  IonCard,
  IonCardContent,
  IonButton,
  modalController,
  toastController,
} from "@ionic/vue";
import { copy } from "ionicons/icons";
import { defineComponent } from "vue";
import storeClicked from "@/data/providers/explore/lomadee/store-clicked";

import { Clipboard } from "@capacitor/clipboard";

export default defineComponent({
  name: "ModalGoStore",
  components: {
    IonContent,
    IonImg,
    IonCard,
    IonCardContent,
    IonButton,
    IonIcon,
  },
  props: {
    store: { type: Object, default: null },
    coupon: { type: String, default: "" },
  },
  setup() {
    return { copy };
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
    async goStore(id, link) {
      try {
        storeClicked(id);
      } catch (err) {
        modalController.dismiss();
      }
      window.open(link, "_system");
      modalController.dismiss();
    },
    async copyCoupon() {
      await Clipboard.write({ string: this.coupon });

      const toast = await toastController.create({
        message: "Cupom copiado.",
        duration: 2000,
      });
      toast.present();
    },
  },
});
