import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
        return _createVNode(_component_ion_item, {
          key: i,
          lines: "none"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_skeleton_text, { animated: "" })
          ]),
          _: 2
        }, 1024)
      }), 64))
    ]),
    _: 1
  }))
}