import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ad936d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_with_balance = _resolveComponent("header-with-balance")!
  const _component_establishment_card = _resolveComponent("establishment-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_EstablishmentCardSkeleton = _resolveComponent("EstablishmentCardSkeleton")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_header_with_balance, {
            class: "balance-header",
            title: _ctx.banner
          }, null, 8, ["title"]),
          _createElementVNode("div", _hoisted_1, [
            (!_ctx.loadingRef)
              ? (_openBlock(), _createBlock(_component_ion_grid, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.establishmentsRef, (establishment) => {
                          return (_openBlock(), _createBlock(_component_ion_col, {
                            key: establishment.id,
                            class: "no-padding",
                            "size-xs": "12",
                            "size-sm": "12",
                            "size-md": "6",
                            "size-lg": "6",
                            "size-xl": "4",
                            onClick: ($event: any) => (
                _ctx.goToEstablishment(
                  establishment.slug,
                  _ctx.slugify(establishment.category)
                )
              )
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_establishment_card, { establishment: establishment }, null, 8, ["establishment"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_infinite_scroll, {
                      onIonInfinite: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getNextEstablishments($event)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_infinite_scroll_content, { "loading-ref-spinner": "dots" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_grid, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
                          return _createVNode(_component_ion_col, {
                            key: i,
                            "size-xs": "12",
                            "size-sm": "12",
                            "size-md": "6",
                            "size-lg": "6",
                            "size-xl": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_EstablishmentCardSkeleton)
                            ]),
                            _: 2
                          }, 1024)
                        }), 64))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}