
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonPage,
  IonCard,
  IonCardContent,
} from "@ionic/vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import { card, heart, heartOutline } from "ionicons/icons";
import { defineComponent } from "vue";

import likeStore from "@/data/providers/establishments/like-establishment";
import likedEstablishments from "@/data/providers/establishments/liked-establishments";
import EstablishmentCardSkeleton from "@/components/skeletonComponents/EstablishmentCardSkeleton.vue";
import DeliveryTime from "@/components/visualElements/DeliveryTime.vue";
import CashbackBadge from "@/components/visualElements/CashbackBadge.vue";
import CouponBadge from "@/components/visualElements/CouponBadge.vue";
import DeliveryPayment from "@/components/visualElements/DeliveryPayment.vue";
import StoreLogo from "@/components/visualElements/StoreLogo.vue";

import { slugify } from "@/utils/functions/string";

export default defineComponent({
  name: "FavoritesListPage",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonCard,
    IonCardContent,
    BasicHeader,
    EstablishmentCardSkeleton,
    DeliveryTime,
    CashbackBadge,
    CouponBadge,
    DeliveryPayment,
    StoreLogo,
  },
  setup() {
    return {
      card,
      heart,
      heartOutline,
      slugify,
    };
  },
  data: function () {
    return {
      loading: true,
      establishments: [],
    };
  },
  async mounted() {
    this.loading = true;
    this.establishments = await likedEstablishments();

    this.establishments.forEach(function (element) {
      element.like = true;
    });

    this.loading = false;
  },
  methods: {
    async likeStore(storeId) {
      const neighborhoodId = 0;

      this.establishments.forEach(function (element) {
        if (element.id == storeId) {
          element.like = !element.like;
        }
      });

      await likeStore(neighborhoodId, storeId);
    },
    goToStore(slug, categorySlug) {
      const citySlug = this.$store.state.neighborhood.citySlug;

      this.$router.push(
        `/loja/${citySlug || "categoria"}/${categorySlug}/${slug}`
      );
    },
  },
});
