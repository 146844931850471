
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  toastController,
  modalController,
  alertController,
  loadingController,
  isPlatform,
} from "@ionic/vue";
import { cameraOutline } from "ionicons/icons";
import userInfo from "@/data/providers/user/user-info";
import uploadAvatar from "@/data/providers/user/upload-avatar";
import sendValidationSMS from "@/data/providers/user/sendValidationSMS";
import { Ref, ref, onMounted } from "vue";
import { Camera, CameraResultType } from "@capacitor/camera";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import ProfileSkeleton from "@/components/skeletonComponents/ProfileSkeleton.vue";
import { useStore } from "vuex";
import typesModule from "@/data/modules/typesModule";
import ValidatePhoneVue from "../Login/ValidatePhone.vue";
import getPageContent from "@/data/providers/page-contents";
import { App } from "@capacitor/app";

export default {
  name: "ProfileInfo",
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    ProfileSkeleton,
  },
  setup() {
    const infoRef: Ref<any> = ref({});
    const content: Ref<any> = ref({
      buttons: [
        {
          title: "Meus Dados",
          content: "Minhas informações pessoais",
          url: "/edit-profile",
          active: true,
          minVersion: 500000,
        },
        {
          title: "Carteira",
          content: "Minha carteira digital (saldo, cartões, etc)",
          url: "/wallet",
          active: true,
          minVersion: 500000,
        },
        {
          title: "Precisa de ajuda?",
          content: "Entre em contato conosco",
          url: "/faq",
          active: true,
          minVersion: 500000,
        },
        {
          title: "Sorteios",
          content: "Confira os sorteios ativos",
          url: "/sorteios",
          active: true,
          minVersion: 500000,
        },
        {
          title: "Endereços",
          content: "Meus endereços de entrega",
          url: "/address",
          active: true,
          minVersion: 500000,
        },
        {
          title: "Cupons",
          content: "Cupons disponíveis para você",
          url: "/cupons",
          active: true,
          minVersion: 500000,
        },
        {
          title: "Favoritos",
          content: "Minhas lojas favoritas",
          url: "/favorites",
          active: true,
          minVersion: 500000,
        },
        {
          title: "Sessões ativas",
          content: "Minhas sessões no TheLivery",
          url: "/active-sessions",
          active: true,
          minVersion: 500000,
        },
      ],
    });
    const loadingRef = ref(true);
    const appVersion = ref();
    const store = useStore();

    const createToast = (message) => {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    };

    const getUserInfo = async () => {
      loadingRef.value = true;

      const userInfoData = await userInfo();
      infoRef.value = userInfoData;

      const pageContent = await getPageContent("profile");

      if (pageContent?.content?.buttons) {
        content.value = pageContent.content;

        if (isPlatform("capacitor")) {
          const appInfo = await App.getInfo();
          appVersion.value = appInfo.build;
        }
      }

      store.dispatch(typesModule.actions.UPDATE_USER, userInfoData);

      loadingRef.value = false;
    };

    const logoff = async () => {
      const alert = await alertController.create({
        subHeader: "Quer mesmo sair?",
        buttons: [
          {
            text: "Não",
            role: "cancel",
          },
          {
            text: "Sim",
            handler: async () => {
              store.dispatch(typesModule.actions.LOGOFF);
            },
          },
        ],
      });
      await alert.present();
    };

    const sendCodePhone = async () => {
      const validationPhone = await sendValidationSMS();
      createToast(validationPhone.message);

      const modal = await modalController.create({
        component: ValidatePhoneVue as any,
        componentProps: { phone: infoRef.value.phone },
        cssClass: "custom-modal-full-transparent",
      });

      modal.present();
    };
    onMounted(getUserInfo);

    return {
      loadingRef,
      cameraOutline,
      infoRef,
      sendCodePhone,
      logoff,
      createToast,
      content,
      appVersion,
      getUserInfo,
    };
  },
  computed: {
    name() {
      return this.$store.state.user.name;
    },
    email() {
      return this.$store.state.user.email;
    },
    phoneVerified() {
      return this.$store.state.user.phoneVerified;
    },
    cashbackValue() {
      return this.$store.state.user.cashbackValue;
    },
    validButtons() {
      return this.content.buttons.filter(
        (button) =>
          button.active &&
          (!this.appVersion || this.appVersion > button.minVersion)
      );
    },
  },
  methods: {
    goTo(url) {
      this.$router.push(url);
    },
    async openPhoto() {
      //await Camera.requestPermissions();
      const image = await Camera.getPhoto({
        quality: 80,
        width: 800,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        promptLabelPhoto: "Da Galeria",
        promptLabelPicture: "Tirar uma Foto",
        promptLabelCancel: "Cancelar",
        promptLabelHeader: "Selecione sua Foto",
      });

      const loading = await loadingController.create({});

      try {
        loading.present();

        const blob = await fetch(image.webPath).then((res) => res.blob());

        const response = await uploadAvatar(blob);
        if (!response.success) {
          throw new Error("Erro ao enviar foto");
        }
        await this.getUserInfo();
        await loading.dismiss();
        this.createToast("Foto enviada com sucesso!");
      } catch (err) {
        await loading.dismiss();
        this.createToast(
          "Ocorreu um erro ao enviar sua foto, por favor, tente novamente!"
        );
      }
    },
  },
};
