const othersModule = {
  state: {
    renderingTabs: undefined,
    acceptedCookies: false,
    appOptions: undefined,
    ref: undefined,
  },
  mutations: {
    setRenderingTabs(state: any, value: any) {
      state.renderingTabs = value;
    },
    updateAppOptions(state: any, value: any) {
      state.appOptions = value;
    },
    setRef(state: any, value: any) {
      state.ref = value;
    },
    acceptCookies(state: any) {
      state.acceptedCookies = true;
    },
  },
  actions: {
    setRenderingTabs({ commit }: any, tab: any) {
      commit("setRenderingTabs", tab);
    },
    updateAppOptions({ commit }: any, appOptions) {
      commit("updateAppOptions", appOptions);
    },
    setRef({ commit }: any, ref: any) {
      commit("setRef", ref);
    },
    acceptCookies({ commit }: any) {
      commit("acceptCookies");
    },
  },
};

export default othersModule;
