
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  modalController,
  IonCard,
} from "@ionic/vue";
import { defineComponent } from "vue";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import { hiddenEmail } from "@/utils/functions/email";

export default defineComponent({
  name: "ChooseEmail",
  components: {
    IonContent,
    IonPage,
    HeaderWithBalance,
    IonList,
    IonItem,
    IonCard,
  },
  props: {
    user: {
      type: Object,
      default: undefined,
    },
  },
  setup() {
    return {
      hiddenEmail,
    };
  },
  methods: {
    goLogin(email) {
      modalController.dismiss(email);
    },
  },
});
