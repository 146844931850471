/* eslint-disable prefer-const */
import store from "@/data/modules/index";
import axios from "@/utils/axios";
import { Observable } from "rxjs";
import { io } from "socket.io-client";

const chatApiUrl = "https://chat.thelivery.com.br";
let chatSocket = io();
let isSocketConnect = false;
let chatOpened = [];

export async function getHistoryMessages(orderId) {
  const response = await axios.get(`${chatApiUrl}/message`, {
    headers: { orderId: orderId },
  });
  return response.data || [];
}

export async function getIsOnlineInfo(userid) {
  const response = await axios.get(`${chatApiUrl}/user`, {
    headers: { userid },
  });
  return response.data || false;
}

export async function getImgLink(formData) {
  const response = await axios.post(`${chatApiUrl}/midia/upload`, formData);
  return response;
}

export function startChatSocket() {
  const user = (store as any).state?.user;

  if (!user?.token) {
    return;
  }

  chatSocket = io(chatApiUrl, {
    auth: { userId: `app-${user.id}` },
  });

  isSocketConnect = true;
}

export function closeChatSocket() {
  chatSocket.disconnect();
}

export function getReadMessages() {
  const user = (store as any).state?.user;

  return new Observable((observer) => {
    chatSocket.on(`chatNotification-${user.id}`, (orderId) => {
      if (!this.chatOpened.includes(orderId)) {
        observer.next(orderId);
        observer.complete();
      }
    });
  });
}

export function getRefreshUserConnection(order) {
  return new Observable((observer) => {
    chatSocket.on(`statusConection:emp-${order.establishment.id}`, () => {
      observer.next();
    });
  });
}

export async function postReadMessages(orderId) {
  const userId = (store as any).state?.user.id;
  return await axios.post(`${chatApiUrl}/message/read`, {
    userId,
    orderId,
  });
}

export default {
  getHistoryMessages,
  getImgLink,
  closeChatSocket,
  startChatSocket,
  getIsOnlineInfo,
  getReadMessages,
  postReadMessages,
  getRefreshUserConnection,
  chatOpened,
  chatSocket,
  chatApiUrl,
};
