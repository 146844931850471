import axios from "@/utils/axios";

export default async function (neighborhood: number, establishment: number) {
  const response = await axios.get(
    `/neighborhood/${neighborhood}/establishments/${establishment}/info`
  );

  const establishmentInfoData = response.data;

  return establishmentInfoData;
}
