
import { IonContent, modalController, IonIcon } from "@ionic/vue";
import { defineComponent } from "vue";
import { close } from "ionicons/icons";

export default defineComponent({
  name: "ModalGoStore",
  components: { IonContent, IonIcon },
  props: {
    image: { type: String, default: "", required: true },
  },
  setup() {
    return {
      close,
    };
  },
  methods: {
    closeModal() {
      modalController.dismiss();
    },
  },
});
