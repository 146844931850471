<template>
  <ion-page>
    <ion-content>
      <header-with-balance title="Cupons" :has-go-back="true" />
      <div class="circular-border">
        <section v-if="highlight.length">
          <h3 class="titles">Destaques</h3>
          <section class="carrossel">
            <ion-card
              v-for="coupon in highlight"
              :key="coupon.id"
              @click="openModalCoupon(coupon.id)"
            >
              <store-logo :logo="coupon.establishment.logo" w-h="58px" />
              <p>{{ coupon.title }}</p>
            </ion-card>
          </section>
        </section>
        <section v-if="popular.length">
          <h3 class="titles">Mais populares</h3>
          <section class="carrossel">
            <ion-card
              v-for="coupon in popular"
              :key="coupon.id"
              @click="openModalCoupon(coupon.id)"
            >
              <store-logo :logo="coupon.establishment.logo" w-h="58px" />
              <p>{{ coupon.title }}</p>
            </ion-card>
          </section>
        </section>
        <ion-list v-if="coupons.length" class="list-coupons">
          <ion-item
            v-for="coupon in coupons"
            :key="coupon.id"
            class="no-padding"
          >
            <ion-card class="coupon-card" @click="openModalCoupon(coupon.id)">
              <div class="image">
                <ion-img
                  :src="`https://cdn.thelivery.com.br/coupons/${coupon.image}`"
                ></ion-img>
              </div>
              <div class="info">
                <p>{{ coupon.title }}</p>
                <span>{{ coupon.establishment.name }}</span>
              </div>
            </ion-card>
          </ion-item>
          <ion-infinite-scroll @ionInfinite="getNextCoupons($event)">
            <ion-infinite-scroll-content loading-ref-spinner="dots" />
          </ion-infinite-scroll>
        </ion-list>
        <h3 v-else-if="!loading">
          Não foi encontrado nenhum cupom disponível no momento.
        </h3>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import {
  IonImg,
  IonCard,
  IonItem,
  IonList,
  IonContent,
  IonPage,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  modalController,
} from "@ionic/vue";
import CouponInfo from "@/views/Explore/CouponInfo.vue";
import getOfflineCouponsInitialPage from "@/data/providers/offline-coupons/offline-coupons-initial-page";
import getPaginatedOfflineCoupons from "@/data/providers/offline-coupons/paginated-offline-coupons";
import { eventKeys, logEvent } from "@/services/analitics";
import StoreLogo from "@/components/visualElements/StoreLogo.vue";

export default {
  name: "CouponsPage",
  components: {
    HeaderWithBalance,
    IonImg,
    IonCard,
    IonItem,
    IonList,
    IonContent,
    IonPage,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    StoreLogo,
  },
  data() {
    return {
      loading: true,
      coupons: [],
      popular: [],
      highlight: [],
      cursor: 2,
    };
  },
  async mounted() {
    await this.getInitialPageData();
  },
  methods: {
    ionViewDidEnter() {
      logEvent(eventKeys.OPEN_OFFLINE_COUPONS);
    },
    async openModalCoupon(id) {
      const modal = await modalController.create({
        component: CouponInfo,
        componentProps: { id },
      });
      return modal.present();
    },
    async getInitialPageData() {
      this.loading = true;
      const { coupons, popular, highlight } =
        await getOfflineCouponsInitialPage();

      this.coupons = coupons;
      this.popular = popular;
      this.highlight = highlight;
      this.cursor = 2;
      this.loading = false;
    },
    async getCoupons() {
      const newCoupons = await getPaginatedOfflineCoupons(this.cursor);

      this.coupons.push(...newCoupons);

      this.cursor += 1;
    },
    async getNextCoupons(event) {
      await this.getCoupons();
      event.target.complete();
    },
  },
};
</script>

<style scoped>
.coupon-card ion-card {
  display: flex;
  flex: 1;
  border-radius: 20px;
  padding: 6px;
  box-shadow: none;
  border: 1px solid #ededed;
  margin: 5px 0;
}

.image {
  position: relative;
}

.image ion-img {
  min-height: 110px;
  min-width: 125px;
  margin: 5px 0 5px 5px;
}

.image ion-img::part(image) {
  height: 110px;
  width: 125px;
  object-fit: cover;
  border-radius: 15px 0px 0px 15px;
}

.info {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1;
}

.titles {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 14px 0;
}

.carrossel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 22px;
}

.carrossel ion-card {
  min-width: 110px;
  box-shadow: none;
  border: 1px solid #ededed;
  border-radius: 14px;
  margin: 0 5px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
}
.carrossel ion-img {
  border-radius: 100%;
  object-fit: contain;
  width: 58px;
  height: 58px;
}

.carrossel ion-img::part(image) {
  border-radius: 50%;
}
.carrossel p {
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  color: #242323;
  margin: 5px 0 0 0;
}

.list-coupons ion-card {
  box-shadow: none;
  border: 1px solid #ededed;
  width: 100%;
  margin: 4px 0;
  display: flex;
  justify-content: center;
  border-radius: 15px;
}

.list-coupons ion-img {
  object-fit: cover;
  width: 76px;
  height: 58px;
  margin: 7px;
}

.list-coupons ion-img::part(image) {
  border-radius: 10px 0px 0px 10px;
}

.list-coupons p {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.list-coupons span {
  font-size: 10px;
}
</style>