<template>
  <ion-card>
    <ion-card-content>
      <div class="header-card-skeleton">
        <ion-skeleton-text
          class="option-name-skeleton"
          animated
        ></ion-skeleton-text>
      </div>
      <ion-list>
        <ion-item class="no-padding no-ripple-effect">
          <ion-skeleton-text animated></ion-skeleton-text>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-item>
        <ion-item class="no-padding no-ripple-effect">
          <ion-skeleton-text animated></ion-skeleton-text>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-item>
        <ion-item class="no-padding no-ripple-effect">
          <ion-skeleton-text animated></ion-skeleton-text>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-item>
      </ion-list>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonSkeletonText,
  IonCardContent,
  IonList,
  IonItem,
} from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: { IonCard, IonSkeletonText, IonCardContent, IonList, IonItem },
});
</script>

<style scoped>
ion-card {
  margin: 0;
  border: 1px solid #ebebeb;
  box-shadow: none;
  border-radius: 20px;
  margin-bottom: 6px;
}
ion-card ion-card-content {
  padding-bottom: 0px;
}
ion-card .header-card-skeleton {
  display: flex;
}

ion-card .header-card-skeleton .option-name-skeleton {
  margin: 0;
  height: 16px;
  flex: 1;
  width: 80%;
}
ion-card .header-card-skeleton .option-qtd-skeleton {
  height: 12px;
  margin: 0 9px;
  align-self: flex-end;
  width: 10%;
}
ion-list ion-item {
  --border-color: #ededed;
  --border-style: solid;
  --inner-padding-end: 0px !important;
}
</style>