import { appsOutline, textOutline, star, bicycleOutline } from "ionicons/icons";

export default [
  {
    name: "Padrão",
    value: "default",
    icon: appsOutline,
  },
  {
    name: "Nome",
    value: "name",
    icon: textOutline,
  },
  {
    name: "Avaliação",
    value: "rating",
    icon: star,
  },
  {
    name: "Taxa de entrega",
    value: "delivery_fee",
    icon: bicycleOutline,
  },
];
