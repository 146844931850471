import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, withKeys as _withKeys, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1510b3a3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "inputs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_with_balance = _resolveComponent("header-with-balance")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_header_with_balance, {
            title: "Adicionar endereço",
            "has-go-back": false,
            "has-go-back-modal": true
          }),
          _createElementVNode("div", _hoisted_1, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_ion_skeleton_text, { animated: "" }),
                  _createVNode(_component_ion_skeleton_text, { animated: "" }),
                  _createVNode(_component_ion_skeleton_text, { animated: "" }),
                  _createVNode(_component_ion_skeleton_text, { animated: "" }),
                  _createVNode(_component_ion_skeleton_text, { animated: "" })
                ]))
              : (_openBlock(), _createBlock(_component_ion_grid, { key: 1 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { class: "ion-no-padding" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { position: "floating" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Bairro *")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_select, {
                                    modelValue: _ctx.neighborhood,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.neighborhood) = $event)),
                                    value: _ctx.neighborhood,
                                    "interface-options": _ctx.customNeighborhoodOptions,
                                    "cancel-text": "Cancelar",
                                    mode: "ios"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.neighborhoods, (neigh) => {
                                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                                          key: neigh.id,
                                          value: neigh.id
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(neigh.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue", "value", "interface-options"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { class: "ion-no-padding" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { position: "floating" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Rua/Avenida/Praça *")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_input, {
                                    modelValue: _ctx.street,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.street) = $event)),
                                    autocomplete: "street-address",
                                    class: "street-address",
                                    type: "text",
                                    maxlength: "255"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { class: "ion-no-padding" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { position: "floating" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Número *")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_input, {
                                    modelValue: _ctx.number,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.number) = $event)),
                                    maxlength: "20",
                                    type: "text",
                                    autocomplete: "off"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { class: "ion-no-padding" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { position: "floating" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Complemento (apt, bloco, etc)")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_input, {
                                    modelValue: _ctx.complement,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.complement) = $event)),
                                    class: "complement",
                                    type: "text",
                                    maxlength: "255",
                                    autocomplete: "off"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { class: "ion-no-padding" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { position: "floating" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Referência *")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_input, {
                                    modelValue: _ctx.reference,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.reference) = $event)),
                                    type: "text",
                                    enterkeyhint: "done",
                                    class: "reference",
                                    maxlength: "255",
                                    autocomplete: "off",
                                    onKeyup: _withKeys(_ctx.addAddressConfirm, ["enter"])
                                  }, null, 8, ["modelValue", "onKeyup"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_the_button, {
                name: "Confirmar",
                onClick: _ctx.addAddressConfirm
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}