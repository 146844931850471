
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import Categories from "@/components/explore/Categories.vue";
import {
  IonContent,
  IonPage,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
} from "@ionic/vue";
import OffersCards from "@/components/explore/OffersCards.vue";

import getAnyRoute from "@/data/providers/any-route";
import { logEvent, eventKeys } from "@/services/analitics";

export default {
  name: "AllOffersPage",
  components: {
    IonInfiniteScrollContent,
    IonInfiniteScroll,
    HeaderWithBalance,
    IonContent,
    IonPage,
    Categories,
    OffersCards,
  },
  props: {
    slug: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      offers: [],
      categories: [],
      page: 1,
    };
  },
  methods: {
    async updateCategory(item) {
      this.getOffers(1, item.id);
      this.page = 1;
    },
    async getNextOffers(event) {
      await this.getOffers(++this.page);
      event.target.complete();
    },
    async getOffers(page = 1, categoryId = undefined) {
      const queryParams = new URLSearchParams();

      this.slug && queryParams.set("storeSlug", this.slug);
      categoryId && queryParams.set("categoryId", categoryId);
      queryParams.set("page", page.toString());

      const query = queryParams.toString();

      const offers = await getAnyRoute(
        "/explore/offers" + (query && `?${query}`)
      );

      if (page == 1) {
        this.offers = offers;
        return;
      }

      this.offers.push(...offers);
    },
    async getCategories() {
      const queryParams = new URLSearchParams();

      queryParams.set("filter", "offers");

      const query = queryParams.toString();

      const categories = await getAnyRoute(
        "/explore/categories" + (query && `?${query}`)
      );

      this.categories = categories;
    },
  },
  async ionViewDidEnter() {
    this.getOffers();
    this.getCategories();
    logEvent(eventKeys.OPEN_EXTERNAL_OFFERS);
  },
};
