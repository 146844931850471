export function validEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function hiddenEmail(email) {
  if (!email) return "Invalid email";

  const emailTemp = email.split("@");
  return (
    emailTemp[0].substr(0, 3) +
    "***" +
    emailTemp[0].substr(-2, 2) +
    "@" +
    emailTemp[1]
  );
}
