
import { IonIcon } from "@ionic/vue";
import { star } from "ionicons/icons";
export default {
  name: "RatingBadge",
  components: { IonIcon },
  props: {
    rating: {
      type: Number,
      default: undefined,
    },
  },
  setup() {
    return {
      star,
    };
  },
  methods: {
    formatRating(rating) {
      const format = rating.toFixed(1).replace(".", ",");
      return format;
    },
  },
};
