
import StoreExternalLogo from "@/components/visualElements/StoreExternalLogo.vue";
import { IonBadge, IonCard, IonIcon } from "@ionic/vue";

import { caretForward } from "ionicons/icons";

export default {
  name: "AllStoresPage",
  components: {
    IonBadge,
    IonCard,
    IonIcon,
    StoreExternalLogo,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      caretForward,
    };
  },
  methods: {
    async goToLink(link) {
      this.$router.push(link);
    },
  },
};
