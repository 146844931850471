export function dateToBr(date) {
  const jsDate = new Date(date);

  return jsDate.toLocaleDateString("pt-BR");
}

export function dateToUS(date) {
  if (!date) return "Invalid Date";

  const [day, month, year] = date.split("/");
  return new Date(year, parseInt(month) - 1, day);
}

export function dateToBrWithHour(stringDate) {
  const date = new Date(stringDate);
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleDateString("pt-BR", options);
}

export function extractTime(date) {
  return new Date(date).toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  });
}
