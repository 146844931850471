import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0d07cb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_header = _resolveComponent("simple-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _directive_mask = _resolveDirective("mask")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_simple_header, {
            title: "Junte-se a nós",
            "header-img": "assets/registerHeader.webp",
            "has-go-back-modal": $props.isModal,
            "has-go-back": !$props.isModal
          }, null, 8, ["has-go-back-modal", "has-go-back"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_grid, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { class: "ion-no-padding" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { position: "floating" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Telefone")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_input, {
                              modelValue: _ctx.phone,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event)),
                              type: "tel",
                              autocomplete: "tel-national"
                            }, null, 8, ["modelValue"]),
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phone) = $event)),
                              style: {"display":"none"}
                            }, null, 512), [
                              [_vModelText, _ctx.phone],
                              [_directive_mask, '(##) #####-####']
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { class: "ion-no-padding" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { position: "floating" }, {
                              default: _withCtx(() => [
                                _createTextVNode("Senha")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_input, {
                              modelValue: _ctx.password,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                              type: "password",
                              enterkeyhint: "send",
                              onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => ($options.goConfirmLogin()), ["enter"]))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          shape: "round",
                          color: "primary",
                          expand: "full",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => ($options.goConfirmLogin()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(" Entrar ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}