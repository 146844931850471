import axios from "@/utils/axios";

export default async function (neighborhood: number, establishmentId: number) {
  const response = await axios.post(
    `/neighborhood/${neighborhood}/establishments/${establishmentId}/like`
  );

  const establishmentLikeData = response.data;

  return establishmentLikeData;
}
