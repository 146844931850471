import axios from "@/utils/axios";

export default async function (params: {
  neighborhoodId;
  searchValue;
  sortBy;
  filters;
  payments;
  categories;
  queryBy?;
  page?;
}) {
  const response = await axios.post(
    `neighborhood/${params.neighborhoodId}/search${
      params.page ? "?page=" + params.page : ""
    }`,

    {
      searchValue: params.searchValue,
      sortBy: params.sortBy,
      filters: params.filters,
      payments: params.payments,
      categories: params.categories,
      queryBy: params.queryBy,
    }
  );

  const searchPost = response.data;

  return searchPost;
}
