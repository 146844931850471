import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d16860e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "name" }
const _hoisted_4 = {
  key: 0,
  class: "value value-debit"
}
const _hoisted_5 = {
  key: 1,
  class: "value value-credit"
}
const _hoisted_6 = { class: "col-right" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_img, {
          src: 
          $props.transaction.logo
            ? `https://cdn.thelivery.com.br/img/clients/` + $props.transaction.logo
            : `/assets/thelivery_logo.jpg`
        
        }, null, 8, ["src"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString($props.transaction.type == "debit" ? "Compra em" : "Cashback de "), 1),
          _createTextVNode(" " + _toDisplayString($props.transaction.establishment || "TheLivery"), 1)
        ]),
        ($props.transaction.type === $data.debit)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, " - " + _toDisplayString($setup.moneyMask($props.transaction.value)), 1))
          : _createCommentVNode("", true),
        ($props.transaction.type === $data.credit)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, " + " + _toDisplayString($setup.moneyMask($props.transaction.value)), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        ($props.transaction.status == 'approved')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_ion_badge, {
                class: _normalizeClass($props.transaction.type)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.transaction.type == "credit" ? "Recebido" : "Gasto"), 1)
                ]),
                _: 1
              }, 8, ["class"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_ion_badge, { class: "pending" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.transaction.status == "pending" ? "Pendente" : "Cancelado"), 1)
                ]),
                _: 1
              })
            ])),
        _createElementVNode("p", null, _toDisplayString($setup.dateToBr($props.transaction.date)), 1)
      ])
    ]),
    _: 1
  }))
}