import axios from "@/utils/axios";

export default async function (establishmentId: number, page: number) {
  const response = await axios.get(
    `/establishments/${establishmentId}/ratings${page ? "?page=" + page : ""}`
  );

  const ratingsData = response.data;

  return ratingsData;
}
