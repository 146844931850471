const neighborhoodsModule = {
  state: {
    id: undefined,
    name: "Escolha sua localização",
    cityId: undefined,
    citySlug: undefined,
    cityName: undefined,
  },
  mutations: {
    setNeighborhood(state: any, value: any) {
      state.id = value.id;
      state.name = value.name;
      state.cityId = value.cityId;
      state.citySlug = value.citySlug;
      state.cityName = value.cityName;
    },
  },
  actions: {
    changeNeighborhood({ commit }: any, neighborhood: any) {
      commit("setNeighborhood", neighborhood);
    },
  },
};

export default neighborhoodsModule;
