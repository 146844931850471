const paymentModule = {
  state: {
    payment: {},
  },
  mutations: {
    initPayment(state: any, value: any) {
      if (!state.payment.paymentMethod && value.isOnlinePayment) {
        return;
      } else {
        state.payment = {};
      }
    },
    addPayment(state: any, value: any) {
      state.payment = value;
    },
  },
  actions: {
    initPayment({ commit }: any, payment: string) {
      commit("initPayment", payment);
    },
    addPayment({ commit }: any, payment: string) {
      commit("addPayment", payment);
    },
  },
};

export default paymentModule;
