import axios from "@/utils/axios";

export default async function ({
  email,
  name,
  phone,
  alternativePhone,
  gender,
  birthDate,
}) {
  const response = await axios.post(`user/edit`, {
    email,
    name,
    phone,
    alternative_phone: alternativePhone,
    gender,
    birthDate,
  });

  const editInfo = response.data;

  return editInfo;
}
