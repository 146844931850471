
import {
  IonButton,
  IonInput,
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  toastController,
  loadingController,
  modalController,
  IonLabel,
  IonItem,
} from "@ionic/vue";
import {} from "ionicons/icons";
import SimpleHeader from "@/components/headers/SimpleHeader.vue";
import loginWithPhone from "@/services/loginWithPhone";

import sendLoginMail from "@/services/sendLoginMail";

import ChooseEmail from "../Login/ChooseEmail.vue";
import RegisterVue from "../Register.vue";
import ConfirmLoginVue from "./ConfirmLogin.vue";
import { mask } from "vue-the-mask";

export default {
  name: "LoginWithPhone",
  directives: { mask },
  components: {
    SimpleHeader,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonLabel,
    IonItem,
  },
  props: {
    isModal: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const goConfirmLogin = async (email) => {
      const modal = await modalController.create({
        component: ConfirmLoginVue as any,
        componentProps: { email, isModal: true },
        cssClass: "custom-modal-full-transparent",
      });

      modal.onDidDismiss().then((response) => {
        if (response?.data?.login) {
          modalController.dismiss({ login: true });
        }
      });

      modal.present();
    };
    return { goConfirmLogin };
  },
  data: () => ({
    phone: "",
  }),
  methods: {
    createToast(message) {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    },
    async verifyLogin() {
      if (this.phone == "") {
        this.createToast("Todos os campos devem ser preenchidos!");
        return;
      }

      this.goLogin(this.phone);

      await this.showLoader();
    },
    async goLogin(phone) {
      try {
        const response = await loginWithPhone(phone.replace(/\D/g, ""));

        loadingController.dismiss();

        if (response.length > 1) {
          this.openModal(response);
        } else if (!response[0].email) {
          this.createToast(
            "Esse número de telefone não possui um email. Crie uma nova conta!"
          );

          const modal = await modalController.create({
            component: RegisterVue as any,
            componentProps: { isModal: true },
            cssClass: "custom-modal-full-transparent",
          });

          modal.present();
        } else {
          this.sendCode(response[0].email).then((res) => {
            res.success
              ? this.goConfirmLogin(response[0].email)
              : this.openEmailNotFoundToast();
          });
        }
      } catch (error) {
        loadingController.dismiss();
        if (error.response?.data) {
          this.createToast(error.response?.data?.message);
        }
      }
    },

    async sendCode(email: string) {
      this.showLoader();
      try {
        const response = await sendLoginMail(email);
        loadingController.dismiss();
        return response;
      } catch (error) {
        loadingController.dismiss();
      }
      loadingController.dismiss();
    },

    async openModal(user) {
      const modal = await modalController.create({
        component: ChooseEmail,
        componentProps: { user },
      });

      modal.onDidDismiss().then((data) => {
        this.sendCode(data.data).then((res) => {
          res.success
            ? this.goConfirmLogin(data.data)
            : this.openEmailNotFoundToast();
        });
      });
      return modal.present();
    },

    async showLoader() {
      const loader = await loadingController.create({
        message: "Acessando sua conta...",
      });
      loader.present();
    },
  },
};
