import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-782b6dc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ratings" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(11, (n) => {
      return _createElementVNode("div", {
        key: n - 1,
        class: _normalizeClass(n - 1 === $data.limit ? 'button selected' : 'button'),
        onClick: ($event: any) => ($options.changeRatings(n - 1))
      }, [
        _createElementVNode("span", null, _toDisplayString(n - 1), 1)
      ], 10, _hoisted_2)
    }), 64))
  ]))
}