import axios from "@/utils/axios";

export default async function (id, reference?) {
  const response = await axios.post(`/raffles/${id}`, {
    reference,
  });

  const info = response.data;

  return info;
}
