import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_skeleton_text, {
            animated: "",
            class: "title-skeleton"
          }),
          _createVNode(_component_ion_skeleton_text, { animated: "" }),
          _createVNode(_component_ion_skeleton_text, { animated: "" }),
          _createVNode(_component_ion_skeleton_text, { animated: "" }),
          _createVNode(_component_ion_skeleton_text, {
            animated: "",
            class: "button-skeleton"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}