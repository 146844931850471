
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { useBackButton, useIonRouter } from "@ionic/vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  setup() {
    const ionRouter = useIonRouter();
    const router = useRouter();
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        router.push("/tabs/inicio");
      }
    });
  },
});
