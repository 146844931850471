import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_skeleton_text, {
      class: "banner",
      animated: ""
    }),
    _createVNode(_component_ion_skeleton_text, {
      class: "howEntrant",
      animated: ""
    }),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
      return _createVNode(_component_ion_grid, {
        key: i.id
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_skeleton_text, {
                class: "title",
                animated: ""
              }),
              _createVNode(_component_ion_skeleton_text, {
                class: "description",
                animated: ""
              })
            ]),
            _: 1
          })
        ]),
        _: 2
      }, 1024)
    }), 64))
  ], 64))
}