
import {
  IonInput,
  IonPage,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonFooter,
  IonToolbar,
  toastController,
  IonItem,
  IonLabel,
  IonSkeletonText,
  alertController,
} from "@ionic/vue";
import {} from "ionicons/icons";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import TheButton from "@/components/visualElements/TheButton.vue";
import { useRouter } from "vue-router";
import ShowProfile from "@/data/providers/user/user-info";
import EditProfile from "@/data/providers/user/edit-info";
import typesModule from "@/data/modules/typesModule";
import { mask } from "vue-the-mask";
import { dateToUS, dateToBr } from "@/utils/functions/date";
import { validEmail } from "@/utils/functions/email";
import deleteAccount from "@/data/providers/user/deleteAccount";

export default {
  name: "EditProfilePage",
  directives: { mask },
  components: {
    HeaderWithBalance,
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonCol,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonFooter,
    IonToolbar,
    IonItem,
    IonLabel,
    IonSkeletonText,
    TheButton,
  },
  setup() {
    const router = useRouter();
    const goBack = () => {
      if (history?.state?.back) {
        router.back();
      } else {
        router.push("/tabs/inicio");
      }
    };

    return {
      goBack,
      dateToUS,
      dateToBr,
      validEmail,
    };
  },
  data: () => ({
    name: "",
    phone: "",
    phoneAlternative: "",
    email: "",
    birth: "",
    gender: "",
    loading: true,
  }),

  async mounted() {
    this.loading = true;

    const data = await ShowProfile();
    this.name = data.name;
    this.phone = data.phone;
    this.phoneAlternative = data.alternative_phone;
    this.email = data.email;
    this.birth = data.birth_date ? this.dateToBr(data.birth_date) : "";
    this.gender = data.gender;

    this.loading = false;
  },

  methods: {
    createToast(message) {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    },

    openDataNotFoundToast() {
      this.createToast("Todos os campos devem ser preenchidos!");
    },

    openInvalidEmailToast() {
      this.createToast("Informe um email válido");
    },

    alertName() {
      this.createToast(
        "Ops! Não é possível alterar o nome. Qualquer dúvida, fale conosco."
      );
    },
    async deleteAccount() {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Excluir conta",
        message:
          "Todos os dados serão apagados permanentemente. Tem certeza que deseja disso?",
        buttons: [
          {
            text: "Sim",
            handler: async () => {
              this.loading = true;
              this.$router.back();
              await deleteAccount();
              await this.$store.dispatch(typesModule.actions.LOGOFF);
              this.loading = false;
            },
          },
          {
            text: "Não",
            role: "calcel",
          },
        ],
      });
      await alert.present();
    },
    async editProfile() {
      if (
        this.name == "" ||
        this.phone == "" ||
        this.email == "" ||
        this.birth == "" ||
        this.gender == ""
      ) {
        this.openDataNotFoundToast();
      } else if (!this.validEmail(this.email)) {
        this.openInvalidEmailToast();
      } else {
        try {
          const res = await EditProfile({
            name: this.name.replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            ),
            phone: this.phone.replace(/\D/g, ""),
            alternativePhone: this.phoneAlternative?.replace(/\D/g, ""),
            email: this.email,
            birthDate: this.dateToUS(this.birth),
            gender: this.gender,
          });

          this.createToast("Perfil editado com sucesso!");
          this.$store.dispatch(typesModule.actions.UPDATE_USER, res);
          this.$router.back();
        } catch (error) {
          this.createToast(
            error.response?.data?.message ||
              "Ops! Por favor, entre em contato com nossa equipe."
          );
        }
      }
    },
  },
};
