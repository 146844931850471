import axios from "@/utils/axios";

export default async function uploadAvatar(blobAvatar: Blob) {
  const form = new FormData();

  form.append("image", blobAvatar);

  const response = await axios.post(`/user/avatar`, form, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  const info = response.data;

  return info;
}
