import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3522237b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    "slides-per-view": "1",
    modules: $setup.modules,
    pagination: {
      dynamicBullets: true,
    }
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.images, (image, i) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, {
          key: i,
          zoom: true,
          onClick: ($event: any) => ($options.openImage(image))
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "slide-content",
              src: image
            }, null, 8, _hoisted_1)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modules"]))
}