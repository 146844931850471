import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_with_balance = _resolveComponent("header-with-balance")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "ion-padding" }, {
    default: _withCtx(() => [
      _createVNode(_component_header_with_balance, {
        title: _ctx.title,
        "has-go-back": !_ctx.isModal,
        "has-go-back-modal": _ctx.isModal
      }, null, 8, ["title", "has-go-back", "has-go-back-modal"]),
      _createElementVNode("div", {
        class: "circular-border",
        innerHTML: _ctx.content
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}