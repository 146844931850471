
export default {
  name: "CouponBadge",
  props: {
    text: {
      type: String,
      default: undefined,
    },
    color: {
      type: String,
      default: "#35f5ef5e",
    },
  },
};
