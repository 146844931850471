
import { IonCard, IonImg, IonCardContent, IonIcon } from "@ionic/vue";
import { close } from "ionicons/icons";
export default {
  name: "CreditCardCard",
  components: { IonCard, IonImg, IonCardContent, IonIcon },
  props: {
    card: {
      type: Object,
      default: undefined,
    },
    hasDelete: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["deleteCard"],
  setup() {
    return { close };
  },
  methods: {
    deleteCard() {
      this.$emit("deleteCard");
    },
  },
};
