import axios from "@/utils/axios";

export default async function (establishmentId: any) {
  const response = await axios.get(
    `/establishments/${establishmentId}/addresses`
  );

  const address = response.data;

  return address;
}
