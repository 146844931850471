import axios from "@/utils/axios";

export default async function (neighborhood: number) {
  const response = await axios.get(
    `/neighborhood/${neighborhood}/initial-page`
  );

  const establishmentData = response.data;

  return establishmentData;
}
