
import { modalController } from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import ModalImage from "@/components/modals/ModalImage.vue";
import { Zoom, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/zoom";
import { slugify } from "@/utils/functions/string";
export default {
  name: "ImagesCarousel",
  components: { Swiper, SwiperSlide },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return {
      slugify,
      modules: [Zoom, Pagination],
    };
  },

  methods: {
    async openImage(image) {
      const modal = await modalController.create({
        component: ModalImage,
        componentProps: {
          image,
        },
      });
      modal.present();
    },
  },
};
