import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60825130"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar-container skeleton" }
const _hoisted_2 = { class: "avatar-image-container" }
const _hoisted_3 = { class: "info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_skeleton_text, {
                animated: "",
                style: {"border-radius":"100%"}
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              class: "name"
            }),
            _createVNode(_component_ion_skeleton_text, {
              animated: "",
              class: "category"
            }),
            _createVNode(_component_ion_skeleton_text, { animated: "" }),
            _createVNode(_component_ion_skeleton_text, { animated: "" })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}