import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44f654d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "skeleton"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "card__content-custom-modal" }
const _hoisted_4 = {
  key: 0,
  class: "rating-container-modal"
}
const _hoisted_5 = {
  key: 1,
  class: "rating-container-modal"
}
const _hoisted_6 = {
  key: 2,
  class: "comment-container-modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_header = _resolveComponent("basic-header")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_rating_stars = _resolveComponent("rating-stars")!
  const _component_rating_to_10 = _resolveComponent("rating-to-10")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_basic_header, { title: "Avalie o Pedido" }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_ctx.ratingsConfig.length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                return _createVNode(_component_ion_card, { key: i }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_content, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_skeleton_text, { animated: "" }),
                        _createVNode(_component_ion_skeleton_text, { animated: "" }),
                        _createVNode(_component_ion_skeleton_text, { animated: "" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1024)
              }), 64))
            ]))
          : (_openBlock(), _createElementBlock("main", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratingsConfig, (rating, index) => {
                return (_openBlock(), _createBlock(_component_ion_card, {
                  key: rating.field
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_content, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createElementVNode("span", null, _toDisplayString(rating.text), 1)
                        ]),
                        (rating.type == 'star')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              _createVNode(_component_rating_stars, {
                                rating: rating.value,
                                onSaveValueLimit: ($event: any) => (_ctx.updateRating($event, index))
                              }, null, 8, ["rating", "onSaveValueLimit"])
                            ]))
                          : _createCommentVNode("", true),
                        (rating.type == '0-10')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createVNode(_component_rating_to_10, {
                                rating: rating.value,
                                onSaveValueLimit: ($event: any) => (_ctx.updateRating($event, index))
                              }, null, 8, ["rating", "onSaveValueLimit"])
                            ]))
                          : _createCommentVNode("", true),
                        (rating.type == 'input')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              _createVNode(_component_ion_textarea, {
                                modelValue: rating.value,
                                "onUpdate:modelValue": ($event: any) => ((rating.value) = $event),
                                placeholder: rating.placeholder,
                                class: "content__input-custom-modal"
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]))
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_the_button, {
              name: "Salvar Avaliação",
              onClick: _ctx.sendRate
            }, null, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}