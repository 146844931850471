
import { IonIcon, IonImg } from "@ionic/vue";
import { card } from "ionicons/icons";
export default {
  name: "DeliveryPayment",
  components: { IonIcon, IonImg },
  props: {
    onlinePayment: {
      type: Boolean,
      default: undefined,
    },
    creditCard: {
      type: Boolean,
      default: undefined,
    },
    pix: {
      type: Boolean,
      default: undefined,
    },
  },
  setup() {
    return {
      card,
    };
  },
};
