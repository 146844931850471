import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23c1fbc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-custom-modal" }
const _hoisted_2 = { class: "card-custom-modal" }
const _hoisted_3 = { class: "title-custom-modal" }
const _hoisted_4 = { class: "subtitle-custom-modal" }
const _hoisted_5 = { class: "card__content-custom-modal" }
const _hoisted_6 = { class: "buttons-container-custom-modal" }
const _hoisted_7 = { class: "bottom-button-custom-modal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, { class: "custom-modal-transparent" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.subtitle), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ion_input, {
              modelValue: _ctx.moneyValue,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.moneyValue) = $event)),
              placeholder: _ctx.inputs[0].placeholder,
              class: "content__input-custom-modal",
              enterkeyhint: "done",
              autocomplete: "off",
              onKeyup: _withKeys(_ctx.confirmMoney, ["enter"])
            }, null, 8, ["modelValue", "placeholder", "onKeyup"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ion_button, {
                color: "dark",
                fill: "clear",
                onClick: _ctx.closeModal
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Cancelar")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_ion_button, {
                fill: "clear",
                onClick: _ctx.confirmMoney
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Confirmar")
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}