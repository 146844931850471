import axios from "@/utils/axios";

export default async function (phone: string) {
  const response = await axios.post(`/user/find-by-phone`, {
    phone,
    info: navigator.userAgent.toString(),
  });

  const data = response.data;

  return data;
}
