
import {
  IonButton,
  IonInput,
  IonFooter,
  IonToolbar,
  IonPage,
  IonContent,
  toastController,
  loadingController,
  modalController,
} from "@ionic/vue";
import { keyOutline, callOutline, mail, arrowBack } from "ionicons/icons";
import sendLoginMail from "@/services/sendLoginMail";
import loginWithCode from "@/services/loginWithCode";
import { useStore } from "vuex";
import typesModule from "@/data/modules/typesModule";
import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import TheButton from "@/components/visualElements/TheButton.vue";
import { hiddenEmail } from "@/utils/functions/email";

export default {
  name: "ConfirmLogin",
  components: {
    IonButton,
    IonInput,
    IonPage,
    IonContent,
    IonFooter,
    IonToolbar,
    HeaderWithBalance,
    TheButton,
  },
  props: {
    isModal: {
      default: false,
      type: Boolean,
    },
    email: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore();

    const goBack = () => {
      if (props.isModal) {
        modalController.dismiss();
        return;
      }
      modalController.dismiss();
    };

    const createToast = (message) => {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    };

    const openEmailSentToast = () => {
      createToast("Email reenviado");
    };

    const openEmailErrorToast = () => {
      createToast("Ocorreu um erro ao reenviar o email");
    };

    const validateEmailCode = async (code) => {
      try {
        const response = await loginWithCode(props.email, code);
        loadingController.dismiss();

        if (response.token) {
          store.dispatch(typesModule.actions.SET_USER, response);
          createToast("Email validado com sucesso");
          modalController.dismiss({ login: true });
        } else {
          loadingController.dismiss();
          createToast("Ocorreu um erro ao validar o email");
        }
      } catch (error) {
        loadingController.dismiss();

        createToast("Código inválido. Tente novamente");
      }
    };

    return {
      keyOutline,
      callOutline,
      mail,
      arrowBack,
      goBack,
      openEmailSentToast,
      openEmailErrorToast,
      validateEmailCode,
      createToast,
      hiddenEmail,
    };
  },
  data: () => ({
    code: "",
    counter: 60,
  }),
  computed: {
    buttonName() {
      return this.counter <= 0
        ? "Reenviar o código"
        : `Aguarde ${this.counter}s`;
    },
  },
  watch: {
    counter: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.counter--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async validateCode() {
      const code = this.code;
      if (code.length < 5) {
        this.createToast("Preencha todos os campos!");
        return;
      }
      await this.showLoader();
      this.validateEmailCode(code);
    },
    async showLoader() {
      const loader = await loadingController.create({
        message: "Confirmando login...",
      });
      loader.present();
    },
    async sendCode() {
      this.showLoader();
      try {
        const response = await sendLoginMail(this.$props.email);
        loadingController.dismiss();
        response.success ? this.resentSuccess() : this.openEmailErrorToast();

        return response;
      } catch (error) {
        loadingController.dismiss();
        this.openEmailErrorToast();
      }
    },
    resentSuccess() {
      this.counter = 60;
      this.openEmailSentToast();
    },
  },
};
