
import { arrowBack } from "ionicons/icons";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonThumbnail,
  IonImg,
  modalController,
} from "@ionic/vue";
import ModalGoCouponStore from "@/components/modals/ModalGoCouponStore.vue";
import getStoreCoupons from "@/data/providers/explore/lomadee/coupons-by-store";
import { Ref, ref, onMounted } from "vue";
import { eventKeys, logEvent } from "@/services/analitics";
import RaffleDetailSkeleton from "@/components/skeletonComponents/RaffleDetailsSkeleton.vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";

export default {
  name: "CouponsStorePage",
  components: {
    IonPage,
    IonContent,
    IonThumbnail,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    RaffleDetailSkeleton,
    BasicHeader,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    store: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const loadingRef = ref(true);
    const couponsRef: Ref = ref([]);

    const getCoupons = async () => {
      loadingRef.value = true;

      const listStores = await getStoreCoupons(props.id);

      couponsRef.value = listStores;

      loadingRef.value = false;
    };

    onMounted(getCoupons);

    return {
      couponsRef,
      loadingRef,
      arrowBack,
    };
  },
  methods: {
    ionViewDidEnter() {
      logEvent(eventKeys.OPEN_LOMADEE);
    },
    goBack() {
      if (history?.state?.back) {
        this.$router.back();
      } else {
        this.$router.push("/tabs/inicio");
      }
    },
    async openModal(coupon) {
      const modal = await modalController.create({
        component: ModalGoCouponStore,
        cssClass: "modal-confirm-order",
        componentProps: {
          store: {
            ...coupon.store,
            logo: coupon.store.image,
            link: coupon.link,
          },
          coupon: coupon.code,
        },
      });

      await modal.present();
    },
  },
};
