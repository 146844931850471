import axios from "@/utils/axios";

export default async function (user) {
  const response = await axios.post(`/auth/apple`, {
    ...user,
    info: navigator.userAgent.toString(),
  });

  const data = response.data;

  return data;
}
