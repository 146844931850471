import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-88252e1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet),
          _createElementVNode("section", _hoisted_1, [
            _createVNode(_component_ion_tab_bar, { slot: "bottom" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_tab_button, {
                  tab: "inicio",
                  href: "/tabs/inicio"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: $setup.homeOutline }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Início")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_tab_button, {
                  tab: "search",
                  href: "/tabs/search"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: $setup.search }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Buscar")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_tab_button, {
                  tab: "promotions",
                  href: "/tabs/explore"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      class: "central-icon",
                      src: "/theliveryLogo.svg"
                    }),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Shopping")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_tab_button, {
                  tab: "Orders",
                  href: "/tabs/Orders"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: $setup.list }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Pedidos")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_tab_button, {
                  tab: "perfil",
                  href: "/tabs/perfil"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: $setup.personOutline }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Perfil")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}