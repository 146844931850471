
import {
  IonPage,
  IonContent,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";

import EstablishmentCard from "@/components/cards/EstablishmentCard.vue";
import EstablishmentCardSkeleton from "@/components/skeletonComponents/EstablishmentCardSkeleton.vue";

import HeaderWithBalance from "@/components/headers/HeaderWithBalance.vue";
import EstablishmentsBannerList from "@/data/providers/establishments-banner-list";

import { slugify } from "@/utils/functions/string";

import { Ref, ref, onMounted, watch, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "BannersStoresPage",
  components: {
    EstablishmentCard,
    EstablishmentCardSkeleton,
    IonContent,
    IonPage,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonGrid,
    IonRow,
    IonCol,
    HeaderWithBalance,
  },
  props: {
    idBanner: {
      type: String,
      default: undefined,
    },
    banner: {
      type: String,
      default: undefined,
    },
  },
  setup(props: any) {
    const router = useRouter();
    const store = useStore();

    const establishmentsRef: Ref = ref([]);
    const loadingRef = ref(true);
    const cursorRef = ref(1);

    const neighborhoodState = store.state.neighborhood;

    const user = store.state.user;

    const getNextEstablishments = async (event) => {
      const newEstablishments = await EstablishmentsBannerList(
        neighborhoodState.id,
        props.idBanner,
        cursorRef.value
      );

      establishmentsRef.value.push(...newEstablishments);

      cursorRef.value += 1;
      event.target.complete();
    };

    const getEstablishments = async () => {
      loadingRef.value = true;
      const listEstablishments = await EstablishmentsBannerList(
        neighborhoodState.id,
        props.idBanner
      );

      establishmentsRef.value = listEstablishments;

      cursorRef.value = 2;
      loadingRef.value = false;
    };

    const goToEstablishment = (slug, categorySlug) => {
      const citySlug = store.state.neighborhood.citySlug;

      router.push(`/loja/${citySlug || "categoria"}/${categorySlug}/${slug}`);
    };

    const doRefresh = async (event) => {
      await getEstablishments();
      event.target.complete();
    };

    onMounted(getEstablishments);

    watch(neighborhoodState, () => getEstablishments());

    return {
      loadingRef,
      establishmentsRef,
      cursorRef,
      user,
      doRefresh,
      neighborhoodState,
      goToEstablishment,
      getNextEstablishments,
      slugify,
    };
  },
});
