import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48006836"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_header = _resolveComponent("basic-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_items_search_by = _resolveComponent("items-search-by")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_basic_header, { title: "Busca" }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_segment, {
              modelValue: _ctx.segmentModel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.segmentModel) = $event)),
              value: "lojas",
              scrollable: "true",
              mode: "ios",
              onIonChange: _cache[1] || (_cache[1] = ($event: any) => ($options.segmentChanged($event)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_segment_button, {
                  class: "segment-button",
                  value: "lojas"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Lojas")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_segment_button, { value: "itens" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Itens")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            (_ctx.segmentModel === 'lojas')
              ? (_openBlock(), _createBlock(_component_items_search_by, {
                  key: _ctx.renderComponentStore,
                  "list-store": true
                }))
              : _createCommentVNode("", true),
            (_ctx.segmentModel === 'itens')
              ? (_openBlock(), _createBlock(_component_items_search_by, {
                  key: 5,
                  "list-store": false
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}