<template>
  <ion-page>
    <ion-content class="page">
      <simple-header
        title="Cadastre sua empresa"
        header-img="assets/registerHeader.webp"
        :has-go-back-modal="false"
        :has-go-back="true"
      />
      <div class="circular-border">
        <h3 class="title">Vantagens para você e o seu negócio!</h3>

        <ion-grid class="benefits">
          <ion-row>
            <ion-col>
              <ion-card>
                <span>Simplicidade</span>
                <p>Receba o pedido completo na tela do seu computador.</p>
              </ion-card>
            </ion-col>
            <ion-col>
              <ion-card>
                <span>Mais clientes</span>
                <p>Aumento das vendas e conquista de novos clientes.</p>
              </ion-card>
            </ion-col>
            <ion-col>
              <ion-card>
                <span>Junte-se a outras empresas</span>
                <p>Não fique de fora, use a inovação a seu favor.</p>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
        <h3 class="title">
          Realize seu cadastro e comece a aumentar suas vendas!
        </h3>
        <ion-card>
          <ion-grid>
            <ion-row>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-label position="floating">Seu nome</ion-label>
                  <ion-input
                    v-model="name"
                    class="capitalize"
                    type="text"
                    autocomplete="name"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-label position="floating">Nome do seu negócio</ion-label>
                  <ion-input
                    v-model="establishmentName"
                    class="capitalize"
                    type="text"
                    autocomplete="name"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-label position="floating">CNPJ</ion-label>
                  <ion-input v-model="cnpj" type="tel"></ion-input>
                  <input
                    v-model="cnpj"
                    v-mask="'##.###.###/####-##'"
                    style="display: none"
                  />
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-label position="floating"
                    >Celular para contato</ion-label
                  >
                  <ion-input
                    v-model="phone"
                    type="tel"
                    autocomplete="tel-national"
                  ></ion-input>
                  <input
                    v-model="phone"
                    v-mask="'(##) #####-####'"
                    style="display: none"
                  />
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-label position="floating">E-mail para contato</ion-label>
                  <ion-input
                    v-model="email"
                    type="email"
                    autocomplete="email"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-label position="floating">Endereço completo</ion-label>
                  <ion-input
                    v-model="address"
                    type="text"
                    autocomplete="address-line1"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-label position="floating">Estado</ion-label>
                  <ion-select
                    v-model="stateId"
                    ok-text="OK"
                    cancel-text="Cancelar"
                  >
                    <ion-select-option value="1">Bahia</ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-item class="ion-no-padding">
                  <ion-label position="floating">Cidade</ion-label>
                  <ion-select
                    v-model="cityId"
                    ok-text="OK"
                    cancel-text="Cancelar"
                  >
                    <ion-select-option
                      v-for="city in cities"
                      :key="city.id"
                      :value="city.id"
                      >{{ city.name }}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col class="terms">
                Ao se registrar, você concorda com os
                <a @click="openTermsAndConditionsModal">termos e condições</a>
                do serviço.
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-button
                  shape="round"
                  color="primary"
                  expand="full"
                  @click="goRegister"
                >
                  Cadastrar
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonCard,
  IonInput,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonItem,
  toastController,
  modalController,
} from "@ionic/vue";
import Documents from "../views/Documents.vue";
import SimpleHeader from "@/components/headers/SimpleHeader.vue";
import { mask } from "vue-the-mask";

import getOnlineCities from "@/data/providers/cities";
import registerEstablishment from "@/data/providers/register-establishment-presale";

export default {
  name: "EstablishmentsPresale",
  directives: { mask },
  components: {
    IonPage,
    IonContent,
    IonInput,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonItem,
    SimpleHeader,
    //IonIcon,
    IonCard,
    //IonCardTitle,
    //IonCardHeader,
    //IonCardContent,
  },
  data: () => ({
    name: "",
    phone: "",
    cnpj: "",
    email: "",
    establishmentName: "",
    address: "",
    stateId: "1",
    cityId: "",
    cities: [],
  }),
  async mounted() {
    this.cities = await getOnlineCities();
  },
  methods: {
    async goRegister() {
      const establishment = {
        name: this.name,
        establishmentName: this.establishmentName,
        phone: this.phone,
        email: this.email,
        cnpj: this.cnpj,
        stateId: this.stateId,
        cityId: this.cityId,
        address: this.address,
      };

      await registerEstablishment(establishment);

      const toast = await toastController.create({
        message: "Estabelecimento cadastrado com sucesso!",
        duration: 2000,
      });
      toast.present();
    },
    async openTermsAndConditionsModal() {
      const modal = await modalController.create({
        component: Documents,
        componentProps: { document: "politica-de-privacidade", isModal: true },
      });

      modal.present();
    },
  },
};
</script>

<style scoped>
.circular-border {
  padding-top: 0;
}

.title {
  margin-top: 0px;
  align-self: center;
}

.benefits {
  margin: 20px auto;
}

.benefits ion-col {
  margin: 5px !important;
}

ion-card {
  padding: 10px;
  border: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 15px;
  align-items: center;
  text-align: center;
  box-shadow: inherit !important;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

ion-grid {
  margin: 0;
  padding-left: 0;
  gap: 10px;
}

@media only screen and (min-width: 768px) {
  ion-grid {
    max-width: 720px;
    margin: 0 auto;
  }
  ion-card {
    max-width: 720px;
    margin: 0 auto;
  }
}

ion-grid ion-row ion-col span {
  font-size: 14px;
  font-weight: 800;
}

ion-col {
  padding: 0;
}

.terms {
  margin: 15px auto;
}
</style>