
import { defineComponent } from "vue";

import { IonPage, IonContent, IonButton, toastController } from "@ionic/vue";
import StoreExternalLogo from "@/components/visualElements/StoreExternalLogo.vue";
import BasicHeader from "@/components/headers/BasicHeader.vue";
import ImagesCarousel from "@/components/explore/ImagesCarousel.vue";
import { moneyMask } from "@/utils/functions/money";

import { Browser } from "@capacitor/browser";
import { Clipboard } from "@capacitor/clipboard";

import { copy } from "ionicons/icons";

import getPageContent from "@/data/providers/page-contents";
import getAnyRoute from "@/data/providers/any-route";

export default defineComponent({
  name: "TransitionToExternalStore",
  components: {
    IonPage,
    IonContent,
    IonButton,
    StoreExternalLogo,
    BasicHeader,
    ImagesCarousel,
  },
  props: {
    store: {
      type: Object,
      default: null,
    },
    message: {
      type: String,
      default: "",
    },
    coupon: {
      type: String,
      default: "",
    },
    initialPrice: {
      type: Number,
      default: undefined,
    },
    promotionalPrice: {
      type: Number,
      default: undefined,
    },
    buttonText: {
      type: String,
      default: "Ir para a loja",
    },
    images: {
      type: Array,
      default: () => [],
    },
    storeId: {
      type: Number,
      default: undefined,
    },
    couponId: {
      type: Number,
      default: undefined,
    },
    offerId: {
      type: Number,
      default: undefined,
    },
  },
  setup() {
    return { copy, moneyMask };
  },
  data() {
    return {
      warning: null,
      about: null,
      counter: 5,
      timeoutId: undefined,
    };
  },
  async mounted() {
    const warning = await getPageContent("external-store-warning");
    this.warning = warning.content;
    const about = await getPageContent("external-store-about");
    this.about = about.content;
    getAnyRoute(this.getLogUrl()).catch(() => ({}));
  },
  unmounted() {
    clearTimeout(this.timeoutId);
  },
  methods: {
    getLogUrl() {
      if (this.storeId) {
        return `/explore/stores/${this.storeId}/details`;
      }
      if (this.couponId) {
        return `/explore/coupons/${this.couponId}/details`;
      }
      if (this.offerId) {
        return `/explore/offers/${this.offerId}/details`;
      }
    },
    getLogRedirectUrl() {
      if (this.storeId) {
        return `/explore/stores/${this.storeId}/redirection`;
      }
      if (this.couponId) {
        return `/explore/coupons/${this.couponId}/redirection`;
      }
      if (this.offerId) {
        return `/explore/offers/${this.offerId}/redirection`;
      }
    },
    async goToStore() {
      if (this.coupon) {
        await this.copyCoupon();
      }
      this.openExternal(this.store.link);
    },

    async copyCoupon() {
      await Clipboard.write({ string: this.coupon });

      const toast = await toastController.create({
        message: "Cupom copiado.",
        duration: 2000,
      });
      toast.present();
    },

    async openExternal(link) {
      const url = new URL(link);
      if (this.$store.state.user.id) {
        url.searchParams.append("mdasc", this.$store.state.user.id);
      }
      await Browser.open({ url: url.toString() });
      getAnyRoute(this.getLogRedirectUrl()).catch(() => ({}));
    },
  },
});
