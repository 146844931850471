
import { useStore } from "vuex";

import { IonCard, IonBadge, modalController } from "@ionic/vue";
import Products from "@/views/Establishments/Products.vue";
import typesModule from "@/data/modules/typesModule";

import { logEvent, eventKeys } from "@/services/analitics";
import DeliveryFee from "@/components/visualElements/DeliveryFee.vue";
import StoreLogo from "@/components/visualElements/StoreLogo.vue";
import { slugify } from "@/utils/functions/string";

export default {
  name: "PromotionProductCard",
  components: { IonCard, IonBadge, DeliveryFee, StoreLogo },
  props: {
    product: {
      type: Object,
      default: null,
    },
  },
  setup(props: any) {
    const store = useStore();
    const citySlug = store.state.neighborhood.citySlug;
    const categorySlug = slugify(props.product.establishment.category);

    const establishmentPromotionalUrl = `/loja/${
      citySlug || "categoria"
    }/${categorySlug}/${props.product.establishment.slug}`;

    const getProduct = async () => {
      const modal = await modalController.create({
        component: Products,
        componentProps: {
          productId: props.product.product_id,
          establishmentPromotionId: props.product.establishment_id,
          establishmentPromotionalUrl,
        },
      });

      store.dispatch(typesModule.actions.INIT_CART, {
        establishmentId: props.product.establishment_id,
        deliveryFee: props.product.establishment.deliveryFee.delivery_fee,
        isWithdraw: props.product.establishment.withdraw,
        isOnlinePayment: props.product.establishment.online_payment,
        isPix: props.product.establishment.pix,
        isCashbackCampaign: props.product.establishment.cashbackCampaign,
      });

      logEvent(eventKeys.OPEN_PROMOTION, {
        NOME_PROMOCAO: props.product.name,
        ID_PROMOCAO: props.product.id,
        BAIRRO_ID: store.state.neighborhood.id,
        BAIRRO_NOME: store.state.neighborhood.name,
      });

      return modal.present();
    };

    return {
      getProduct,
    };
  },
};
