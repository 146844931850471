import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Tabs from "../views/Tabs/Tabs.vue";
import Cities from "../views/Cities.vue";
import Neighborhoods from "../views/Neighborhoods.vue";
import Establishment from "../views/Establishments/Establishment.vue";
import EstablishmentInfo from "../views/Establishments/EstablishmentInfo.vue";
import Ratings from "../views/Establishments/Ratings.vue";
import LoginWithPassword from "../views/Login/LoginWithPassword.vue";
import LoginWithPhone from "../views/Login/LoginWithPhone.vue";
import ConfirmLogin from "../views/Login/ConfirmLogin.vue";
import Register from "../views/Register.vue";
import Products from "../views/Establishments/Products.vue";
import ProfileInfo from "../views/Profile/ProfileInfo.vue";
import Faq from "../views/Profile/Faq/Faq.vue";
import ActiveSessions from "../views/Profile/ActiveSessions/ActiveSessions.vue";
import Wallet from "../views/Profile/Wallet/Wallet.vue";
import AddCard from "../views/Profile/Wallet/AddCard.vue";
import Transactions from "../views/Profile/Wallet/Transactions.vue";
import Address from "../views/Profile/Address/Address.vue";
import Coupons from "@/views/Profile/Coupons/Coupons.vue";
import Favorites from "@/views/Profile/Favorites/Favorites.vue";
import Raffles from "@/views/Profile/Raffles/Raffles.vue";
import RafflesDetails from "@/views/Profile/Raffles/Details.vue";
import ManageAddress from "../views/Profile/Address/ManageAddress.vue";
import OrderDetails from "../views/Order/OrderDetails.vue";
import Filters from "../views/Search/SearchFilters.vue";
import SearchList from "../views/Search/SearchList.vue";
import SimpleSearch from "../views/Search/SimpleSearch.vue";
import Cart from "../views/Cart/Cart.vue";
import EditProfile from "../views/Profile/EditProfile.vue";
import ValidatePhone from "../views/Login/ValidatePhone.vue";
import EstablishmentsBannerPage from "../views/EstablishmentsBannerPage.vue";
import CouponsPage from "../views/Explore/CouponsPage.vue";
import ShoppingSearchList from "../views/Explore/ShoppingSearchList.vue";
import ExternalStorePage from "../views/Explore/ExternalStorePage.vue";
import ExternalOfferPage from "../views/Explore/ExternalOfferPage.vue";
import TipPage from "../views/Explore/TipPage.vue";
import AllCouponsPage from "../views/Explore/AllCouponsPage.vue";
import AllStoresPage from "../views/Explore/AllStoresPage.vue";
import AllOffersPage from "../views/Explore/AllOffersPage.vue";
import PromotionsPage from "../views/Explore/PromotionsPage.vue";
import StoresPage from "../views/Explore/Shop/StoresPage.vue";
import CouponsStoresPage from "../views/Explore/Shop/Coupons/CouponsStoresPage.vue";
import CouponsStorePage from "../views/Explore/Shop/Coupons/CouponsStorePage.vue";
import CouponsCategoriesPage from "../views/Explore/Shop/Coupons/CouponsCategoriesPage.vue";
import Home from "../views/Home.vue";
import EstablishmentsPresale from "../views/EstablishmentsPresale.vue";
import Documents from "../views/Documents.vue";
import { transformSlug } from "../utils/functions/string";

import { isPlatform } from "@ionic/vue";

const home: RouteRecordRaw = isPlatform("capacitor")
  ? {
      path: "/",
      redirect: "/tabs/inicio",
    }
  : {
      path: "/",
      component: Home,
    };

const routes: Array<RouteRecordRaw> = [
  home,
  {
    path: "/tabs/",
    component: Tabs,
    children: [
      {
        path: "",
        redirect: "/tabs/inicio",
      },
      {
        path: "inicio/ref/:ref",
        component: () => import("@/views/Tabs/InitialPage.vue"),
        beforeEnter() {
          document.title = `Delivery de comida, petshop e mercado - TheLivery`;
        },
      },
      {
        path: "inicio",
        component: () => import("@/views/Tabs/InitialPage.vue"),
        beforeEnter() {
          document.title = `Delivery de comida, petshop e mercado - TheLivery`;
        },
      },
      {
        path: "Orders",
        component: () => import("@/views/Tabs/Orders.vue"),
        beforeEnter() {
          document.title = "Pedidos em TheLivery";
        },
      },
      {
        path: "perfil",
        component: () => import("@/views/Profile/Profile.vue"),
        beforeEnter() {
          document.title = "Perfil em TheLivery";
        },
      },
      {
        path: "search",
        component: () => import("@/views/Tabs/Search.vue"),
      },
      {
        path: "explore",
        component: () => import("@/views/Tabs/Explore.vue"),
        beforeEnter() {
          document.title = `Promoções e descontos - TheLivery`;
          const meta = document.getElementsByTagName("meta");
          for (let i = 0; i < meta.length; i++) {
            if (meta[i].getAttribute("name") == "description") {
              meta[i].content =
                "As melhores promoções e descontos da cidade em um só lugar. Açai, massas, pizzas, doces, hambúrguer e muito mais. Delivery de tudo.";

              break;
            }
          }
        },
      },
    ],
  },
  {
    path: "/busca",
    name: "search-list",
    component: SearchList,
    beforeEnter() {
      document.title = "Buscar estabelecimentos e produtos - TheLivery";
    },
  },
  {
    path: "/lista-busca",
    name: "simple-search",
    component: SimpleSearch,
    beforeEnter() {
      document.title = "Buscar estabelecimentos e produtos - TheLivery";
    },
  },
  {
    path: "/filters",
    component: Filters,
  },
  {
    path: "/order-details/:orderId",
    component: OrderDetails,
    props: true,
  },
  {
    path: "/login/loginWithPassword",
    component: LoginWithPassword,
  },
  {
    path: "/login/loginWithPhone",
    component: LoginWithPhone,
  },
  {
    path: "/cadastrar",
    component: Register,
    beforeEnter() {
      document.title = "Me cadastrar - TheLivery";
    },
  },
  {
    path: "/login/confirm-login",
    name: "confirm-login",
    props: true,
    component: ConfirmLogin,
  },
  {
    path: "/validate-phone",
    name: "validate-phone",
    props: true,
    component: ValidatePhone,
  },
  {
    path: "/profile-info",
    component: ProfileInfo,
    beforeEnter() {
      document.title = "Meu Perfil - TheLivery";
    },
  },
  {
    path: "/faq",
    component: Faq,
    beforeEnter() {
      document.title = "Ajuda - TheLivery";
    },
  },
  { path: "/edit-profile", component: EditProfile },
  {
    path: "/active-sessions",
    component: ActiveSessions,
    beforeEnter() {
      document.title = "Minhas Sessões - TheLivery";
    },
  },
  {
    path: "/address",
    component: Address,
    props: true,
    beforeEnter() {
      document.title = "Meus Endereços - TheLivery";
    },
  },
  {
    path: "/favorites",
    component: Favorites,
    props: true,
    beforeEnter() {
      document.title = "Meus Favoritos - TheLivery";
    },
  },
  {
    path: "/cupons",
    component: Coupons,
    props: true,
    beforeEnter() {
      document.title = "Meus Cupons - TheLivery";
    },
  },
  {
    path: "/sorteios",
    component: Raffles,
    beforeEnter() {
      document.title = "Peça e participe de sorteios de prêmios - TheLivery";
    },
  },
  {
    path: "/sorteios/:raffleSlug",
    component: RafflesDetails,
    beforeEnter() {
      document.title = `Participe do Sorteio - TheLivery`;
    },
  },
  {
    path: "/sorteios/:raffleSlug/refer/:reference",
    component: RafflesDetails,
    beforeEnter() {
      document.title = `Participe do Sorteio - TheLivery`;
    },
  },
  {
    path: "/address/add-address",
    component: ManageAddress,
    props: true,
    beforeEnter() {
      document.title = `Cadastrar endereço - TheLivery`;
    },
  },
  {
    path: "/wallet",
    component: Wallet,
    props: true,
    beforeEnter() {
      document.title = `Minha carteira - TheLivery`;
    },
  },
  {
    path: "/wallet/transactions",
    component: Transactions,
    beforeEnter() {
      document.title = `Minhas transações de cashback - TheLivery`;
    },
  },
  {
    path: "/wallet/add-card",
    component: AddCard,
  },
  {
    path: "/cidades",
    component: Cities,
  },
  {
    path: "/cidades/:cityId/bairros",
    component: Neighborhoods,
    props: true,
  },
  {
    path: "/estabelecimento/:establishmentId",
    component: Establishment,
    props: true,
    beforeEnter() {
      document.title = `Delivery de tudo - TheLivery`;
    },
  },
  {
    path: "/loja/:citySlug/:categorySlug/:establishmentSlug",
    component: Establishment,
    props: true,
    beforeEnter(to) {
      const name = to.params.establishmentSlug.toString();
      const category = to.params.categorySlug.toString();
      const city = to.params.citySlug.toString();

      document.title = `Delivery em ${transformSlug(name)} - ${transformSlug(
        category
      )} - ${transformSlug(city)} - TheLivery`;
      const meta = document.getElementsByTagName("meta");
      for (let i = 0; i < meta.length; i++) {
        if (meta[i].getAttribute("name") == "description") {
          meta[i].content = `Peça em ${transformSlug(name)}. ${transformSlug(
            category
          )}. ${transformSlug(city)}.`;

          break;
        }
      }
    },
  },
  {
    path: "/estabelecimento/:establishmentId/info",
    component: EstablishmentInfo,
    props: true,
  },
  {
    path: "/estabelecimento/:establishmentId/avaliacoes",
    component: Ratings,
    props: true,
  },
  {
    path: "/produto/:productId",
    component: Products,
    props: true,
  },
  {
    path: "/cart",
    component: Cart,
    beforeEnter() {
      document.title = `Carrinho - TheLivery`;
    },
  },
  {
    path: "/documentos/:document",
    name: "document",
    component: Documents,
    props: true,
    beforeEnter() {
      document.title = `Documentos - TheLivery`;
    },
  },
  {
    path: "/estabelecimentos-banner/:idBanner",
    name: "estabelecimentos-banner",
    component: EstablishmentsBannerPage,
    props: true,
  },
  {
    path: "/explore/coupons",
    component: CouponsPage,
    beforeEnter() {
      document.title = `Cupons - TheLivery`;
    },
  },
  {
    path: "/explore/promotions",
    component: PromotionsPage,
    beforeEnter() {
      document.title = `Promoções - TheLivery`;
    },
  },
  {
    path: "/explore/cupons-online",
    component: CouponsCategoriesPage,
    beforeEnter() {
      document.title = `Cupons Online - TheLivery`;
    },
  },
  {
    path: "/explore/cupons-online/categorias/:id/lojas",
    component: CouponsStoresPage,
    props: true,
    beforeEnter() {
      document.title = `Cupons Online - TheLivery`;
    },
  },
  {
    path: "/explore/cupons-online/lojas/:id",
    component: CouponsStorePage,
    name: "coupons-online-store",
    props: true,
    beforeEnter() {
      document.title = `Cupons Online - TheLivery`;
    },
  },
  {
    path: "/dicas/:slug",
    component: TipPage,
    name: "tip",
    props: true,
    beforeEnter() {
      document.title = `Dicas - TheLivery`;
    },
  },
  {
    path: "/explore/stores",
    component: StoresPage,
    beforeEnter() {
      document.title = `Lojas Online - TheLivery`;
    },
  },
  {
    path: "/lojas-online",
    component: AllCouponsPage,
    beforeEnter() {
      document.title = `Cupons Online - TheLivery`;
    },
  },
  {
    path: "/lojas-online/cupons",
    component: AllCouponsPage,
    beforeEnter() {
      document.title = `Cupons Online - TheLivery`;
    },
  },
  {
    path: "/lojas-online/lojas",
    component: AllStoresPage,
    beforeEnter() {
      document.title = `Lojas Online - TheLivery`;
    },
  },
  {
    path: "/lojas-online/ofertas",
    component: AllOffersPage,
    beforeEnter() {
      document.title = `Ofertas Online - TheLivery`;
    },
  },
  {
    path: "/lojas-online/busca",
    name: "shopping-search-list",
    component: ShoppingSearchList,
    props: (route) => ({ term: route.query.q }),
    beforeEnter() {
      document.title = "Buscar estabelecimentos e produtos - TheLivery";
    },
  },
  {
    path: "/lojas-online/ofertas/:slug",
    component: ExternalOfferPage,
    props: true,
    beforeEnter() {
      document.title = `Oferta Online - TheLivery`;
    },
  },
  {
    path: "/lojas-online/:storeSlug",
    component: ExternalStorePage,
    beforeEnter() {
      document.title = `Lojas Online - TheLivery`;
    },
  },
  {
    path: "/lojas-online/:slug/cupons",
    component: AllCouponsPage,
    props: true,
    beforeEnter() {
      document.title = `Ofertas Online - TheLivery`;
    },
  },
  {
    path: "/lojas-online/:slug/ofertas",
    component: AllOffersPage,
    props: true,
    beforeEnter() {
      document.title = `Ofertas Online - TheLivery`;
    },
  },
  //TEM QUE FICAR POR ÚLTIMO
  {
    path: "/aumente-suas-vendas:param(.*)",
    component: EstablishmentsPresale,
    beforeEnter() {
      document.title = `Aumente suas vendas - TheLivery`;
    },
  },
  {
    ...home,
    path: "/:param(.*)",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
