import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-620a3642"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tag-box cashback-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.cashbackValue)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.cashbackValue) + " CASHBACK ", 1))
    : _createCommentVNode("", true)
}