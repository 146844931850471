import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e1ad0e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-message float-left" }
const _hoisted_2 = { class: "content-message float-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", null, [
        _createVNode(_component_ion_skeleton_text, {
          id: "text-item",
          animated: ""
        }),
        _createVNode(_component_ion_skeleton_text, {
          id: "hour-item",
          animated: ""
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, [
        _createVNode(_component_ion_skeleton_text, {
          id: "text-item",
          animated: ""
        }),
        _createVNode(_component_ion_skeleton_text, {
          id: "hour-item",
          animated: ""
        })
      ])
    ])
  ], 64))
}