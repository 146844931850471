import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e2017f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = {
  key: 0,
  class: "skeleton"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_with_balance = _resolveComponent("header-with-balance")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_chat_button = _resolveComponent("chat-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_header_with_balance, {
            class: "balance-header",
            title: "Ajuda",
            "has-go-back": true
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              ($setup.loadingRef)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                      return _createElementVNode("div", { key: i }, [
                        _createVNode(_component_ion_skeleton_text, {
                          animated: "",
                          class: "title"
                        }),
                        _createVNode(_component_ion_skeleton_text, { animated: "" }),
                        _createVNode(_component_ion_skeleton_text, { animated: "" }),
                        _createVNode(_component_ion_skeleton_text, { animated: "" })
                      ])
                    }), 64))
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.faqRef, (messages, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createElementVNode("h4", null, _toDisplayString(messages.title), 1),
                  _createElementVNode("span", null, _toDisplayString(messages.content), 1)
                ]))
              }), 128))
            ])
          ]),
          _createVNode(_component_chat_button)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}