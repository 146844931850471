import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d02cf44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buttons" }
const _hoisted_2 = { class: "counter-display" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_button, {
      class: "circle-left",
      onClick: $options.removeProduct,
      onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkSuboption(_ctx.suboption)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_icon, { icon: $setup.remove }, null, 8, ["icon"])
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.quantity), 1)
    ]),
    _createVNode(_component_ion_button, {
      class: "circle-right",
      onClick: $options.addProduct,
      onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkSuboption(_ctx.suboption)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_icon, { icon: $setup.add }, null, 8, ["icon"])
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}