import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6185df3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image" }
const _hoisted_2 = {
  key: 0,
  class: "new-product"
}
const _hoisted_3 = { class: "info" }
const _hoisted_4 = {
  key: 0,
  class: "description"
}
const _hoisted_5 = {
  key: 1,
  class: "minimum"
}
const _hoisted_6 = {
  key: 2,
  class: "price"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { onClick: $setup.getProduct }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ion_img, {
          src: `https://cdn.thelivery.com.br/img/products/${$props.product.image}`
        }, null, 8, ["src"]),
        ($props.product.is_new)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Novo"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString($props.product.name), 1),
        ($props.product.description)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($props.product.description), 1))
          : _createCommentVNode("", true),
        ($props.product.price)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, "A partir de"))
          : _createCommentVNode("", true),
        ($props.product.price)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($setup.moneyMask($props.product.price)), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["onClick"]))
}