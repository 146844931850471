import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d4abc64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "items" }
const _hoisted_2 = { class: "suboption" }
const _hoisted_3 = { class: "check-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_select_multiple_component = _resolveComponent("product-select-multiple-component")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.suboptions, (suboption) => {
        return (_openBlock(), _createBlock(_component_ion_item, {
          key: suboption.id,
          class: "no-padding no-ripple-effect"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("p", null, _toDisplayString(suboption.name), 1),
                _createElementVNode("span", null, _toDisplayString(suboption.description), 1)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_product_select_multiple_component, {
                  option: $props.option,
                  suboption: suboption,
                  "max-selected": $props.maxSelected,
                  "checked-props": _ctx.checked,
                  "already-selected": suboption.alreadySelected,
                  onIsCheckboxActive: ($event: any) => ($options.checkSuboption(suboption, $event))
                }, null, 8, ["option", "suboption", "max-selected", "checked-props", "already-selected", "onIsCheckboxActive"]),
                _createElementVNode("span", null, _toDisplayString(suboption.price > 0 ? $setup.moneyMask(suboption.price) : ""), 1)
              ])
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}