import axios from "@/utils/axios";

export default async function (page?) {
  const response = await axios.get(
    `/offline-coupons/${page ? "?page=" + page : ""}`
  );

  const data = response.data;

  return data;
}
