import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a2b545e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_icon, { icon: $setup.timeOutline }, null, 8, ["icon"]),
    _createElementVNode("p", null, _toDisplayString($props.averageTime) + " min", 1)
  ]))
}