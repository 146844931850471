import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80916572"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-line" }
const _hoisted_2 = { class: "card-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_store_external_logo = _resolveComponent("store-external-logo")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openExternal()))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        ($props.item.image)
          ? (_openBlock(), _createBlock(_component_store_external_logo, {
              key: 0,
              class: "logo",
              logo: $props.item.image
            }, null, 8, ["logo"]))
          : _createCommentVNode("", true),
        _createElementVNode("h3", null, _toDisplayString($props.item.description), 1)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("h4", null, _toDisplayString($props.item.cashback ? $props.item.cashback + "% de Cashback" : ""), 1),
          _createElementVNode("p", null, "Válido até " + _toDisplayString($setup.dateToBr($props.item.expiration_date)), 1)
        ])
      ])
    ]),
    _: 1
  }))
}