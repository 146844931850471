
import {
  IonContent,
  IonButton,
  IonInput,
  modalController,
  toastController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "vuex";

import typesModule from "@/data/modules/typesModule";
export default defineComponent({
  name: "CustomModalMoney",
  components: { IonContent, IonButton, IonInput },
  props: {
    title: { type: String },
    subtitle: { type: String },
    inputs: { type: Array },
    paymentMethod: { type: Object },
    totalValue: { type: Number },
  },

  setup(props: any) {
    const store = useStore();
    const establishmentId = store.state.cart.establishmentId;

    return { props };
  },

  data: () => ({
    moneyValue: "",
  }),

  methods: {
    createToast(message) {
      toastController
        .create({
          message,
          duration: 2000,
        })
        .then((toast) => toast.present());
    },
    confirmMoney() {
      if (this.$props.totalValue > this.moneyValue) {
        this.createToast(
          `Dinheiro insuficiente! Deve ser pelo menos ${this.$props.totalValue}`
        );

        return;
      }
      this.$store.dispatch(typesModule.actions.SELECTED_PAYMENT_METHOD, {
        ...this.props.paymentMethod,
        change: parseInt(this.moneyValue),
      });
      modalController.dismiss();
    },
    closeModal() {
      modalController.dismiss();
    },
  },
});
