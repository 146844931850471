import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f701fd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "circular-border" }
const _hoisted_2 = {
  key: 0,
  class: "skeleton"
}
const _hoisted_3 = { class: "item-address" }
const _hoisted_4 = { class: "reference" }
const _hoisted_5 = { class: "icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_with_balance = _resolveComponent("header-with-balance")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_the_button = _resolveComponent("the-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_header_with_balance, {
            class: "balance-header",
            title: "Endereços",
            "has-go-back": true
          }),
          _createElementVNode("div", _hoisted_1, [
            ($setup.loadingRef)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_ion_skeleton_text, {
                    animated: "",
                    class: "city-skeleton"
                  }),
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                    return _createVNode(_component_ion_card, {
                      key: i,
                      class: "address-item"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_content, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_skeleton_text, {
                              animated: "",
                              class: "neigh-skeleton"
                            }),
                            _createVNode(_component_ion_skeleton_text, { animated: "" }),
                            _createVNode(_component_ion_skeleton_text, { animated: "" }),
                            _createVNode(_component_ion_skeleton_text, { animated: "" })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024)
                  }), 64))
                ]))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.addressRef, (city) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: city.id,
                    class: "city"
                  }, [
                    _createElementVNode("h2", null, _toDisplayString(city.name), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(city.neighborhoods, (address) => {
                      return (_openBlock(), _createBlock(_component_ion_card, {
                        key: address.id,
                        class: "address-item"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_content, null, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("h4", null, _toDisplayString(address.name), 1),
                                _createVNode(_component_ion_list, null, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(address.addresses, (addresses) => {
                                      return (_openBlock(), _createBlock(_component_ion_item, {
                                        key: addresses.id,
                                        class: "address no-padding"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_4, [
                                            _createElementVNode("span", null, _toDisplayString(addresses.street_name) + ", " + _toDisplayString(addresses.address_number), 1),
                                            _createElementVNode("span", null, _toDisplayString(addresses.complement), 1),
                                            _createElementVNode("span", null, _toDisplayString(addresses.reference), 1)
                                          ]),
                                          _createElementVNode("div", _hoisted_5, [
                                            _createVNode(_component_ion_icon, {
                                              icon: $setup.pencilOutline,
                                              onClick: ($event: any) => ($options.editAddress(addresses))
                                            }, null, 8, ["icon", "onClick"]),
                                            _createVNode(_component_ion_icon, {
                                              icon: $setup.trashBinOutline,
                                              onClick: ($event: any) => ($options.alertDelete(addresses.id))
                                            }, null, 8, ["icon", "onClick"])
                                          ])
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024)
                              ])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]))
                }), 128))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_the_button, {
                name: "Adicionar endereço",
                onClick: $options.openModalAddAddress
              }, null, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}