import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-adeb546a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "coupon-name" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_icon, { icon: $setup.ticketOutline }, null, 8, ["icon"]),
            _createTextVNode(" " + _toDisplayString($props.coupon.description), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "full-rule",
            innerHTML: $props.coupon.rules
          }, null, 8, _hoisted_2),
          _createVNode(_component_ion_button, { color: "primary" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.coupon.many_establishments ? "Ver Estabelecimentos" : "Ativar"), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("p", _hoisted_3, "Valido até " + _toDisplayString($setup.dateToBr($props.coupon.valid_until)), 1)
    ]),
    _: 1
  }))
}