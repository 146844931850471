const cardsModule = {
  state: {
    cards: [],
  },
  mutations: {
    setCard(state: any, value: any) {
      state.cards.push(value);
    },
    deleteCard(state, value) {
      state.cards.splice(value, 1);
    },
    deleteAllCards(state) {
      state.cards = [];
    },
  },
  actions: {
    setCardInfo({ commit }: any, card: any) {
      commit("setCard", card);
    },
    deleteCard({ commit }, card: any) {
      commit("deleteCard", card);
    },
  },
};

export default cardsModule;
