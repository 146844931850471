import axios from "@/utils/axios";

function getQueryParams(page: number, sortBy?: string) {
  if (page || sortBy) {
    if (page && sortBy) {
      return `?page=${page}&sorting=${sortBy}`;
    }

    if (page) {
      return `?page=${page}`;
    }

    if (sortBy) {
      return `?sorting=${sortBy}`;
    }
  }

  return "";
}

export default async function (
  neighborhoodId: number,
  page: number,
  sortBy?: string
) {
  const queryParams = getQueryParams(page, sortBy);

  const response = await axios.get(
    `/neighborhood/${neighborhoodId}/promotions${queryParams}`
  );

  const promotionsData = response.data;

  return promotionsData;
}
