
import RatingStars from "@/components/RatingStars.vue";
import { IonCard, IonCardContent } from "@ionic/vue";
import StoreLogo from "./visualElements/StoreLogo.vue";
import { dateToBr } from "@/utils/functions/date";
import { firstNameAndNextLetter } from "@/utils/functions/name";

export default {
  name: "ItemRatings",
  components: {
    RatingStars,
    IonCard,
    IonCardContent,
    StoreLogo,
  },
  props: {
    rating: {
      type: Object,
      default: undefined,
    },
    logo: {
      type: String,
      default: undefined,
    },
  },
  setup() {
    return {
      dateToBr,
      firstNameAndNextLetter,
    };
  },
};
