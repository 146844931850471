
import { IonList, IonItem } from "@ionic/vue";
import { add, remove } from "ionicons/icons";
import ProductCounterComponent from "@/components/ProductCounterComponent.vue";
import { moneyMask } from "@/utils/functions/money";

export default {
  name: "SelectQuantitySuboption",
  components: { IonList, IonItem, ProductCounterComponent },
  props: {
    suboptions: {
      type: Object,
      default: undefined,
    },
    alreadySelected: {
      type: Array,
      default: undefined,
    },
  },
  emits: ["selectSuboption"],
  setup() {
    return { add, remove, moneyMask };
  },
  data: () => ({
    selected: [],
  }),
  beforeMount() {
    if (this.alreadySelected) {
      this.selected = this.alreadySelected;
      this.alreadySelected.forEach((element) => {
        const suboption = this.suboptions.find(
          (suboption) => suboption.id == element.id
        );

        if (suboption) {
          suboption.initialQuantity = element.quantity;
        }
      });
    }
  },
  methods: {
    checkQuantity(selectedSuboption, quantity) {
      const index = this.selected.findIndex(
        (suboption) => suboption.id == selectedSuboption.id
      );

      if (index === -1) {
        this.selected.push({ ...selectedSuboption, quantity });
      } else {
        this.selected[index].quantity = quantity;
        if (this.selected[index].quantity == 0) {
          this.selected.splice(index, 1);
        }
      }

      this.$emit("selectSuboption", this.selected);
    },
  },
};
