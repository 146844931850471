import axios from "@/utils/axios";

export default async function ({ name, number, cpf, validate, cvv }) {
  const response = await axios.post(`user/wallet/credit-cards`, {
    name,
    number,
    cpf,
    expirationDate: validate,
    cvv,
  });

  const creditCardsData = response.data;

  return creditCardsData;
}
