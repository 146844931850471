import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withKeys as _withKeys, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1476079b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "content-list",
  class: "circular-border"
}
const _hoisted_2 = {
  key: 1,
  id: "content-list",
  class: "circular-border"
}
const _hoisted_3 = {
  key: 1,
  class: "clickabe-component",
  title: "Enviar Imagem",
  for: "file-selection-chat"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chat_modal_header = _resolveComponent("chat-modal-header")!
  const _component_chat_text_balloon_skeleton = _resolveComponent("chat-text-balloon-skeleton")!
  const _component_chat_text_balloon = _resolveComponent("chat-text-balloon")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        ref: "content",
        fullscreen: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_chat_modal_header, { order: _ctx.order }, null, 8, ["order"]),
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (n) => {
                  return _createElementVNode("div", { key: n }, [
                    _createVNode(_component_chat_text_balloon_skeleton)
                  ])
                }), 64))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageList, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id
                  }, [
                    _createVNode(_component_chat_text_balloon, { item: item }, null, 8, ["item"])
                  ]))
                }), 128))
              ]))
        ]),
        _: 1
      }, 512),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.NewMessageText,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.NewMessageText) = $event)),
                    placeholder: "Enviar uma mensagem",
                    autocomplete: "off",
                    onKeyup: _withKeys(_ctx.sendMessage, ["enter"])
                  }, null, 8, ["modelValue", "onKeyup"]),
                  _createVNode(_component_ion_buttons, { slot: "end" }, {
                    default: _withCtx(() => [
                      (_ctx.NewMessageText)
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 0,
                            title: "Enviar Mensagem",
                            onClick: _ctx.sendMessage
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.send }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (!_ctx.NewMessageText)
                        ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
                            _createVNode(_component_ion_icon, {
                              id: "attach-icon",
                              color: "dark",
                              icon: _ctx.attach
                            }, null, 8, ["icon"])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("input", {
                        id: "file-selection-chat",
                        type: "file",
                        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fileChangeEvent($event)))
                      }, null, 32)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}