import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_coupon_card = _resolveComponent("coupon-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "grid-cards" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item) => {
            return (_openBlock(), _createBlock(_component_ion_col, {
              key: item.id,
              "size-xs": "12",
              "size-sm": "6",
              "size-md": "4",
              "size-lg": "3",
              "size-xl": "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_coupon_card, { item: item }, null, 8, ["item"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}