import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-557c0e22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = { class: "profile-info" }
const _hoisted_3 = { class: "orders-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, {
          animated: "",
          class: "nome"
        }),
        _createVNode(_component_ion_skeleton_text, {
          animated: "",
          class: "email"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ion_skeleton_text, { animated: "" }),
        _createVNode(_component_ion_skeleton_text, { animated: "" })
      ])
    ]),
    _createVNode(_component_ion_grid, null, {
      default: _withCtx(() => [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(7, (i) => {
          return _createVNode(_component_ion_row, { key: i }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_skeleton_text, { animated: "" }),
                  _createVNode(_component_ion_skeleton_text, { animated: "" })
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1024)
        }), 64))
      ]),
      _: 1
    })
  ], 64))
}